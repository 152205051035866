import React from "react";

export const ConeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C9.52131 2 9.10975 2.33924 9.01838 2.80913L5.67572 20H5C4.44772 20 4 20.4477 4 21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21C20 20.4477 19.5523 20 19 20H18.3243L14.9816 2.80913C14.8902 2.33924 14.4787 2 14 2H10ZM16.2868 20H7.71317L8.29651 17H15.7035L16.2868 20ZM9.07429 13L8.6854 15H15.3146L14.9257 13H9.07429ZM14.5368 11H9.46318L9.85206 9H14.1479L14.5368 11ZM13.759 7H10.241L10.8243 4H13.1757L13.759 7Z"
        fill="#0173C6"
      />
    </svg>
  );
};
