import React from "react";

export const CalendarIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C16.5523 3 17 3.44772 17 4V4.5H19C20.1046 4.5 21 5.32081 21 6.33333V10.5V19.1667C21 20.1792 20.1046 21 19 21H5C3.89543 21 3 20.1792 3 19.1667V10.5V6.33333C3 5.32081 3.89543 4.5 5 4.5H7V4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4V4.5H15V4C15 3.44772 15.4477 3 16 3ZM7 6.5V7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7V6.5H15V7C15 7.55228 15.4477 8 16 8C16.5523 8 17 7.55228 17 7V6.5H19V9.5H5V6.5H7ZM19 11.5V19H5V11.5H19Z"
        fill="#0173C6"
      />
    </svg>
  );
};
