import React, { useState } from "react";
import {
  SABox,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import {
  ExpandableFAQ,
  QuestionBox,
} from "../claimsDetail/FAQSection/ExpandableFAQ";
import { Snowplow } from "../../util/snowplow/snowplow";
import { useClaimsApplicationState } from "../../providers";

const NarrowBox = styled(SABox)`
  width: 90%;
`;

const ChevronContainer = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export interface FAQ {
  answer: string;
  question: string;
}

export default ({ question, answer, type }: ExpandableFAQ) => {
  const [expanded, setExpanded] = useState(false);
  const { accountNumber } = useClaimsApplicationState();

  const onFaqExpanded = () => {
    if (!expanded) {
      Snowplow.track.faqClicked({
        faq: question,
        faqType: type,
        accountNumber,
      });
    }
  };

  return (
    <SABox p="medium">
      <QuestionBox
        onClick={() => {
          setExpanded(!expanded);
          onFaqExpanded();
        }}
        data-testid="generalFaqItemExpander"
      >
        <NarrowBox>
          <SAText type="paragraph" weight="bold" text={question} />
        </NarrowBox>
        <ChevronContainer>
          <SAIcon
            icon={expanded ? SAIcons.chevronUp : SAIcons.chevronDown}
            size={SAIconSize.small}
          />
        </ChevronContainer>
      </QuestionBox>
      {expanded && (
        <SABox pt="xs" pb="xs">
          <SAText type="paragraph" text={answer} />
        </SABox>
      )}
    </SABox>
  );
};
