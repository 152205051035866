import { Box } from "@material-ui/core";
import styled from "styled-components";
import {
  SABox,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAStack,
  SAText,
} from "@saux/design-system-react";
import React, { useState } from "react";
import AlertSVG from "./icons/alert/Alert";
import PhoneSVG from "./icons/phone/Phone";
import DocSVG from "./icons/doc/Note";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";

export const AlertDescriptionContainer = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 10px 15px 10px 35px;
`;

export interface Alert {
  createDate?: string;
  alertName?: string;
  alertDescription?: string;
}

export enum AlertType {
  Payment = "Payment",
  Possible_Total_Loss = "Possible Total Loss",
  Rental_Created = "Rental Created",
  Initial_Contact_Made = "Initial Contact Made",
}

const AlertTypeIcon = {
  [AlertType.Payment]: <AlertSVG />,
  [AlertType.Possible_Total_Loss]: <AlertSVG />,
  [AlertType.Rental_Created]: <DocSVG />,
  [AlertType.Initial_Contact_Made]: <PhoneSVG />,
};

export default ({ createDate, alertName, alertDescription }: Alert) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <SABox sb="line">
      <SABox pt="medium" pb="small">
        <SAColumns columns={[1, 10, 1]}>
          <SABox>{AlertTypeIcon[alertName as AlertType]}</SABox>
          <SABox pl="small">
            <SAStack spacing="small">
              <SABox display="flex">
                <AltColorText type="standard" text={createDate} weight="bold" />
              </SABox>
              <SABox display="flex">
                <AltColorText type="standard" text={alertName} />
              </SABox>
            </SAStack>
          </SABox>
          <Box
            padding="10px"
            marginRight="10px"
            onClick={() => {
              setExpanded(!expanded);
            }}
            data-testid="detailsExpander"
          >
            {!expanded ? (
              <SAIcon
                icon={SAIcons.chevronDown}
                size={SAIconSize.small}
                colorVariant="dark"
              />
            ) : (
              <SAIcon
                icon={SAIcons.chevronUp}
                size={SAIconSize.small}
                colorVariant="dark"
              />
            )}
          </Box>
        </SAColumns>
      </SABox>
      {expanded && (
        <AlertDescriptionContainer>
          <SAText type="standard" text={alertDescription} />
        </AlertDescriptionContainer>
      )}
    </SABox>
  );
};
