import React, { useState } from "react";
import { Box, Modal, useMediaQuery } from "@material-ui/core";
import {
  SACard,
  SABox,
  SAText,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAStack,
} from "@saux/design-system-react";
import styled from "styled-components";
import RecentClaimsItem, {
  AltColorText,
} from "../recentClaimsItem/RecentClaimsItem";
import EmptyClaimsContainer from "./EmptyClaimsContainer";
import { Slide } from "../slide/Slide";
import { ClaimsDetailContainer } from "..";
import {
  SelectedIncidentState,
  useClaimsApplicationState,
} from "../../providers";
import { ClaimDetailsDropdown } from "../claimsDetail/ClaimDetailsDropdown";
import { ClaimNumberWrapper, FlexContainer, StyledTag } from "../styled";
import formatTitleCaseOther from "../../util/formatters/formatTitleCaseOther";
import ClaimTypeSlideHeaders from "../claimsDetail/ClaimTypeSlideHeaders";
import { altBreakpointsTheme } from "../../overrides";
import { NewClaimLink } from "../../page/ClaimsPage/ClaimsPage";

export interface ClaimData {
  insuredName?: string;
  claimNumber?: string;
  lossLocation?: string;
  dateOfLoss: string;
  status: string;
  closeDate?: string;
  injuredName?: string;
  injuryDetails?: string;
  dateOfBirth?: Date;
  policyNumber?: string;
  hideClaimDetails?: boolean;
}

export const StepOneHeaderContainer = styled(SABox)`
  position: relative;
`;

const StepOneCloseModalIcon = styled(SABox)`
  position: absolute;
  right: 0;
`;

const PreviousFont = styled(SAText)`
  font-size: 18px;
`;

const StepOneTabletHeaderContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface RenderClaimsInfoProps {
  activeStepIndex: any;
  setActiveStepIndex: any;
  setActiveIncidentIndex: any;
}

export const RenderClaimsInfo = ({
  activeStepIndex,
  setActiveStepIndex,
  setActiveIncidentIndex,
}: RenderClaimsInfoProps) => {
  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("xs"));
  const noDropdown = useMediaQuery(altBreakpointsTheme.breakpoints.down("sm"));

  const callback = ({ index }: any) => {
    if (isMobile || noDropdown) {
      setActiveStepIndex(activeStepIndex + 1);
    }
    setActiveIncidentIndex(index);
  };

  const tabletCallback = ({ index }: any) => {
    setActiveIncidentIndex(index);
  };

  return (
    <SABox>
      <ClaimsDetailContainer
        callback={noDropdown ? callback : tabletCallback}
      />
    </SABox>
  );
};

export const RenderStepTwoHeader = () => {
  return (
    <SABox>
      <ClaimTypeSlideHeaders />
    </SABox>
  );
};

export const RenderWithSelectedIncident = ({
  children,
  activeIncidentIndex,
}: any) => {
  const { claimsIncidentsList } = useClaimsApplicationState();

  const value =
    claimsIncidentsList && claimsIncidentsList[activeIncidentIndex || 0];

  return (
    <SelectedIncidentState value={{ ...value }}>
      {children}
    </SelectedIncidentState>
  );
};

export const RenderClaimDetails = () => {
  return (
    <SABox>
      <ClaimDetailsDropdown />
    </SABox>
  );
};

export const RenderContent = ({
  activeTabIndex,
  setAreStepActive,
  areStepsActive,
  account_number,
}: any) => {
  const {
    recentClaimsList,
    priorClaimsList,
    selected,
    setSelected,
    userDisplayName,
  } = useClaimsApplicationState();

  const list = activeTabIndex === 1 ? priorClaimsList : recentClaimsList;
  const type = activeTabIndex === 1 ? "prior" : "recent";

  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(
    altBreakpointsTheme.breakpoints.between("sm", "md")
  );

  return (
    <>
      {!list?.length ? (
        <EmptyClaimsContainer type={type} account_number={account_number} />
      ) : (
        <Box
          margin={isMobile ? "-15px" : "-30px"}
          data-testid="claimIterableContainer"
        >
          {list?.map((displayedItem) => (
            <Box
              key={displayedItem.claimNumber}
              onClick={() => {
                setSelected(displayedItem.claimNumber, displayedItem.status);
                if (
                  (isMobile || isTablet) &&
                  setAreStepActive &&
                  !areStepsActive
                ) {
                  setAreStepActive(true);
                }
              }}
              className="recent-claims-item"
            >
              <RecentClaimsItem
                {...displayedItem}
                selected={displayedItem.claimNumber === selected}
                selectedClaim={selected}
                userDisplayName={userDisplayName}
                recentClaimsList={recentClaimsList}
                priorClaimsList={priorClaimsList}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export const RenderStepOneHeader = ({
  setAreStepActive,
  account_number,
}: any) => {
  const {
    recentClaimsList,
    priorClaimsList,
    selected,
    claimsIncidentsList,
  } = useClaimsApplicationState();

  const isTablet = useMediaQuery(
    altBreakpointsTheme.breakpoints.between("sm", "md")
  );

  const selectedIncidentStatus = [
    ...(recentClaimsList || []),
    ...(priorClaimsList || []),
  ]?.find((value) => value.claimNumber === selected);

  const selectIncidentStatusLabel = formatTitleCaseOther(
    selectedIncidentStatus?.status || ""
  );

  return (
    <StepOneHeaderContainer>
      {isTablet ? (
        <SAStack>
          <StepOneTabletHeaderContainer>
            <Box onClick={() => setAreStepActive(false)}>
              <SAIcon
                icon={SAIcons.chevronLeftBold}
                size={SAIconSize.small}
                colorVariant="dark"
              />
              <PreviousFont text="PREVIOUS" type="standard" />
            </Box>
            <NewClaimLink account_number={account_number} />
          </StepOneTabletHeaderContainer>
          <SABox p="small" mb="small" pt="large">
            <SABox mb="xxs">Claim Number</SABox>
            <SABox mb="small">
              <FlexContainer>
                <ClaimNumberWrapper>
                  <AltColorText type="heading-4" text={selected} />
                </ClaimNumberWrapper>
              </FlexContainer>
            </SABox>
            <SABox>
              {claimsIncidentsList && claimsIncidentsList?.length > 1 ? (
                <SAText
                  text={`This claim has multiple sections. A different adjuster has been
                assigned to each section. Please select a section to view more
                info.`}
                />
              ) : (
                <SAText text="Please select a section to view more info." />
              )}
            </SABox>
          </SABox>
        </SAStack>
      ) : (
        <>
          <StepOneCloseModalIcon>
            <SAIcon
              onClick={() => setAreStepActive(false)}
              icon={SAIcons.x}
              size={SAIconSize.small}
              colorVariant="dark"
            />
          </StepOneCloseModalIcon>
          <SABox p="small" mb="small" pt="large">
            <SABox mb="xxs">Claim Number</SABox>
            <SABox mb="small">
              <FlexContainer>
                <ClaimNumberWrapper>
                  <AltColorText type="heading-4" text={selected} />
                </ClaimNumberWrapper>
                <SABox>
                  <ul
                    style={{
                      marginTop: `-5px`,
                    }}
                  >
                    <StyledTag
                      label={selectIncidentStatusLabel}
                      variant={
                        selectedIncidentStatus?.status === "open"
                          ? "secondary-outline-small"
                          : "standard-outline-small"
                      }
                    />
                  </ul>
                </SABox>
              </FlexContainer>
            </SABox>
            <SABox>
              {claimsIncidentsList && claimsIncidentsList?.length > 1 ? (
                <SAText
                  text={`This claim has multiple sections. A different adjuster has been
                assigned to each section. Please select a section to view more
                info.`}
                />
              ) : (
                <SAText text="Please select a section to view more info." />
              )}
            </SABox>
          </SABox>
        </>
      )}
    </StepOneHeaderContainer>
  );
};
export interface RecentClaimsContainerProps {
  account_number: string;
}

export default ({ account_number }: RecentClaimsContainerProps) => {
  const tabs = ["Recent Claims", "Prior Claims"];
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [areStepsActive, setAreStepActive] = useState(false);
  const [activeIncidentIndex, setActiveIncidentIndex] = useState<
    number | undefined
  >();
  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(
    altBreakpointsTheme.breakpoints.between("sm", "md")
  );
  const noDropdown = useMediaQuery(altBreakpointsTheme.breakpoints.down("sm"));

  const {
    recentClaimsList,
    priorClaimsList,
    setSelected,
  } = useClaimsApplicationState();

  const tabChanged = (newActiveTabIndex: number) => {
    setActiveTabIndex(newActiveTabIndex);

    if (newActiveTabIndex === 0 && recentClaimsList?.length) {
      setSelected(recentClaimsList[0]?.claimNumber);
    }
    if (newActiveTabIndex === 1 && priorClaimsList?.length) {
      setSelected(priorClaimsList[0]?.claimNumber);
    }
    if (newActiveTabIndex === 0 && !recentClaimsList?.length) {
      setSelected("emptyRecent");
    }
    if (newActiveTabIndex === 1 && !priorClaimsList?.length) {
      setSelected("emptyPrior");
    }
  };

  return (
    <SABox mt="xs" mr="xxs" mb="xs" ml="xxs">
      <Box>
        <SACard
          variant="standard-tabbed"
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          tabChangeEventHandler={tabChanged}
          removePadding
        >
          {areStepsActive && (isMobile || isTablet) ? (
            <Modal open={areStepsActive}>
              <SACard variant="minimal">
                <Slide
                  activeStepIndex={activeStepIndex}
                  displayCallbackIcon={activeStepIndex > 0}
                  stepCallback={(stepIndex) =>
                    setActiveStepIndex(stepIndex - 1)
                  }
                  steps={
                    noDropdown
                      ? [
                          {
                            stepKey: "step-1",
                            stepHeader: (
                              <RenderStepOneHeader
                                setAreStepActive={setAreStepActive}
                                account_number={account_number}
                              />
                            ),
                            stepElement: (
                              <RenderClaimsInfo
                                activeStepIndex={activeStepIndex}
                                setActiveStepIndex={setActiveStepIndex}
                                setActiveIncidentIndex={setActiveIncidentIndex}
                              />
                            ),
                          },
                          {
                            stepKey: "step-2",
                            stepHeader: (
                              <RenderWithSelectedIncident
                                activeIncident={activeIncidentIndex}
                              >
                                <RenderStepTwoHeader />
                              </RenderWithSelectedIncident>
                            ),
                            stepElement: (
                              <RenderWithSelectedIncident
                                activeIncident={activeIncidentIndex}
                              >
                                <RenderClaimDetails />
                              </RenderWithSelectedIncident>
                            ),
                          },
                        ]
                      : [
                          {
                            stepKey: "step-1",
                            stepHeader: (
                              <RenderStepOneHeader
                                setAreStepActive={setAreStepActive}
                              />
                            ),
                            stepElement: (
                              <RenderClaimsInfo
                                activeStepIndex={activeStepIndex}
                                setActiveStepIndex={setActiveStepIndex}
                                setActiveIncidentIndex={setActiveIncidentIndex}
                              />
                            ),
                          },
                        ]
                  }
                />
              </SACard>
            </Modal>
          ) : (
            <RenderContent
              activeTabIndex={activeTabIndex}
              setAreStepActive={setAreStepActive}
              areStepsActive={areStepsActive}
              account_number={account_number}
            />
          )}
        </SACard>
      </Box>
    </SABox>
  );
};
