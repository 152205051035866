import React from "react";
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
} from "@saux/design-system-react";
import { useClaimsApplicationState } from "../../providers";

export const ViewOtherClaimsButton = () => {
  const {
    setIsWorkersCompView,
    setSelected,
    recentClaimsList,
    priorClaimsList,
  } = useClaimsApplicationState();

  return (
    <SAButton
      startIcon={
        <SAIcon
          icon={SAIcons.chevronLeft}
          size={SAIconSize.small}
          colorVariant="primary"
          data-testid="wc-back-icon"
        />
      }
      label="RETURN TO OTHER CLAIMS"
      onClick={() => {
        // set selected claims to default value
        if (recentClaimsList?.length) {
          setSelected(recentClaimsList[0]?.claimNumber);
        } else if (priorClaimsList?.length) {
          setSelected(priorClaimsList[0]?.claimNumber);
        } else {
          setSelected("emptyRecent");
        }
        setIsWorkersCompView(false);
      }}
      variant="secondary-medium-outline"
    />
  );
};
