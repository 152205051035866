import { SABox, SAText } from "@saux/design-system-react";
import React from "react";
import styled from "styled-components";
import { formatMoney } from "../../util/formatters";

const HeaderBox = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PaymentBox = styled(SABox)`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const TextBox = styled(SABox)`
  white-space: nowrap;
`;

interface WCClaimPayeeHeaderProps {
  payeeName?: string;
  chargedToDate?: number;
  paidToDate?: number;
}

export const WCClaimPayeeHeader = ({
  payeeName,
  chargedToDate,
  paidToDate,
}: WCClaimPayeeHeaderProps) => {
  return (
    <HeaderBox pl="medium">
      <SABox pr="large">
        <SAText type="heading-3">{payeeName || "N/A"}</SAText>
      </SABox>
      <PaymentBox>
        <SABox pr="medium">
          <TextBox>
            <SAText type="heading-4" alignment="right">
              Charged to Date:
            </SAText>
          </TextBox>
          <TextBox pt="small">
            <SAText type="heading-4" alignment="right">
              Paid to Date:
            </SAText>
          </TextBox>
        </SABox>
        <SABox pr="medium">
          <TextBox>
            <SAText type="heading-4">
              {formatMoney(chargedToDate) || "N/A"}
            </SAText>
          </TextBox>
          <TextBox pt="small">
            <SAText type="heading-4">{formatMoney(paidToDate) || "N/A"}</SAText>
          </TextBox>
        </SABox>
      </PaymentBox>
    </HeaderBox>
  );
};
