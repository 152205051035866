import React, { useState, useEffect } from "react";
import { FAQType, fetchFAQS } from "../../services";
import { FAQ } from "./FAQItem";
import FAQSection from "./FAQSection";

export default () => {
  const [faqs, setFAQS] = useState([] as FAQ[]);
  const type = { type: "General" } as FAQType;

  useEffect(() => {
    fetchFAQS(type)
      .then((response) => {
        const { data } = response;
        setFAQS(data.data);
      })
      .catch((e) => {});
  }, []);

  return <FAQSection faqs={faqs} />;
};
