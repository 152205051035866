import React, { ReactElement, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { SACard, SABox, SAIcons, SAIconSize } from "@saux/design-system-react";
import styled from "styled-components";
import { StyledIcon } from "../styled";

const LightGreyMobileCard = styled(SACard)`
  @media (max-width: 1024px) {
    background: #f4f6fa;
  }
`;

const TitleContainer = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeadingContainer = styled(SABox)`
  flex-basis: 100%;
  flex-grow: 1;
`;

const IconContainer = styled(SABox)`
  flex-grow: 0;
`;

export interface AccordionProps {
  title: string | ReactElement | Element;
  content: string | ReactElement | Element;
  callback?: (expanded: boolean) => void;
  displayIcon?: boolean | true;
  expand?: boolean | undefined;
  showBorder?: boolean;
  disable?: boolean | undefined;
}

export const Accordion = ({
  title,
  content,
  callback,
  displayIcon,
  expand,
  showBorder,
  disable,
}: AccordionProps) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(expand || false);
  }, [expand]);

  return (
    <Box
      style={
        (showBorder || showBorder === undefined) && expanded
          ? {
              borderTop: "4px solid #0072C6",
              borderRadius: "3px",
            }
          : { borderTop: "0px" }
      }
    >
      <LightGreyMobileCard variant="minimal" removePadding>
        <SABox pr="medium" pl="medium" pt="large" pb="large">
          <Box
            onClick={() => {
              if (!disable) {
                setExpanded(!expanded);
                if (callback) {
                  callback(!expanded);
                }
              }
            }}
            data-testid="accordionExpander"
          >
            <TitleContainer>
              <HeadingContainer>{title}</HeadingContainer>
              {displayIcon && (
                <IconContainer pr="medium">
                  {!expanded ? (
                    <StyledIcon
                      icon={SAIcons.chevronDownBold}
                      size={SAIconSize.medium}
                      colorVariant="secondary"
                    />
                  ) : (
                    <StyledIcon
                      icon={SAIcons.chevronUpBold}
                      size={SAIconSize.medium}
                      colorVariant="secondary"
                    />
                  )}
                </IconContainer>
              )}
            </TitleContainer>
          </Box>
          {expanded && <SABox>{content}</SABox>}
        </SABox>
      </LightGreyMobileCard>
    </Box>
  );
};
