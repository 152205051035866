import React from "react";
import { useMediaQuery } from "@material-ui/core";
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SABox,
  SAStack,
} from "@saux/design-system-react";
import styled from "styled-components";
import { ClaimsSummaryWrapperProps } from "../claimsSummaryWrapper/ClaimsSummaryWrapper";
import ClaimType from "./ClaimType";
import ClaimAdjuster from "./ClaimAdjuster";
import ClaimStatus from "./ClaimStatus";
import { altBreakpointsTheme } from "../../overrides";

export interface VehicleProps {
  ymm?: string;
  vin?: string;
}

export interface PropertyProps {
  propertyLine1?: string;
  propertyLine2?: string;
  propertyLine3?: string;
}

export interface InjuryProps {
  name?: string;
  birthYear?: string;
}

export interface AdjusterDetails {
  email?: string;
  phone?: string;
  username?: string;
}

export interface ContactDetails {
  name?: string;
  addressline1?: string;
  addressline2?: string;
  addressline3?: string;
  city?: string;
  contact_type?: string;
  dateofbirth?: string;
  socialsecuritynbr?: string;
  statecode?: string;
  zip?: string;
}

export interface PaymentSummaryDetails {
  expense_ptd?: number;
  indemnity_ptd?: number;
  legal_ptd?: number;
  medical_ptd?: number;
  voc_rehab_ptd?: number;
}

export interface PaymentDetails {
  chargedamount?: number;
  checkdate?: string;
  checknumber?: number;
  invoice_trid?: number;
  paidamount?: number;
  paymentstatus?: string;
  service_fromdate?: string;
  service_thrudate?: string;
  vendor?: string;
  vendor_trid?: number;
}

export interface ClaimInformation {
  agww?: number;
  claimtrid?: number;
  firstdayindemnity?: string;
  firstdayoflosttime?: string;
  mmidate?: string;
  primarydiagnosis?: string;
  returntoclinicactualflag?: string;
  returntoclinicdate?: string;
  returntoclinicprovider?: string;
  secondarydiagnosis?: string;
  treatingprovider?: string;
  ttdrate?: number;
  workstatus?: string;
  workstatusdate?: string;
}

export interface VendorPaymentDetails {
  name?: string;
  chargedtodate?: number;
  paidtodate?: number;
  paymentDetails?: PaymentDetails[];
}

export interface ClaimsReserveDetails {
  expense_reserves?: number;
  indemnity_reserves?: number;
  legal_reserves?: number;
  medical_reserves?: number;
  voc_rehab_reserves?: number;
}

export interface WorkerCompProps {
  account_number?: string;
  activitieswheninjured?: string;
  agencycode?: string;
  benefitstate?: string;
  causeofinjury?: string;
  claim_status?: string;
  claimnumber?: string;
  dateofloss?: string;
  ee_trid?: string;
  er_trid?: string;
  ernotifiedofinjurydate?: string;
  injurydescription?: string;
  injuryreporteddate?: string;
  liability?: string;
  losslocationaddressline1?: string;
  losslocationcity?: string;
  losslocationstate?: string;
  losslocationzip?: string;
  natureofinjury?: string;
  occupation?: string;
  partofbody?: string;
  pk?: string;
  policynumber?: string;
  policyyears?: number;
  primaryadjustertrid?: string;
  retired_flag?: string;
  returntoworkdate?: string;
  adjusterDetails?: AdjusterDetails;
  injuredContactDetails?: ContactDetails;
  employerContactDetails?: ContactDetails;
  paymentSumDetails?: PaymentSummaryDetails;
  paymentDetails?: VendorPaymentDetails[];
  claimsReserveDetails?: ClaimsReserveDetails;
  policyNumber?: string;
  injuredName?: string;
}

export interface MedicalProps {
  name?: string;
  birthYear?: string;
  exposure?: string;
}

export interface AdjusterAvailabilityProps {
  fromWorkHours?: string;
  isAvailableFriday?: boolean;
  isAvailableMonday?: boolean;
  isAvailableSaturday?: boolean;
  isAvailableSunday?: boolean;
  isAvailableThursday?: boolean;
  isAvailableTuesday?: boolean;
  isAvailableWednesday?: boolean;
  toWorkHours?: string;
}

export interface AdjusterProps {
  displayName: string;
  email: string;
  phoneNumber: string;
  adjusterAvailability?: AdjusterAvailabilityProps;
}

export interface ClaimsDetailProps {
  publicID?: string;
  insuredAsset?: string;
  summary?: ClaimsSummaryWrapperProps;
  vehicle?: VehicleProps;
  property?: PropertyProps;
  injury?: InjuryProps;
  workerComp?: WorkerCompProps;
  medical?: MedicalProps;
  adjuster: AdjusterProps;
  status: String;
  selected?: string;
}

const MobileContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const DesktopContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: start;
`;

export default () => {
  const isAltMobileBreakpoint = useMediaQuery(
    altBreakpointsTheme.breakpoints.down("sm")
  );

  return (
    <>
      {isAltMobileBreakpoint ? (
        <SAStack spacing="large">
          <MobileContainer>
            <ClaimType />
            <SAIcon
              icon={SAIcons.chevronRightBold}
              size={SAIconSize.small}
              colorVariant="secondary"
            />
          </MobileContainer>
          <ClaimAdjuster />
          <ClaimStatus />
        </SAStack>
      ) : (
        <DesktopContainer>
          <ClaimType />
          <ClaimAdjuster />
          <ClaimStatus />
        </DesktopContainer>
      )}
    </>
  );
};
