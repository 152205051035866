import React, { useEffect, useState } from "react";
import { loader } from "graphql.macro";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { RouteComponentProps } from "@reach/router";
import { useMediaQuery } from "@material-ui/core";
import { altBreakpointsTheme } from "../../overrides";
import { ClaimsPage } from "./ClaimsPage";
import {
  formatDate,
  formatMoney,
  formatTitleCase,
  formatZipcode,
} from "../../util/formatters";
import {
  AdjusterProps,
  ClaimsDetailProps,
  InjuryProps,
  MedicalProps,
  PropertyProps,
  VehicleProps,
} from "../../components/claimsDetail/ClaimsDetail";
import { ClaimsLatestPaymentProps } from "../../components/claimsLatestPayments/ClaimsLatestPayments";
import { ClaimData } from "../../components/recentClaimsContainer/RecentClaimsContainer";
import { ClaimsApplicationState } from "../../providers";
import {
  GET_CLAIM_INCIDENTS_account_items_claim_incidents,
  GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts,
} from "../../generated/GET_CLAIM_INCIDENTS";
import {
  GET_CLAIMS_Recent_Claims,
  GET_CLAIMS_Recent_Claims_account_items_claims_items,
} from "../../generated/GET_CLAIMS_Recent_Claims";
import { useDCXFeatureFlags } from "../../providers/Providers";
import { getFFEnabled } from "../../util";
import { Loading } from "../../components/loading";

const get_Product_Code = loader(
  "../../graphql/queries/Get_Product_Code.graphql"
);

const get_claims_recent_claims = loader(
  "../../graphql/queries/Get_Claims_Recent_Claims.graphql"
);

const get_claim_incidents = loader(
  "../../graphql/queries/Get_Claim_Incidents.graphql"
);

const get_wc_claim_details = loader(
  "../../graphql/queries/Get_Wc_Claim_Details.graphql"
);

const get_wc_claim_list = loader(
  "../../graphql/queries/Get_WC_Claim_List.graphql"
);

export enum ClaimState {
  Open = "Open",
  Complete = "Complete",
  Canceled = "Canceled",
}

export const alertsTransform = (
  alerts:
    | (GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts | null)[]
    | null
    | undefined
) => {
  if (!alerts) {
    return [];
  }
  const transformedAlerts: GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts[] = alerts
    .filter(
      (
        alert
      ): alert is GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts => {
        return alert != null;
      }
    )
    .map(
      (
        alert: GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts
      ) => {
        let createDate;
        if (alert.createDate) {
          createDate = `${formatDate(alert.createDate)}`;
        } else {
          createDate = "Not Available";
        }
        return {
          __typename: "ClaimAlert",
          publicID: alert.publicID || "",
          createDate,
          alertName: alert.alertName || "Not Available",
          alertDescription: alert.alertDescription || "Not Available",
          isRead: alert.isRead,
        };
      }
    );
  return transformedAlerts;
};

export const paymentsTransform = (
  incident: GET_CLAIM_INCIDENTS_account_items_claim_incidents
) => {
  const payments: ClaimsLatestPaymentProps[] = [];
  if (incident.exposureDetails?.length) {
    incident.exposureDetails.forEach((exposure: any) => {
      if (exposure?.payments?.length) {
        exposure.payments.forEach((exposurePayment: any) => {
          const singlePayment: ClaimsLatestPaymentProps = {
            payees: [],
            paymentMethod:
              formatTitleCase(exposurePayment?.paymentMethod) || "Payment",
            issueDate: "Not Available",
            checkNumber: exposurePayment?.checkNumber || "Not Available",
            amount: "Not Available",
          };
          if (exposurePayment?.payees?.length) {
            exposurePayment.payees.forEach((payee: any) => {
              if (payee?.name) {
                const titleCaseName = formatTitleCase(payee.name)!;
                singlePayment?.payees?.push(titleCaseName);
              }
            });
          }
          if (exposurePayment?.issuedDate) {
            singlePayment.issueDate = `${formatDate(
              exposurePayment.issuedDate
            )}`;
          }
          if (exposurePayment?.amount?.amount) {
            const amount = parseFloat(exposurePayment.amount.amount);
            if (amount === 0) {
              singlePayment.amount = "-";
            } else {
              singlePayment.amount = formatMoney(amount)!;
            }
          }
          payments.push(singlePayment);
        });
      }
    });
  }
  return payments;
};

export const adjusterTransform = (occurrence: any, data: any) => {
  const adjuster = {
    displayName: "Not Available",
    email: "Not Available",
    phoneNumber: "Not Available",
    adjusterAvailability: {
      fromWorkHours: "9:00 AM",
      isAvailableMonday: true,
      isAvailableTuesday: true,
      isAvailableWednesday: true,
      isAvailableThursday: true,
      isAvailableFriday: true,
      isAvailableSaturday: false,
      isAvailableSunday: false,
      toWorkHours: "5:00 PM",
    },
  };
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.displayName
  ) {
    adjuster.displayName =
      occurrence.exposureDetails[0].adjusterDetails.displayName;
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.displayName
  ) {
    adjuster.displayName = data.account.items[0].claim.adjuster.displayName;
  }
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.email
  ) {
    adjuster.email = occurrence.exposureDetails[0].adjusterDetails.email.toLowerCase();
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.email
  ) {
    adjuster.email = data.account.items[0].claim.adjuster.email.toLowerCase();
  }
  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.phoneNumber
  ) {
    adjuster.phoneNumber =
      occurrence.exposureDetails[0].adjusterDetails.phoneNumber;
  } else if (
    data?.account?.items &&
    data.account.items[0]?.claim?.adjuster?.phoneNumber
  ) {
    adjuster.phoneNumber = data.account.items[0].claim.adjuster.phoneNumber;
  }

  if (
    occurrence.exposureDetails &&
    occurrence.exposureDetails[0]?.adjusterDetails?.adjusterAvailability
  ) {
    const availability =
      occurrence.exposureDetails[0]?.adjusterDetails?.adjusterAvailability;

    if (availability?.fromWorkHours)
      adjuster.adjusterAvailability.fromWorkHours = availability?.fromWorkHours;
    if (availability?.toWorkHours)
      adjuster.adjusterAvailability.toWorkHours = availability?.toWorkHours;

    adjuster.adjusterAvailability.isAvailableMonday =
      availability?.isAvailableMonday || false;
    adjuster.adjusterAvailability.isAvailableTuesday =
      availability?.isAvailableTuesday || false;
    adjuster.adjusterAvailability.isAvailableWednesday =
      availability?.isAvailableWednesday || false;
    adjuster.adjusterAvailability.isAvailableThursday =
      availability?.isAvailableThursday || false;
    adjuster.adjusterAvailability.isAvailableFriday =
      availability?.isAvailableFriday || false;
    adjuster.adjusterAvailability.isAvailableSaturday =
      availability?.isAvailableSaturday || false;
    adjuster.adjusterAvailability.isAvailableSunday =
      availability?.isAvailableSunday || false;
  }

  return adjuster;
};

export const nameTransform = (data: GET_CLAIMS_Recent_Claims) => {
  if (data?.account?.items) {
    const name = data.account?.items?.[0];
    const userDisplayName =
      name?.display_name === null
        ? ""
        : `${formatTitleCase(name?.display_name)}`;
    return userDisplayName;
  }
  return undefined;
};

export const transformData = (data: GET_CLAIMS_Recent_Claims) => {
  if (data?.account?.items) {
    const information = data.account?.items?.[0]?.claims?.items;
    const transformedClaims =
      information?.map(
        (claim: GET_CLAIMS_Recent_Claims_account_items_claims_items | null) => {
          if (claim) {
            const insuredContacts = (claim?.relatedContacts || []).filter(
              (contact: any) => {
                return contact?.contactRoles.some(
                  (role: string) => role === "insured"
                );
              }
            );
            const lossLocation = claim?.lossLocation?.state || "Not Available";
            return {
              claimNumber: claim?.claimNumber || "Not Available",
              insuredName: insuredContacts[0]?.displayName || "Not Available",
              lossLocation,
              dateOfLoss: claim?.lossDate || "Not Available",
              status: claim?.claimState || "Not Available",
            };
          }
          return undefined;
        }
      ) || [];
    const name = nameTransform(data);
    return {
      transformedClaims,
      name,
    };
  }
  return undefined;
};

const computePriorClaims = (claim: ClaimData, priorClaimCheckDate: number) => {
  if (claim?.closeDate) {
    return priorClaimCheckDate < Date.parse(claim.closeDate);
  }
  return false;
};

export const transformRecentClaims = (transformedClaims: ClaimData[]) => {
  const priorClaimCheckDate = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
  return transformedClaims?.filter((claim: ClaimData) => {
    return (
      claim.status !== ClaimState.Complete ||
      computePriorClaims(claim, priorClaimCheckDate)
    );
  });
};

export const transformPriorClaims = (transformedClaims: ClaimData[]) => {
  const priorClaimCheckDate = new Date().getTime() - 30 * 24 * 60 * 60 * 1000;
  return transformedClaims?.filter((claim: ClaimData) => {
    return (
      claim.status === ClaimState.Complete &&
      !computePriorClaims(claim, priorClaimCheckDate)
    );
  });
};

const formatIncident = (
  occurrence: GET_CLAIM_INCIDENTS_account_items_claim_incidents | null,
  data: any
) => {
  if (occurrence) {
    const incident: ClaimsDetailProps = {
      publicID: occurrence.incident?.publicID || "",
      insuredAsset: "Not Available",
      summary: {
        payments: {
          claimsSummaryPaymentsList: paymentsTransform(occurrence),
        },
      },
      vehicle: undefined,
      property: undefined,
      injury: undefined,
      medical: undefined,
      status: occurrence?.incident?.status || "Not Available",
      adjuster: {
        displayName: "Not Available",
        email: "Not Available",
        phoneNumber: "Not Available",
        adjusterAvailability: undefined,
      },
    };

    incident.adjuster = adjusterTransform(occurrence, data);

    if (occurrence?.incident?.property) {
      incident.insuredAsset =
        formatTitleCase(
          occurrence.incident.property.propertyAddress?.addressLine1
        ) || "Not Available";
      incident.property = {
        propertyLine1:
          occurrence.incident.property.propertyAddress?.addressLine1 || "",
        propertyLine2: occurrence.incident.property.propertyAddress?.city || "",
        propertyLine3: `, ${
          occurrence.incident.property.propertyAddress?.state || ""
        }, ${
          formatZipcode(
            occurrence.incident.property.propertyAddress?.postalCode
          ) || ""
        }`,
      };
    }

    if (occurrence?.incident?.vehicle) {
      incident.insuredAsset = formatTitleCase(
        `${occurrence.incident.vehicle.year || ""} ${
          occurrence.incident.vehicle.make || ""
        } ${occurrence.incident.vehicle.model || ""}`
      )!;
      incident.vehicle = {
        vin: occurrence.incident.vehicle.vin || "Not Available",
      };
    }

    if (occurrence?.incident?.injury) {
      incident.insuredAsset =
        formatTitleCase(occurrence?.incident?.injury?.injured?.name) ||
        "Not Available";
      incident.injury = {
        name:
          formatTitleCase(occurrence?.incident?.injury?.injured?.name) ||
          "Not Available",
        birthYear: "Not Available",
      };
    }

    if (occurrence?.incident?.medical) {
      incident.insuredAsset =
        formatTitleCase(occurrence?.incident?.medical?.injured?.name) ||
        "Not Available";
      incident.medical = {
        name:
          formatTitleCase(occurrence?.incident?.medical?.injured?.name) ||
          "Not Available",
      };
    }

    if (occurrence.incident?.alerts) {
      if (incident.summary) {
        incident.summary.alerts = {
          alerts: alertsTransform(
            occurrence.incident
              ?.alerts as (GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts | null)[]
          ),
        };
      }
    }
    return incident;
  }
  return undefined;
};

export interface adjusterProps {
  displayName: string;
  email: string;
  phoneNumber: string;
}

export interface incidentProps {
  adjuster: adjusterProps;
  insuredAsset: string;
  vehicle: VehicleProps | null;
  property: PropertyProps | null;
  injury: InjuryProps | null;
  medical: MedicalProps | null;
  status: string;
}

const truncate = (input: String) =>
  input.length > 45 ? `${input.substring(0, 45)}...` : input;

export default (
  _: RouteComponentProps & { accountNumber: string; awsAppSyncClient: any }
) => {
  const [isFirstView, setIsFirstView] = useState<boolean>(true);
  const [selected, setSelected] = useState("");
  const [selectedPolicyNumber, setSelectedPolicyNumber] = useState("");
  const [hideClaimDetails, setHideClaimDetails] = useState<boolean>(false);
  const [claimData, setClaimData] = useState<any | undefined>();
  const [claimCallErrorStatus, setClaimCallErrorStatus] = useState<boolean>(
    false
  );
  const [wcClaimCallErrorStatus, setWcClaimCallErrorStatus] = useState<boolean>(
    false
  );
  const [errorIncidentStatus, setErrorIncidentStatus] = useState<boolean>(
    false
  );
  const [incidents, setIncidents] = useState<(ClaimsDetailProps | undefined)[]>(
    []
  );
  const [noIncidentsAdjusterData, setNoIncidentsAdjusterData] = useState<
    AdjusterProps | undefined
  >();
  const [isWorkersComp, setIsWorkersComp] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<ApolloError | undefined>(
    undefined
  );
  const [isWorkersCompView, setIsWorkersCompView] = useState<boolean>(false);
  const [
    recentClaimCallCompleted,
    setRecentClaimCallCompleted,
  ] = useState<boolean>(false);
  const [wcClaimsList, setWcClaimsList] = useState<ClaimData[]>([]);
  const isTablet = useMediaQuery(altBreakpointsTheme.breakpoints.down("md"));
  const { featureFlags } = useDCXFeatureFlags();
  const enableWorkersComp = getFFEnabled("FF_DCX_3003", featureFlags);
  const { accountNumber, awsAppSyncClient } = _;
  const account_number = accountNumber;
  const getRecentClaimRequestVariables = {
    account_number,
  };
  const getIncidentRequestVariables = {
    account_number,
    claim_number: selected,
  };
  const getProductCodeRequestVariables = {
    accountNumber,
  };
  const getWcClaimsListRequestVariables = {
    accountNumber,
  };

  const changeSelection = (selection: string, selectionClaimStatus: string) => {
    setSelected(selection);
  };

  const prioritizeRecentClaim = (claims: ClaimData[]) => {
    const recentList = transformRecentClaims(claims);
    const priorList = transformPriorClaims(claims);

    if (recentList.length) {
      const selection = {
        claimNumber: recentList[0].claimNumber,
        status: recentList[0].status,
      };
      return selection;
    }

    if (priorList.length) {
      const selection = {
        claimNumber: priorList[0].claimNumber,
        status: priorList[0].status,
      };
      return selection;
    }

    return {
      claimNumber: undefined,
      status: undefined,
    };
  };

  // Handling callbacks for recent claims list
  const recentClaimsDataCallback = (data: any) => {
    if (data) {
      const transformedData = transformData(data);
      if (transformedData?.transformedClaims?.length && selected === "") {
        const selection = prioritizeRecentClaim(
          transformedData?.transformedClaims as ClaimData[]
        );
        if (
          selection.claimNumber !== undefined &&
          selection.status !== undefined
        ) {
          changeSelection(selection.claimNumber, selection.status);
        }
      }
      setClaimCallErrorStatus(false);

      setClaimData(transformedData);
    } else {
      setClaimCallErrorStatus(true);
    }
    setRecentClaimCallCompleted(true);
  };

  const recentClaimsErrorCallback = (error: ApolloError) => {
    setClaimCallErrorStatus(true);
    setErrorResponse(error);
  };

  // Handling callbacks for wcClaimsList
  const wcClaimsListDataCallback = (getWcClaimsListData: any) => {
    if (getWcClaimsListData?.wcClaimsList) {
      const WcList: ClaimData[] = getWcClaimsListData.wcClaimsList.map(
        (claim: any) => {
          return {
            injuredName: claim?.name,
            claimNumber: claim?.claimnumber,
            lossLocation: `${claim?.losslocationaddressline1}, ${claim?.losslocationcity}, ${claim?.losslocationstate} ${claim?.losslocationzip}`,
            dateOfLoss: claim?.dateofloss,
            status: claim?.claim_status,
            injuryDetails: truncate(claim?.injurydescription || ""),
            dateOfBirth: new Date(claim?.dateofbirth),
            policyNumber: claim?.policynumber,
            hideClaimDetails: claim?.hideClaimDetails,
          };
        }
      );

      setWcClaimsList(WcList);
      setWcClaimCallErrorStatus(false);
    } else {
      setWcClaimCallErrorStatus(true);
    }
  };

  const wcClaimsListErrorCallback = (error: ApolloError) => {
    setWcClaimCallErrorStatus(true);
    setErrorResponse(error);
  };

  // Handling callbacks for wc claim details query
  const wcClaimDetailsDataCallback = (getWcClaimDetailsData: any) => {
    if (getWcClaimDetailsData?.getWCDetails) {
      const wcDetails = getWcClaimDetailsData.getWCDetails;
      const { adjusterDetails } = wcDetails;

      setIncidents([
        {
          adjuster: {
            displayName: adjusterDetails?.username,
            email: adjusterDetails?.email,
            phoneNumber: adjusterDetails?.phone,
          },
          status: wcDetails?.claim_status,
          workerComp: {
            injuredName: wcDetails?.injuredContactDetails?.name,
            policyNumber: wcDetails?.policynumber,
          },
        },
      ]);
      setErrorIncidentStatus(false);
    } else {
      setErrorIncidentStatus(true);
    }
  };

  const wcClaimDetailsErrorCallback = (error: ApolloError) => {
    setErrorIncidentStatus(true);
    setErrorResponse(error);
  };

  // Handling callbacks for product code
  const productCodeDataCallback = (data: any) => {
    if (data?.getProductCode) {
      setIsWorkersComp(true);
    }
  };

  const productCodeErrorCallback = (error: ApolloError) => {
    setIsWorkersComp(false);
    setErrorResponse(error);
  };

  // Handling callbacks for get incidents
  const incidentDataCallback = (incidentsQueryData: any) => {
    if (incidentsQueryData?.account?.items) {
      const information =
        incidentsQueryData?.account?.items[0]?.claim?.incidents;
      const incidentsArray: (
        | ClaimsDetailProps
        | undefined
      )[] = information?.map(
        (
          occurrence: GET_CLAIM_INCIDENTS_account_items_claim_incidents | null
        ) => formatIncident(occurrence, incidentsQueryData)
      );

      setIncidents(incidentsArray || []);

      if (!incidentsArray?.length) {
        setNoIncidentsAdjusterData(adjusterTransform({}, incidentsQueryData));
      }

      setErrorIncidentStatus(false);
    } else {
      setErrorIncidentStatus(true);
    }
  };

  const incidentErrorCallback = (error: ApolloError) => {
    setErrorIncidentStatus(true);
    setErrorResponse(error);
  };

  // recent claims query
  const [
    getRecentClaimsQuery,
    { called: recentClaimsCalled, loading: recentClaimsLoading },
  ] = useLazyQuery(get_claims_recent_claims, {
    variables: getRecentClaimRequestVariables,
    onCompleted: recentClaimsDataCallback,
    onError: recentClaimsErrorCallback,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // incidents query
  const [
    getIncidentsQuery,
    { called: getIncidentsCalled, loading: getIncidentsLoading },
  ] = useLazyQuery(get_claim_incidents, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: incidentDataCallback,
    onError: incidentErrorCallback,
  });

  // workers comp claims details query
  const [
    getWcClaimDetailsQuery,
    { called: wcClaimDetailsCalled, loading: wcClaimDetailsLoading },
  ] = useLazyQuery(get_wc_claim_details, {
    onCompleted: wcClaimDetailsDataCallback,
    onError: wcClaimDetailsErrorCallback,
    fetchPolicy: "network-only",
  });

  // workers comp claims list query
  const [
    getWcClaimsListQuery,
    { called: wcClaimsListCalled, loading: wcClaimsListLoading },
  ] = useLazyQuery(get_wc_claim_list, {
    variables: getWcClaimsListRequestVariables,
    onCompleted: wcClaimsListDataCallback,
    onError: wcClaimsListErrorCallback,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  // query to check if account has workers comp policy
  const [getProductCode, { loading: getProductCodeLoading }] = useLazyQuery(
    get_Product_Code,
    {
      variables: getProductCodeRequestVariables,
      onCompleted: productCodeDataCallback,
      onError: productCodeErrorCallback,
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (enableWorkersComp) {
      getProductCode();
    }
  }, [enableWorkersComp]);

  useEffect(() => {
    if (isWorkersCompView) {
      getWcClaimsListQuery();
    } else {
      getRecentClaimsQuery();
    }
  }, [isWorkersCompView]);

  useEffect(() => {
    if (!isWorkersCompView) {
      if (
        selected !== "" &&
        selected !== "emptyRecent" &&
        selected !== "emptyPrior"
      ) {
        getIncidentsQuery({
          variables: getIncidentRequestVariables,
        });
      } else {
        setIncidents([]);
      }
    }
  }, [selected]);

  const changeSelectedAndPolicyNumber = (
    claimNumber: string,
    policyNumber: string,
    hideClaimDetails: boolean
  ) => {
    const getWcClaimDetailsRequestVariables = {
      claimNumber,
      accountNumber,
      policyNumber,
    };

    getWcClaimDetailsQuery({
      variables: getWcClaimDetailsRequestVariables,
    });
    setHideClaimDetails(hideClaimDetails);
    setSelected(claimNumber);
    setSelectedPolicyNumber(policyNumber);
  };

  useEffect(() => {
    if (
      recentClaimCallCompleted &&
      !claimData?.transformedClaims?.length &&
      isWorkersComp &&
      !isTablet &&
      isFirstView
    ) {
      setIsWorkersCompView(true);
      setIsFirstView(false);
    }
  }, [
    recentClaimCallCompleted,
    claimData,
    isWorkersComp,
    isTablet,
    isFirstView,
  ]);

  const priorClaimsList = transformPriorClaims(claimData?.transformedClaims);
  const recentClaimsList = transformRecentClaims(claimData?.transformedClaims);
  const userDisplayName = claimData?.name;
  const claimsIncidentsList = incidents;
  const hasWorkersComp = isWorkersComp && !isTablet;

  return (
    <ClaimsApplicationState
      value={{
        recentClaimsList,
        wcClaimsList,
        priorClaimsList,
        awsAppSyncClient,
        userDisplayName,
        claimsIncidentsList,
        changeSelectedAndPolicyNumber,
        setSelected: changeSelection,
        selected,
        accountNumber,
        noIncidentsAdjusterData,
        isWorkersCompView,
        setIsWorkersCompView,
        hasWorkersComp,
        selectedPolicyNumber,
        hideClaimDetails,
      }}
    >
      {enableWorkersComp ? (
        <>
          {(getProductCodeLoading ||
            hasWorkersComp ||
            recentClaimsLoading ||
            claimData ||
            errorResponse) && (
            <Loading
              loading={getProductCodeLoading || recentClaimsLoading}
              error={errorResponse}
            >
              <ClaimsPage
                loadingIncidentStatus={
                  (getIncidentsCalled && getIncidentsLoading) ||
                  (wcClaimDetailsCalled && wcClaimDetailsLoading)
                }
                errorIncidentStatus={errorIncidentStatus}
                accountNumber={account_number}
                claimCallLoading={recentClaimsCalled && recentClaimsLoading}
                claimCallErrorStatus={claimCallErrorStatus}
                wcClaimCallLoading={wcClaimsListCalled && wcClaimsListLoading}
                wcClaimCallErrorStatus={wcClaimCallErrorStatus}
              />
            </Loading>
          )}
        </>
      ) : (
        <ClaimsPage
          loadingIncidentStatus={
            (getIncidentsCalled && getIncidentsLoading) ||
            (wcClaimDetailsCalled && wcClaimDetailsLoading)
          }
          errorIncidentStatus={errorIncidentStatus}
          accountNumber={account_number}
          claimCallLoading={recentClaimsCalled && recentClaimsLoading}
          claimCallErrorStatus={claimCallErrorStatus}
          wcClaimCallLoading={wcClaimsListCalled && wcClaimsListLoading}
          wcClaimCallErrorStatus={wcClaimCallErrorStatus}
        />
      )}
    </ClaimsApplicationState>
  );
};
