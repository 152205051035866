import React from "react";

export default () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.951925 0.951925C1.56141 0.342442 2.3881 0 3.25 0H11.9167H11.9168C12.2222 0 12.498 0.126302 12.6949 0.329512L19.1706 6.80523C19.3738 7.00215 19.5002 7.27799 19.5002 7.58333L19.5 7.60238V20.5833C19.5 21.4453 19.1576 22.2719 18.5481 22.8814C17.9386 23.4909 17.112 23.8333 16.25 23.8333H3.25C2.38799 23.8333 1.56141 23.4909 0.951925 22.8814C0.342442 22.2719 0 21.4453 0 20.5833V3.25C0 2.3881 0.342442 1.56141 0.951925 0.951925ZM3.25 2.16667H10.8335V7.58333C10.8335 8.18166 11.3185 8.66667 11.9168 8.66667H17.3333V20.5833C17.3333 20.8706 17.2192 21.1462 17.016 21.3494C16.8129 21.5525 16.5373 21.6667 16.25 21.6667H3.25C2.9627 21.6667 2.6871 21.5525 2.48397 21.3494C2.28085 21.1462 2.16667 20.8706 2.16667 20.5833V3.25C2.16667 2.9627 2.28085 2.6871 2.48397 2.48397C2.6871 2.28085 2.9627 2.16667 3.25 2.16667ZM13.0002 3.69888L15.8013 6.5H13.0002V3.69888ZM5.41683 11.9167C4.8185 11.9167 4.3335 12.4018 4.3335 13.0001C4.3335 13.5984 4.8185 14.0834 5.41683 14.0834H14.0835C14.6818 14.0834 15.1668 13.5984 15.1668 13.0001C15.1668 12.4018 14.6818 11.9167 14.0835 11.9167H5.41683ZM4.3335 17.3333C4.3335 16.735 4.8185 16.25 5.41683 16.25H14.0835C14.6818 16.25 15.1668 16.735 15.1668 17.3333C15.1668 17.9317 14.6818 18.4167 14.0835 18.4167H5.41683C4.8185 18.4167 4.3335 17.9317 4.3335 17.3333ZM5.41683 7.5835C4.8185 7.5835 4.3335 8.0685 4.3335 8.66683C4.3335 9.26516 4.8185 9.75017 5.41683 9.75017H7.58349C8.18182 9.75017 8.66683 9.26516 8.66683 8.66683C8.66683 8.0685 8.18182 7.5835 7.58349 7.5835H5.41683Z"
        fill="#0173C6"
      />
    </svg>
  );
};
