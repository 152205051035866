// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
// import './polyfills';
// import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import Amplify, { Auth } from "aws-amplify";
import { AuthLink, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./App";
import getConfig, { Env } from "./config";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    renderClaims: Function;
    unmountCreatereactapp: Function;
  }
}

const config = getConfig(process.env.REACT_APP_ENV as Env);
const tagManagerArgs = {
  gtmId: "GTM-MJB2CN9",
};

TagManager.initialize(tagManagerArgs);
Amplify.configure(config);

const url = config.aws_appsync_graphqlEndpoint;
const region = config.aws_appsync_region;
const auth = {
  type: config.aws_appsync_authenticationType,
  jwtToken: () =>
    Auth.currentSession()
      .then((session) => session.getIdToken().getJwtToken())
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("error", err);
      }),
} as AuthOptions;

const appSyncLinkConfig = { url, region, auth };

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    new AuthLink(appSyncLinkConfig),
    createSubscriptionHandshakeLink(url, new HttpLink({ uri: url })),
  ]) as any,
});

window.renderClaims = (containerId: string, history: any) => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App history={history} />
    </ApolloProvider>,
    document.getElementById(containerId)
  );
  serviceWorker.unregister();
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
