import { Box, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { altBreakpointsTheme } from "../../overrides";
import { useClaimsApplicationState } from "../../providers";
import ClaimAdjusterDropdownSection from "../claimAdjusterDropdownSection/ClaimAdjusterDropdownSection";
import PhotosAndFiles from "../photosAndFilesSection/PhotosAndFiles";
import { ClaimDetailsDropdownTabBar } from "./ClaimDetailsDropdown";

export const NoIncidentsView = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { noIncidentsAdjusterData } = useClaimsApplicationState();
  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("sm"));

  const itemLabels = ["Adjuster Information", "Photos & Files"];

  const tabChanged = (newActiveIndex: number) => {
    setActiveIndex(newActiveIndex);
  };

  const renderContent = (returnIndex: number) => {
    switch (returnIndex) {
      case 1:
        return <PhotosAndFiles />;
      default:
        return (
          <ClaimAdjusterDropdownSection adjuster={noIncidentsAdjusterData} />
        );
    }
  };

  const contentToRender = renderContent(activeIndex);

  return (
    <Box
      style={{
        background: isMobile ? "#f4f6fa" : "#E6EAF1",
        padding: "20px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: "20px 10px 20px 10px",
      }}
    >
      <ClaimDetailsDropdownTabBar
        activeIndex={activeIndex}
        itemLabels={itemLabels}
        onChange={tabChanged}
      />
      {contentToRender}
    </Box>
  );
};
