import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FileContent } from ".";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { UploadStatus } from "../uploadModal/UploadModal";

const get_signed_url_query = loader(
  "../../graphql/queries/Get_Signed_Url.graphql"
);
const upload_to_eds_mutation = loader(
  "../../graphql/queries/Upload_To_EDS.graphql"
);

interface uploadProps {
  dispatch: any;
  name: any;
  uploadState: number;
  setUpload: Function;
  removeFile: (file: any, index: number) => void;
  uploadSuccess: React.Dispatch<React.SetStateAction<UploadStatus[]>>;
  file: File;
  index: number;
}

export default ({
  dispatch,
  name,
  uploadState,
  setUpload,
  removeFile,
  uploadSuccess,
  file,
  index,
}: uploadProps) => {
  const {
    accountNumber,
    selected,
    userDisplayName,
  } = useClaimsApplicationState();
  const {
    publicID,
    vehicle,
    property,
    injury,
    medical,
  } = useSelectedIncidentState();

  const setIncidentTypeEDS = () => {
    let incidentTypeEDS = "";
    if (vehicle) {
      incidentTypeEDS = "vehicle";
    }
    if (property) {
      incidentTypeEDS = "property";
    }
    if (injury) {
      incidentTypeEDS = "injury";
    }
    if (medical) {
      incidentTypeEDS = "medical";
    }
    return incidentTypeEDS;
  };
  const incidentTypeEDS = setIncidentTypeEDS();

  const [documentType, setDocumentType] = useState(" ");
  const documentTypes = [
    { value: "MEDICAL", label: "Medical Bills" },
    { value: "DAMAGE", label: "Damage Photos" },
    { value: "ESTIMATE", label: "Estimate" },
    { value: "INVESTIGATION", label: "Other" },
  ];

  const [getSignedUrl, { loading, error, data: signedUrlData }] = useLazyQuery(
    get_signed_url_query,
    {
      variables: {
        account_number: accountNumber,
        claim_number: selected,
        file_name: file.name,
      },
      fetchPolicy: "no-cache",
    }
  );

  const [
    uploadDocEDS,
    { loading: edsLoad, error: edsError, data: edsResponse },
  ] = useMutation(upload_to_eds_mutation, {
    variables: {
      claim_doc_category: documentType,
      claim_doc_sub_category: incidentTypeEDS,
      claim_id: "",
      claim_number: selected,
      exposure_id: "",
      file_name: file.name,
      incident_id: publicID,
      mime_type: file.type,
      user_display_name: userDisplayName,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <FileContent
      dispatch={dispatch}
      name={name}
      loading={loading}
      error={error}
      edsLoad={edsLoad}
      uploadDocEDS={uploadDocEDS}
      edsResponse={edsResponse}
      uploadState={uploadState}
      uploadSuccess={uploadSuccess}
      setUpload={setUpload}
      getSignedUrl={getSignedUrl}
      signedUrlData={signedUrlData}
      file={file}
      documentType={documentType}
      setDocumentType={setDocumentType}
      documentTypes={documentTypes}
      removeFile={removeFile}
      index={index}
    />
  );
};
