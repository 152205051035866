import {
  createHistory,
  createMemorySource,
  LocationProvider,
} from "@reach/router";
import React, { useEffect, useState } from "react";
import "./App.css";
import styled, { ThemeProvider } from "styled-components";
import { SAUX360Theme } from "@saux/design-system-react";
import DataContainer from "./page/ClaimsPage";
import ProductTour from "./components/productTour";
import { FeatureFlagObject, fetchFeatureFlags } from "./services";
import { WithFeatureFlagsContext } from "./providers/Providers";
import { Loading } from "./components/loading";

interface AppProps {
  history: any;
}

const Container = styled.div`
  margin-bottom: 290px;
`;

const source = createMemorySource("/claims");

const App = ({ history }: AppProps) => {
  const locationProviderHistory = createHistory(source);
  const { useNavigation } = history;

  const [loading, setLoading] = useState<boolean>(true);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagObject[]>([]);

  const getFeatureFlags = async () => {
    try {
      const response = await fetchFeatureFlags();

      if (response?.data?.data) {
        setFeatureFlags(response?.data?.data);
      }
      setLoading(false);
    } catch {
      console.log("Error Fetching Feature Flags");
      setLoading(false);
    }
  };

  useEffect(() => {
    getFeatureFlags();
  }, []);

  return (
    <ThemeProvider theme={SAUX360Theme}>
      <Loading loading={loading}>
        <WithFeatureFlagsContext featureFlags={featureFlags}>
          <LocationProvider history={locationProviderHistory}>
            <Container className="App">
              <ProductTour
                accountNumber={history.accountNumber}
                awsAppSyncClient={history.awsAppSyncClient}
                useNavigation={useNavigation}
              />
              <DataContainer
                accountNumber={history.accountNumber}
                awsAppSyncClient={history.awsAppSyncClient}
              />
            </Container>
          </LocationProvider>
        </WithFeatureFlagsContext>
      </Loading>
    </ThemeProvider>
  );
};

export default App;
