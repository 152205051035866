import React from "react";

export default () => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7H8V6H2V7Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 9H8V8H2V9Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11H8V10H2V11Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2H7.61183C7.42658 1.79425 7.18863 1.63687 6.91538 1.55825C6.68504 1.49204 6.50796 1.31496 6.44175 1.08463C6.26154 0.458167 5.68425 0 5 0C4.31575 0 3.73846 0.458167 3.55825 1.08463C3.49204 1.31508 3.31429 1.49204 3.08383 1.55842C2.81071 1.63721 2.57308 1.79442 2.38817 2H1C0.44775 2 0 2.44775 0 3V12C0 12.5522 0.44775 13 1 13H9C9.55225 13 10 12.5522 10 12V3C10 2.44775 9.55225 2 9 2ZM5.0065 1C5.28254 1 5.5065 1.22379 5.5065 1.5C5.5065 1.77621 5.28254 2 5.0065 2C4.73029 2 4.5065 1.77621 4.5065 1.5C4.5065 1.22379 4.73029 1 5.0065 1ZM9 12H1V3H2V4H8V3H9V12Z"
      fill="#0173C6"
    />
  </svg>
);
