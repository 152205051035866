import React from "react";
import {
  SACard,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
  SABox,
  SAStack,
} from "@saux/design-system-react";
import { useTheme, useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import { formatPhone, formatTitleCase } from "../../util/formatters";
import PhoneSVG from "./icons/phone/Phone";
import MailSVG from "./icons/mail/Mail";
import CalendarSVG from "./icons/calendar/Calendar";
import ClipboardSVG from "./icons/clipboard/Clipboard";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import { GreyTextDark } from "../photosAndFilesSection/PhotosAndFiles";
import { AdjusterProps } from "../claimsDetail/ClaimsDetail";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { Snowplow } from "../../util/snowplow/snowplow";
import { DEFAULT_ADJUSTER_PHONE } from "../claimsDetail/ClaimAdjuster";

export interface ClaimAdjusterSectionProps {
  adjuster: AdjusterProps | undefined;
  isWorkerComp?: boolean;
}

export const LeftJustifiedBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: left;
`;

export const RightJustifiedBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export default ({ adjuster, isWorkerComp }: ClaimAdjusterSectionProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { medical, vehicle, property, injury } = useSelectedIncidentState();
  const { selected } = useClaimsApplicationState();

  const getResponsibilities = () => {
    if (medical) {
      return "Assist with injuries as the result of an incident by determining coverage, evaluating and processing medical bills and records, and helping our customers through the medical process.";
    }
    if (injury) {
      return "Handles the investigation of coverage, determines liability, and resolution of injury claims.";
    }
    if (vehicle) {
      return "Assists with vehicle repairs, rental reservations, extensions, and total loss settlements.";
    }
    if (property) {
      return "Assists with your property loss.";
    }
    return "Provides assistance with your claim.";
  };

  const getFromToWorkingHours = () => {
    const availability = adjuster?.adjusterAvailability;
    if (availability?.fromWorkHours && availability?.toWorkHours) {
      return `${adjuster?.adjusterAvailability?.fromWorkHours} to ${adjuster?.adjusterAvailability?.toWorkHours} EST`;
    }
    return "N/A";
  };

  const getWorkDays = () => {
    const workDays: string[] = [];
    const OFF_DAY = "";
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableMonday ? "Monday" : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableTuesday ? "Tuesday" : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableWednesday
        ? "Wednesday"
        : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableThursday ? "Thursday" : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableFriday ? "Friday" : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableSaturday ? "Saturday" : OFF_DAY
    );
    workDays.push(
      adjuster?.adjusterAvailability?.isAvailableSunday ? "Sunday" : OFF_DAY
    );
    workDays.push(OFF_DAY);

    let start = OFF_DAY;
    const groupedDays: string[] = [];
    for (let i = 0; i < workDays.length; i += 1) {
      if (workDays[i] === OFF_DAY && start !== OFF_DAY) {
        if (start !== workDays[i - 1]) {
          groupedDays.push(`${start} - ${workDays[i - 1]}`);
        } else {
          groupedDays.push(start);
        }
        start = OFF_DAY;
      }
      if (start === OFF_DAY && workDays[i] !== OFF_DAY) {
        start = workDays[i];
      }
    }

    return groupedDays.join(", ");
  };

  const workDays = getWorkDays();
  const workHours = getFromToWorkingHours();
  const responsibilities = getResponsibilities();

  const adjusterEmailClicked = () => {
    Snowplow.track.adjusterEmailClicked({
      adjusterName: adjuster?.displayName || "",
      adjusterEmail: adjuster?.email || "",
      claimNumber: selected || "",
    });
  };

  const adjusterPhoneClicked = () => {
    Snowplow.track.adjusterPhoneClicked({
      adjusterName: adjuster?.displayName || "",
      adjusterPhone: adjuster?.phoneNumber || "",
      claimNumber: selected || "",
    });
  };

  const SAI0005 = "State Auto Care Property Team";
  const adjusterName = formatTitleCase(adjuster?.displayName) || "N/A";

  return (
    <>
      {isMobile ? (
        <SABox m="small">
          <SACard variant="minimal">
            <SAStack spacing="medium">
              <SABox display="flex">
                <AltColorText
                  type="heading-3"
                  text={formatTitleCase(adjuster?.displayName) || ""}
                />
              </SABox>
              <SAColumns spacing="small" columns={[6, 6]}>
                <LeftJustifiedBox>
                  <GreyTextDark type="small" text="Phone" />
                  <SABox mt="xs">
                    <SAText
                      type="small"
                      onClick={adjusterPhoneClicked}
                      text={formatPhone(adjuster?.phoneNumber || "")}
                      weight="bold"
                    />
                  </SABox>
                </LeftJustifiedBox>
                <LeftJustifiedBox>
                  <GreyTextDark type="small" text="Email" />
                  <SABox mt="xs">
                    <SAText
                      type="small"
                      onClick={adjusterEmailClicked}
                      text={adjuster?.email || ""}
                      weight="bold"
                    />
                  </SABox>
                </LeftJustifiedBox>
              </SAColumns>
              <SAColumns spacing="small" columns={[6, 6]}>
                <LeftJustifiedBox>
                  <GreyTextDark type="small" text="Working Hours" />
                  <LeftJustifiedBox mt="xs">
                    <SAText type="small" text={workDays} />
                    <SAText type="small" text={workHours} />
                  </LeftJustifiedBox>
                </LeftJustifiedBox>
                <LeftJustifiedBox>
                  <GreyTextDark type="small" text="Responsibilities" />
                  <SABox mt="xs">
                    <SAText type="small" text={responsibilities} />
                  </SABox>
                </LeftJustifiedBox>
              </SAColumns>
            </SAStack>
          </SACard>
        </SABox>
      ) : (
        <SABox m="medium">
          <SACard variant="minimal">
            <SAColumns
              columns={{ md: [12, 6, 6], sm: [12, 12, 12] }}
              spacing="large"
            >
              <SABox display="flex">
                <SAText type="heading-3" text="Adjuster Information" />
              </SABox>
              <SABox sr="line">
                <SAStack spacing="medium">
                  <SABox display="flex">
                    <SABox p="xs">
                      <SAIcon icon={SAIcons.adjuster} size={SAIconSize.small} />
                    </SABox>
                    <LeftJustifiedBox>
                      <SABox p="xs">
                        <SAText type="small" text="Name" weight="bold" />
                      </SABox>
                      <SABox pl="xs">
                        <SAText
                          type="standard"
                          text={formatTitleCase(adjuster?.displayName) || "N/A"}
                        />
                      </SABox>
                    </LeftJustifiedBox>
                  </SABox>
                  {adjusterName !== SAI0005 && (
                    <SABox display="flex">
                      <SABox p="xs">
                        <MailSVG />
                      </SABox>
                      <LeftJustifiedBox>
                        <SABox p="xs">
                          <SAText type="small" text="Email" weight="bold" />
                        </SABox>
                        <SABox pl="xs">
                          <SAText
                            type="standard"
                            onClick={(e) => {
                              window.location.href = `mailto:${adjuster?.email}`;
                              e.preventDefault();
                              adjusterEmailClicked();
                            }}
                            text={adjuster?.email || ""}
                          />
                        </SABox>
                      </LeftJustifiedBox>
                    </SABox>
                  )}
                  {!isWorkerComp && (
                    <SABox display="flex">
                      <SABox p="xs">
                        <PhoneSVG />
                      </SABox>
                      <LeftJustifiedBox>
                        <SABox p="xs">
                          <SAText type="small" text="Phone" weight="bold" />
                        </SABox>
                        <SABox pl="xs">
                          <SAText
                            type="standard"
                            onClick={adjusterPhoneClicked}
                            text={formatPhone(adjuster?.phoneNumber || "")}
                          />
                        </SABox>
                      </LeftJustifiedBox>
                    </SABox>
                  )}
                </SAStack>
              </SABox>
              <SABox>
                <SAStack spacing="medium">
                  {isWorkerComp ? (
                    <SABox display="flex">
                      <SABox p="xs">
                        <PhoneSVG />
                      </SABox>
                      <LeftJustifiedBox>
                        <SABox p="xs">
                          <SAText type="small" text="Phone" weight="bold" />
                        </SABox>
                        <SABox pl="xs">
                          <SAText
                            type="standard"
                            onClick={adjusterPhoneClicked}
                            text={formatPhone(
                              adjuster?.phoneNumber || DEFAULT_ADJUSTER_PHONE
                            )}
                          />
                        </SABox>
                      </LeftJustifiedBox>
                    </SABox>
                  ) : (
                    <>
                      <SABox display="flex">
                        <SABox p="xs">
                          <CalendarSVG />
                        </SABox>
                        <LeftJustifiedBox>
                          <SABox p="xs">
                            <SAText
                              type="small"
                              text="Working Hours"
                              weight="bold"
                            />
                          </SABox>
                          <LeftJustifiedBox display="flex" pl="xs">
                            <SAText type="standard" text={workDays} />
                            <SAText type="standard" text={workHours} />
                          </LeftJustifiedBox>
                        </LeftJustifiedBox>
                      </SABox>
                      <SABox display="flex">
                        <SABox p="xs">
                          <ClipboardSVG />
                        </SABox>
                        <LeftJustifiedBox>
                          <SABox p="xs">
                            <SAText
                              type="small"
                              text="Responsibilities"
                              weight="bold"
                            />
                          </SABox>
                          <SABox pl="xs">
                            <SAText type="standard" text={responsibilities} />
                          </SABox>
                        </LeftJustifiedBox>
                      </SABox>
                    </>
                  )}
                </SAStack>
              </SABox>
            </SAColumns>
          </SACard>
        </SABox>
      )}
    </>
  );
};
