import React, { useEffect, useState } from "react";
import {
  SAModal,
  SACard,
  SAText,
  SABox,
  SAInput,
  SACheckbox,
  SAStack,
  SASelect,
  SAButton,
  SASelectOption,
} from "@saux/design-system-react";
import styled from "styled-components";
import { ClaimData } from "../recentClaimsContainer/RecentClaimsContainer";
import { Snowplow } from "../../util/snowplow/snowplow";
import { useClaimsApplicationState } from "../../providers";

export const CenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SACheckBoxContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SAButtonsContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const SACardContainer = styled(SACard)`
  width: 500px;
  overflow: auto;
  max-height: 675px;
`;
export interface WcAdvancedFilterModalProps {
  closeModal: () => void;
  setActiveFilterCount: (no: Number) => void;
  setFilteredClaimList: (claimList: ClaimData[]) => void;
  openAdvancedFilterModal: boolean;
  wcClaimsList: ClaimData[] | undefined;
  clearFilterClicked: boolean;
  setClearFilterClicked: (state: boolean) => void;
}

export const WcAdvancedFilterModal = ({
  closeModal,
  setActiveFilterCount,
  setFilteredClaimList,
  openAdvancedFilterModal,
  wcClaimsList,
  clearFilterClicked,
  setClearFilterClicked,
}: WcAdvancedFilterModalProps) => {
  const [claimStatus, setClaimStatus] = useState<boolean | undefined>();
  const [injuredName, setInjuredName] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [dobError, setDobError] = useState<boolean>(false);
  const [filtersChanged, setFiltersChanged] = useState<boolean>(false);
  // states for loss location
  const [lossLocationOptions, setLossLocationOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [selectedLossLocation, setSelectedLossLocation] = useState<string[]>(
    []
  );
  const { accountNumber } = useClaimsApplicationState();

  useEffect(() => {
    const currDate = new Date();
    const currStartDate = new Date(startDate);
    const currEndDate = new Date(endDate);

    if (currStartDate > currDate) {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    if (currStartDate > currEndDate) {
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const currDate = new Date();
    const currDob = new Date(dob);

    if (currDob > currDate) {
      setDobError(true);
    } else {
      setDobError(false);
    }
  }, [dob]);

  useEffect(() => {
    const currlossLocationOptions: { label: string; value: string }[] = [];

    if (wcClaimsList) {
      wcClaimsList.forEach((claim: ClaimData) => {
        if (
          claim.lossLocation &&
          !currlossLocationOptions.find(
            (option) =>
              option.value ===
              claim.lossLocation?.toLowerCase().replace(/,/g, "")
          )
        ) {
          currlossLocationOptions.push({
            value: claim.lossLocation.toLowerCase().replace(/,/g, ""),
            label: claim.lossLocation,
          });
        }
      });
    }

    const currlossLocationOptionsSorted: { label: string; value: string }[] = [
      ...currlossLocationOptions,
    ].sort((a, b) => (a.value > b.value ? 1 : -1));

    setLossLocationOptions(currlossLocationOptionsSorted);
  }, [wcClaimsList]);

  const handleFilterClick = () => {
    let currClaimList: ClaimData[] | undefined =
      wcClaimsList !== undefined ? [...wcClaimsList] : [];
    let activeFilterCount = 0;
    const filterNames: string[] = [];

    if (currClaimList) {
      if (injuredName) {
        currClaimList = currClaimList.filter(
          (claimItem) =>
            claimItem.injuredName &&
            claimItem.injuredName
              .toLowerCase()
              .indexOf(injuredName.toLowerCase()) >= 0
        );
        activeFilterCount += 1;
        filterNames.push("injuredName");
      }

      if (claimStatus !== undefined) {
        currClaimList = currClaimList.filter((claim: ClaimData) =>
          claimStatus
            ? claim.status.toLowerCase() === "open"
            : claim.status.toLowerCase() === "closed"
        );
        activeFilterCount += 1;
        filterNames.push("claimStatus");
      }

      if (selectedLossLocation.length) {
        currClaimList = currClaimList.filter(
          (claim: ClaimData) =>
            claim.lossLocation !== undefined &&
            selectedLossLocation.includes(
              claim.lossLocation?.toLowerCase().replace(/,/g, "")
            )
        );
        activeFilterCount += 1;
        filterNames.push("lossLocation");
      }

      if (startDate) {
        currClaimList = currClaimList.filter(
          (claim: ClaimData) => new Date(claim.dateOfLoss) > new Date(startDate)
        );
        activeFilterCount += 1;
        filterNames.push("dateOfInjuryStartDate");
      }

      if (endDate) {
        currClaimList = currClaimList.filter(
          (claim: ClaimData) => new Date(claim.dateOfLoss) < new Date(endDate)
        );
        activeFilterCount += 1;
        filterNames.push("dateOfInjuryEndDate");
      }

      if (dob) {
        const dobObjectDate = new Date(dob).getDate();
        const dobObjectMonth = new Date(dob).getMonth();
        const dobObjectYear = new Date(dob).getFullYear();

        currClaimList = currClaimList.filter(
          (claim: ClaimData) =>
            claim.dateOfBirth &&
            claim.dateOfBirth.getDate() === dobObjectDate &&
            claim.dateOfBirth.getMonth() === dobObjectMonth &&
            claim.dateOfBirth.getFullYear() === dobObjectYear
        );
        activeFilterCount += 1;
        filterNames.push("dateOfBirth");
      }

      setFilteredClaimList(currClaimList);

      if (filterNames.length >= 1) {
        Snowplow.track.wcClaimsFilterApplied({
          accountNumber: accountNumber || "",
          filters: filterNames,
        });
      }
    }

    setActiveFilterCount(activeFilterCount);
    setFiltersChanged(false);
    closeModal();
  };

  const handleClearClick = () => {
    if (wcClaimsList) {
      setFilteredClaimList(wcClaimsList);
    }

    setInjuredName("");
    setClaimStatus(undefined);
    setSelectedLossLocation([]);
    setStartDate("");
    setEndDate("");
    setDob("");

    setActiveFilterCount(0);
    setFiltersChanged(false);
    closeModal();
  };

  useEffect(() => {
    if (clearFilterClicked) {
      handleClearClick();
      setClearFilterClicked(false);
    }
  }, [clearFilterClicked]);

  return openAdvancedFilterModal ? (
    <SAModal onClickHandler={() => {}}>
      <CenteredBox>
        <SACardContainer variant="minimal">
          <SABox pb="medium">
            <SAText
              text="Filter Existing Claims Associated with Policy"
              type="heading-3"
            />
          </SABox>
          <SAStack spacing="medium">
            <SAInput
              id="injuredName"
              name="Injured Name"
              label="Injured Name"
              type="text"
              fullWidth
              defaultValue={injuredName}
              onChange={(e) => {
                setInjuredName(e.currentTarget.value);
                setFiltersChanged(true);
              }}
            />
            <SABox>
              <SAText text="Claim Status" />
              <SACheckBoxContainer>
                <SABox pr="medium">
                  <SACheckbox
                    id="openCheckbox"
                    label="Open"
                    checked={claimStatus === undefined ? false : claimStatus}
                    onChange={(e) => {
                      setFiltersChanged(true);
                      if (e.currentTarget.checked) {
                        setClaimStatus(true);
                      } else {
                        setClaimStatus(undefined);
                      }
                    }}
                  />
                </SABox>
                <SACheckbox
                  id="closedCheckbox"
                  label="Closed"
                  checked={claimStatus === undefined ? false : !claimStatus}
                  onChange={(e) => {
                    setFiltersChanged(true);
                    if (e.currentTarget.checked) {
                      setClaimStatus(false);
                    } else {
                      setClaimStatus(undefined);
                    }
                  }}
                />
              </SACheckBoxContainer>
            </SABox>
            <SABox>
              <SAText text="Loss Location" />
              <SABox pt="small">
                <SASelect
                  fullWidth
                  label="Select Loss Location"
                  id="lossLocation"
                  multiple
                  variant="checkbox"
                  clickToRemove
                  onChange={(e: any) => {
                    setFiltersChanged(true);
                    setSelectedLossLocation(e.target.value);
                  }}
                >
                  {lossLocationOptions.map((lossLocation) => (
                    <SASelectOption
                      value={lossLocation.value}
                      selected={selectedLossLocation.includes(
                        lossLocation.value
                      )}
                      key={lossLocation.value}
                    >
                      {lossLocation.label}
                    </SASelectOption>
                  ))}
                </SASelect>
              </SABox>
            </SABox>
            <SABox>
              <SAText text="Date of Injury" />
              <SACheckBoxContainer pt="small">
                <SAInput
                  id="startDate"
                  data-testId="startDate"
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={startDate}
                  onChange={(e) => {
                    setFiltersChanged(true);
                    setStartDate(e.currentTarget.value);
                  }}
                  error={startDateError}
                  hint={
                    startDateError ? "Start date cannot be a future date" : ""
                  }
                />
                <SABox pl="small" pr="small">
                  <SAText text="-" />
                </SABox>
                <SAInput
                  id="endDate"
                  label="End Date"
                  data-testId="endDate"
                  type="date"
                  defaultValue={endDate}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFiltersChanged(true);
                    setEndDate(e.currentTarget.value);
                  }}
                  error={endDateError}
                  hint={endDateError ? "End date cannot before start Date" : ""}
                />
              </SACheckBoxContainer>
            </SABox>

            <SAInput
              id="injuredDOB"
              data-testId="injuredDOB"
              label="Injured Date Of Birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={dob}
              onChange={(e) => {
                setFiltersChanged(true);
                setDob(e.currentTarget.value);
              }}
              error={dobError}
              hint={dobError ? "Date of birth cannot be a future date" : ""}
            />
            <SAButtonsContainer>
              <SABox pr="small">
                <SAButton
                  fullWidth={false}
                  label="CLEAR"
                  onClick={handleClearClick}
                  textTransform="uppercase"
                  variant="secondary-medium-outline"
                />
              </SABox>
              <SAButton
                fullWidth={false}
                label="FILTER"
                onClick={handleFilterClick}
                textTransform="uppercase"
                variant="primary-medium"
                disabled={
                  !filtersChanged || startDateError || endDateError || dobError
                }
              />
            </SAButtonsContainer>
          </SAStack>
        </SACardContainer>
      </CenteredBox>
    </SAModal>
  ) : (
    <></>
  );
};
