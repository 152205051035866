import dev from "./dev.json";
import staging from "./staging.json";
import prod from "./prod.json";

export enum Env {
  Dev = "DEV",
  Staging = "STAGING",
  Prod = "PROD",
}

export default (env: Env) => {
  switch (env) {
    case Env.Prod:
      return prod;
    case Env.Staging:
      return staging;
    case Env.Dev:
    default:
      return dev;
  }
};
