import { SABox, SAColumns } from "@saux/design-system-react";
import React, { ReactElement } from "react";
import styled from "styled-components";

export interface Step {
  stepHeader: ReactElement | Element;
  stepElement: ReactElement | Element;
  stepKey: string;
}

export interface SlideProps {
  steps: Step[];
  activeStepIndex?: number;
  stepCallback: (index: number) => void;
  displayCallbackIcon?: boolean | undefined;
}

const MobileRelativeContainer = styled(SABox)`
  position: relative;
  height: 100vh;
  width: 100%;
`;

const StepContainer = styled(SABox)`
  position: absolute;
  left: -400%;
  transition: left 1s;
  top: 0;
  bottom: 0;
`;

const ActiveStepContainer = styled(StepContainer)`
  transition: left 1s;
  left: 0;
  width: inherit;
`;

const StepHeader = styled.header`
  & {
    &.--blue {
      background-color: #f4f6fa;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -20px;
      margin-top: -20px;
      padding: 27px 0 50px 0;
    }
  }
`;

const StepContentContainer = styled(SABox)`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 11rem);

  & {
    &.--blue {
      position: relative;
      bottom: 31px;
      height: 100%;
      overflow-y: hidden;
      overflow-x: hidden;
    }
  }
`;

const IconButton = styled.button`
  border: none;
  position: relative;
  left: 25px;
  top: 5px;
`;

const LeftArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
  >
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00292969 0H18V14.9508H0.00292969V0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9882 6.06381L17.0164 6.0701H4.97142L8.758 2.27532C8.94342 2.08995 9.04518 1.83898 9.04518 1.57566C9.04518 1.3121 8.94342 1.06288 8.758 0.877268L8.16883 0.287707C7.98342 0.102488 7.73669 0 7.47332 0C7.21 0 6.96283 0.101756 6.77761 0.286976L0.28698 6.77707C0.10098 6.96322 -0.000776031 7.21093 4.4567e-06 7.47444C-0.000776031 7.73951 0.10098 7.98741 0.28698 8.17302L6.77761 14.6639C6.96283 14.8489 7.20981 14.9508 7.47332 14.9508C7.73669 14.9508 7.98342 14.8487 8.16883 14.6639L8.758 14.0743C8.94342 13.8893 9.04518 13.6423 9.04518 13.379C9.04518 13.1157 8.94342 12.8817 8.758 12.6964L4.92874 8.88054H17.0017C17.5442 8.88054 18 8.41293 18 7.87078V7.03698C18 6.49483 17.5307 6.06381 16.9882 6.06381Z"
        fill="#0173C6"
      />
    </g>
  </svg>
);

export const Slide = ({
  steps,
  activeStepIndex = 0,
  stepCallback,
  displayCallbackIcon,
}: SlideProps) => {
  return (
    <MobileRelativeContainer>
      {steps.map((step: Step, index: number) => {
        const Container =
          activeStepIndex === index ? ActiveStepContainer : StepContainer;
        return (
          <Container
            key={step.stepKey}
            data-testid={`am-${index}-${activeStepIndex}`}
          >
            <StepHeader className={displayCallbackIcon ? "--blue" : "--normal"}>
              <nav aria-labelledby={`${step.stepKey}--header`}>
                <SAColumns
                  columns={displayCallbackIcon ? [2, 10] : [12]}
                  spacing="medium"
                >
                  {displayCallbackIcon && (
                    <IconButton
                      type="button"
                      onClick={() => stepCallback(index)}
                      data-testid={`${index}-cb`}
                    >
                      <LeftArrowIcon />
                    </IconButton>
                  )}
                  <div id={`${step.stepKey}--header`}>{step.stepHeader}</div>
                </SAColumns>
              </nav>
            </StepHeader>
            <StepContentContainer
              className={
                activeStepIndex === index && index === 0 ? "--normal" : "--blue"
              }
            >
              {step.stepElement}
            </StepContentContainer>
          </Container>
        );
      })}
    </MobileRelativeContainer>
  );
};
