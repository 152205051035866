/* eslint-disable no-unused-vars */
import React from "react";
import ErrorBanner from "./errorBanner/ErrorBanner";
import PartialSuccessBanner from "./partialSuccessBanner/PartialSuccessBanner";
import SuccessBanner from "./successBanner/SuccessBanner";

export enum ModalBannerNames {
  error = "error",
  partialSuccess = "partialSuccess",
  success = "success",
}

export interface CloseBannerProps {
  closeBanner: (value: ModalBannerNames | false) => void;
}

interface UploadBannerProps {
  displayBanner: ModalBannerNames | false;
}

export default ({
  displayBanner,
  closeBanner,
}: UploadBannerProps & CloseBannerProps) => {
  let banner: any;

  if (displayBanner === ModalBannerNames.error) {
    banner = <ErrorBanner closeBanner={closeBanner} />;
  } else if (displayBanner === ModalBannerNames.partialSuccess) {
    banner = <PartialSuccessBanner closeBanner={closeBanner} />;
  } else {
    banner = <SuccessBanner closeBanner={closeBanner} />;
  }

  if (displayBanner) {
    return banner;
  }
  return null;
};
