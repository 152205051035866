import React, { createContext, ReactElement, useContext } from "react";
import {
  VehicleProps,
  PropertyProps,
  InjuryProps,
  MedicalProps,
  AdjusterProps,
  WorkerCompProps,
} from "../components/claimsDetail/ClaimsDetail";
import { ClaimsSummaryWrapperProps } from "../components/claimsSummaryWrapper/ClaimsSummaryWrapper";

export interface SelectedIncidentStateContextProps {
  publicID?: string;
  insuredAsset?: string;
  summary?: ClaimsSummaryWrapperProps;
  vehicle?: VehicleProps;
  property?: PropertyProps;
  injury?: InjuryProps;
  workerComp?: WorkerCompProps;
  medical?: MedicalProps;
  adjuster?: AdjusterProps;
  status?: String;
  selected?: string;
}

const SelectedIncidentStateContext = createContext<
  SelectedIncidentStateContextProps | {}
>({});

export interface SelectedIncidentStateProps {
  children: ReactElement;
  value: SelectedIncidentStateContextProps;
}

export const SelectedIncidentState = ({
  children,
  value,
}: SelectedIncidentStateProps) => (
  <SelectedIncidentStateContext.Provider value={value}>
    {children}
  </SelectedIncidentStateContext.Provider>
);

export const useSelectedIncidentState = (): SelectedIncidentStateContextProps => {
  return useContext(SelectedIncidentStateContext);
};
