import React from "react";
import { SABox, SACard, SAStack } from "@saux/design-system-react";
import FAQItem, { FAQ } from "./FAQItem";

export interface GeneralFAQSchema {
  faqs: FAQ[];
}

export default ({ faqs }: GeneralFAQSchema) => {
  return (
    <SABox>
      <SACard
        variant="standard"
        title="Frequently Asked Questions"
        removePadding
      >
        <SABox>
          <SAStack separator="line">
            {faqs?.map((faq: FAQ) => (
              <FAQItem
                key={faq.question}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </SAStack>
        </SABox>
      </SACard>
    </SABox>
  );
};
