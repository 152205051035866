import React, { useEffect } from "react";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import {
  SAAccordion,
  SABox,
  SAColumns,
  SAStack,
  SATable,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import { TextAlignLeftBox } from "../styled";
import {
  ClaimInformation,
  ClaimsReserveDetails,
  PaymentSummaryDetails,
} from "../claimsDetail/ClaimsDetail";
import { useClaimsApplicationState } from "../../providers";
import { Loading } from "../loading";
import { formatDate, formatMoney } from "../../util/formatters";
import { RowItem } from "../common/RowItemContainer";
import { Snowplow } from "../../util/snowplow/snowplow";

const get_claim_overview_details = loader(
  "../../graphql/queries/Get_Claim_Overview_Details.graphql"
);

const StyledAccordion = styled(SAAccordion)`
  .accordion-summary {
    background: #ffffff;
  }
  .accordion-title {
    font-size: 18px;
  }
  background: #ffffff;
`;
const WidthSABox = styled(SABox)`
  max-width: 250px;
`;

export const updateTableData = (
  claimsReserveDetails: ClaimsReserveDetails,
  paymentSumDetails: PaymentSummaryDetails
) => {
  const firstRow: any[] = [];
  const secondRow: any[] = [];

  firstRow.push(claimsReserveDetails?.medical_reserves);
  firstRow.push(claimsReserveDetails?.indemnity_reserves);
  firstRow.push(claimsReserveDetails?.expense_reserves);
  firstRow.push(claimsReserveDetails?.legal_reserves);
  firstRow.push(claimsReserveDetails?.voc_rehab_reserves);
  firstRow.push(
    firstRow.reduce((total, value) => {
      if (value === undefined) {
        return total + 0;
      }
      return total + value;
    }, 0)
  );

  secondRow.push(paymentSumDetails?.medical_ptd);
  secondRow.push(paymentSumDetails?.indemnity_ptd);
  secondRow.push(paymentSumDetails?.expense_ptd);
  secondRow.push(paymentSumDetails?.legal_ptd);
  secondRow.push(paymentSumDetails?.voc_rehab_ptd);
  secondRow.push(
    secondRow.reduce((total, value) => {
      if (value === undefined) {
        return total + 0;
      }
      return total + value;
    }, 0)
  );

  const thirdRow: any[] = firstRow.map(
    (item: number | undefined, index: number) => {
      if (item !== undefined && secondRow[index] !== undefined) {
        return item - secondRow[index];
      }
      return undefined;
    }
  );

  return [
    ["Incurred Reserves", ...firstRow.map((value) => formatMoney(value))],
    ["Paid to Date", ...secondRow.map((value) => formatMoney(value))],
    ["Remaining Reserves", ...thirdRow.map((value) => formatMoney(value))],
  ];
};

export default () => {
  const {
    accountNumber,
    selectedPolicyNumber,
    selected,
  } = useClaimsApplicationState();

  const processColumnToGreen = (value: string) => {
    return <SAText type="standard" text={value} colorVariant="secondary" />;
  };

  const columns = [
    {
      bold: true,
      name: "Summary",
    },
    "Medical",
    "Indemnity",
    "Expense",
    "Legal",
    "Voc Rehab",
    {
      name: "TOTAL",
      process: processColumnToGreen,
    },
  ];

  // claims Details Overview query
  const getClaimDetailsRequestVariables = {
    claimNumber: selected,
    accountNumber,
    policyNumber: selectedPolicyNumber,
  };

  const [
    getClaimOverviewDetailsQuery,
    {
      called: claimCallCalled,
      loading: claimCallLoading,
      data: getClaimOverviewDetailsData,
      error: getClaimOverviewDetailsError,
    },
  ] = useLazyQuery(get_claim_overview_details, {
    variables: getClaimDetailsRequestVariables,
  });

  useEffect(() => {
    getClaimOverviewDetailsQuery();
  }, []);

  const handleChange = (event: string) => (
    _e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      Snowplow.track.wcSnowplowEvent({
        event,
        accountNumber: accountNumber || "",
        claimNumber: selected || "",
        policyNumber: selectedPolicyNumber || "",
      });
    }
  };

  const overviewDetails = getClaimOverviewDetailsData?.getWCDetails;
  const claimStatus = overviewDetails?.claim_status || "N/A";
  const liability = overviewDetails?.liability || "N/A";
  const claimInformation: ClaimInformation = overviewDetails?.claimInformation;
  const tableData = updateTableData(
    overviewDetails?.claimsReserveDetails,
    overviewDetails?.paymentSumDetails
  );

  return (
    <Loading
      loading={claimCallCalled && claimCallLoading}
      error={getClaimOverviewDetailsError}
    >
      <SAStack spacing="medium">
        <StyledAccordion
          title="Claim and Liability Status"
          variant="standard"
          onChange={handleChange("wcClaimAndLiabilityStatusTabOpened")}
        >
          <WidthSABox>
            <SAColumns columns={{ md: [5, 7] }} spacing="xs">
              <TextAlignLeftBox pb="medium">
                <AltColorText type="small" text="Claim Status:" />
              </TextAlignLeftBox>
              <TextAlignLeftBox pb="medium">
                <SAText
                  text={claimStatus}
                  colorVariant={
                    claimStatus?.toLowerCase() === "open"
                      ? "secondary"
                      : "alert"
                  }
                  weight="bold"
                />
              </TextAlignLeftBox>
            </SAColumns>
            <SAColumns columns={{ md: [5, 7] }} spacing="xs">
              <TextAlignLeftBox pb="xxs">
                <AltColorText type="small" text="Liability Status:" />
              </TextAlignLeftBox>
              <TextAlignLeftBox pb="xxs">
                <SAText text={liability} weight="bold" />
              </TextAlignLeftBox>
            </SAColumns>
          </WidthSABox>
        </StyledAccordion>

        <StyledAccordion
          title="Claim Information"
          variant="standard"
          onChange={handleChange("wcClaimInformationTabOpened")}
        >
          <SAColumns columns={{ xs: [12], sm: [12] }} spacing="medium">
            <RowItem
              headerText="Primary and Secondary Diagnosis"
              contentText={
                [
                  claimInformation?.primarydiagnosis,
                  claimInformation?.secondarydiagnosis,
                ]
                  .filter((d) => !!d)
                  .join(",") || "N/A"
              }
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [6, 6] }} spacing="medium">
            <RowItem
              headerText="Average Gross Weekly Wage"
              contentText={formatMoney(claimInformation?.agww) || "N/A"}
            />
            <RowItem
              headerText="Temporary Total Disability Rate"
              contentText={formatMoney(claimInformation?.ttdrate) || "N/A"}
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [6, 6] }} spacing="medium">
            <RowItem
              headerText="First Day of Lost Time"
              contentText={
                claimInformation?.firstdayoflosttime
                  ? (formatDate(claimInformation?.firstdayoflosttime) as string)
                  : "N/A"
              }
            />
            <RowItem
              headerText="First Day of Indemnity"
              contentText={
                claimInformation?.firstdayindemnity
                  ? (formatDate(claimInformation?.firstdayindemnity) as string)
                  : "N/A"
              }
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [12] }} spacing="medium">
            <RowItem
              headerText="Treating Provider"
              contentText={claimInformation?.treatingprovider || "N/A"}
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [12] }} spacing="medium">
            <RowItem
              headerText="Work status"
              contentText={claimInformation?.workstatus || "N/A"}
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [6, 6] }} spacing="medium">
            <RowItem
              headerText="Return to Clinic Date"
              contentText={
                claimInformation?.returntoclinicdate
                  ? (formatDate(claimInformation?.returntoclinicdate) as string)
                  : "N/A"
              }
            />
            <RowItem
              headerText="Return to Clinic Doctor"
              contentText={claimInformation?.returntoclinicprovider || "N/A"}
            />
          </SAColumns>
          <SAColumns columns={{ xs: [12], sm: [12] }} spacing="medium">
            <RowItem
              headerText="Maximum Medical Improvement Date"
              contentText={
                claimInformation?.mmidate
                  ? (formatDate(claimInformation?.mmidate) as string)
                  : "N/A"
              }
            />
          </SAColumns>
        </StyledAccordion>

        <StyledAccordion
          title="Reserves"
          variant="standard"
          onChange={handleChange("wcReservesTabOpened")}
        >
          <SABox data-testid="reserves-table">
            <SATable
              columns={columns}
              data={tableData}
              disableRowAccent
              variant="table-to-listview"
            />
          </SABox>
        </StyledAccordion>
      </SAStack>
    </Loading>
  );
};
