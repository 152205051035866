import { navigate } from "@reach/router";
import axios, { AxiosResponse } from "axios";
import getConfig, { Env } from "../config";

const config = getConfig(process.env.REACT_APP_ENV as Env);

const FAQ_URL = config.faq_url;

export interface SignedResponseFields {
  key: string;
  AWSAccessKeyId: string;
  "x-amz-security-token": string;
  policy: string;
  signature: string;
}

export interface SignedResponse {
  url: string;
  fields: SignedResponseFields;
}

export interface SuccessSignedResponse {
  data: SignedResponse;
}

export interface OtherOptions {
  type: string | null;
}

export const uploadToS3 = (signedResponse: any, file: File): Promise<any> => {
  const formData = new FormData();

  formData.append("Key", signedResponse.fields.Key);
  formData.append("bucket", signedResponse.fields.bucket);
  formData.append("X-Amz-Algorithm", signedResponse.fields.Algorithm);
  formData.append("X-Amz-Credential", signedResponse.fields.Credential);
  formData.append("X-Amz-Security-Token", signedResponse.fields.SecurityToken);
  formData.append("X-Amz-Date", signedResponse.fields.Date);
  formData.append("Policy", signedResponse.fields.Policy);
  formData.append("X-Amz-Signature", signedResponse.fields.Signature);

  formData.append("file", file);

  const options = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return axios.post(signedResponse.url, formData, options);
};

export interface FAQType {
  type: string;
}

export interface FAQResponse {
  data: any[];
}

export interface Response<T> {
  data: T;
  errors: string[];
  hasErrors: boolean;
}

export interface FeatureFlagObject {
  application_name: string;
  created_date: string;
  description: string;
  enabled: boolean;
  flag_name: string;
  modified_date: string;
  type: string;
}

export const fetchFAQS = (payload: FAQType) => {
  return axios.post<FAQType, AxiosResponse<FAQResponse>>(
    `${FAQ_URL}`,
    JSON.stringify(payload),
    {}
  );
};

export const fetchFeatureFlags = () => {
  return axios.get<Response<FeatureFlagObject[]>>(config.feature_flag_url, {});
};

export const download = (contents: string) => {
  const newWindow = window.open(contents);
  if (newWindow) {
    const newTab = newWindow.document.body.appendChild(
      newWindow.document.createElement("iframe")
    );
    newTab.width = "100%";
    newTab.height = "100%";
    newTab.setAttribute("style", "border: 0");
    newTab.src = contents;
  }
};

export const REDIRECT_PATH = "maintenance";

// axios interceptors for error handling

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    // Any status code that lie within the
    // range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the
    // range of 2xx cause this function to trigger
    // Do something with response error
    const { url } = error.config;

    const isFAQFetchError = url.match(FAQ_URL)
      ? url.match(FAQ_URL).length > 0
      : false;

    if (isFAQFetchError && error.response.status === 503) {
      navigate(`/${REDIRECT_PATH}`);
    }

    return Promise.reject(error);
  }
);
