import React from "react";

export interface TrashIconProps {
  variant: string | undefined;
}

export default ({ variant }: TrashIconProps) => {
  const color = variant === "alert" ? "#ED323B" : "#666666";
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2V3.5H12V2H6ZM14 3.5V1C14 0.447715 13.5523 0 13 0H5C4.44772 0 4 0.447715 4 1V3.5H3H1C0.447715 3.5 0 3.94772 0 4.5C0 5.05228 0.447715 5.5 1 5.5H2V19C2 19.5523 2.44772 20 3 20H15C15.5523 20 16 19.5523 16 19V5.5H17C17.5523 5.5 18 5.05228 18 4.5C18 3.94772 17.5523 3.5 17 3.5H15H14ZM14 5.5H13H5H4V18H14V5.5ZM11 8C11.5523 8 12 8.44771 12 9V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V9C10 8.44771 10.4477 8 11 8ZM8 9C8 8.44771 7.55228 8 7 8C6.44772 8 6 8.44771 6 9V15C6 15.5523 6.44772 16 7 16C7.55228 16 8 15.5523 8 15V9Z"
        fill={color}
      />
    </svg>
  );
};
