import { SABox, SAText } from "@saux/design-system-react";
import React from "react";
import { Accordion } from "../accordion";
import { VendorPaymentDetails } from "../claimsDetail/ClaimsDetail";
import { WCClaimPayeeHeader } from "./WCClaimPayeeHeader";
import { WCClaimPaymentsTable } from "./WCClaimPaymentsTable";

interface WCClaimPayeeProps {
  payee: VendorPaymentDetails;
}

export const WCClaimPayee = ({ payee }: WCClaimPayeeProps) => {
  const payeeName = payee?.name;
  const chargedToDate = payee?.chargedtodate;
  const paidToDate = payee?.paidtodate;
  const paymentDetails = payee?.paymentDetails;

  return (
    <SABox p="medium">
      <Accordion
        displayIcon
        showBorder={false}
        title={
          <WCClaimPayeeHeader
            payeeName={payeeName}
            chargedToDate={chargedToDate}
            paidToDate={paidToDate}
          />
        }
        content={
          paymentDetails?.length ? (
            <WCClaimPaymentsTable paymentDetails={paymentDetails} />
          ) : (
            <SABox pt="large">
              <SAText>
                No payments found. If filters are applied, try expanding your
                search criteria.
              </SAText>
            </SABox>
          )
        }
      />
    </SABox>
  );
};
