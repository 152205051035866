import React from "react";

export default () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2898 4.35996L1.81978 18.5C1.64514 18.8024 1.55274 19.1453 1.55177 19.4945C1.55079 19.8437 1.64127 20.1871 1.8142 20.4905C1.98714 20.7939 2.2365 21.0467 2.53748 21.2238C2.83847 21.4009 3.18058 21.4961 3.52978 21.5H20.4698C20.819 21.4961 21.1611 21.4009 21.4621 21.2238C21.7631 21.0467 22.0124 20.7939 22.1854 20.4905C22.3583 20.1871 22.4488 19.8437 22.4478 19.4945C22.4468 19.1453 22.3544 18.8024 22.1798 18.5L13.7098 4.35996C13.5315 4.06607 13.2805 3.82308 12.981 3.65444C12.6814 3.48581 12.3435 3.39722 11.9998 3.39722C11.656 3.39722 11.3181 3.48581 11.0186 3.65444C10.7191 3.82308 10.468 4.06607 10.2898 4.35996V4.35996Z"
      stroke="#F5A524"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17.5H12.01"
      stroke="#F5A524"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9.5V13.5"
      stroke="#F5A524"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
