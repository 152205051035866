import {
  SABox,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
  SATag,
  SAText,
  SAUX360Theme,
} from "@saux/design-system-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CashIcon } from "../../assets/Icons";
import { formatMoney } from "../../util/formatters";
import { PaymentDetails } from "../claimsDetail/ClaimsDetail";

export enum PaymentFields {
  Status = "Status",
  FromDate = "From Date",
  ThruDate = "Thru Date",
  CheckNumber = "Check #",
  CheckDate = "Check Date",
  Charged = "Charged",
  Paid = "Paid",
}

export const convertDate = (dateString: string) => {
  let dateArg: string = dateString;

  if (/^(\d{2}\/){2}\d{4}$/.test(dateArg)) {
    const dateElements = dateArg.split("/");
    dateArg = `${dateElements[2]}-${dateElements[0]}-${dateElements[1]}T00:00:00.000Z`;
  }

  return new Date(dateArg).getTime();
};

export const getPaymentsSortFunction = (
  field: PaymentFields,
  isAscending: boolean
) => {
  let greaterThan = 1;
  let lessThan = -1;

  if (!isAscending) {
    greaterThan = -1;
    lessThan = 1;
  }

  let sortField: keyof PaymentDetails = "checkdate";

  switch (field) {
    case PaymentFields.Status:
      sortField = "paymentstatus";
      break;
    case PaymentFields.FromDate:
      sortField = "service_fromdate";
      break;
    case PaymentFields.ThruDate:
      sortField = "service_thrudate";
      break;
    case PaymentFields.CheckNumber:
      sortField = "checknumber";
      break;
    case PaymentFields.CheckDate:
      sortField = "checkdate";
      break;
    case PaymentFields.Charged:
      sortField = "chargedamount";
      break;
    case PaymentFields.Paid:
      sortField = "paidamount";
      break;
    default:
      sortField = "checkdate";
      break;
  }

  return (a: PaymentDetails, b: PaymentDetails) => {
    let aValue: any = a[sortField];
    let bValue: any = b[sortField];

    if (
      [
        PaymentFields.ThruDate,
        PaymentFields.FromDate,
        PaymentFields.ThruDate,
      ].includes(field)
    ) {
      aValue = convertDate(aValue);
      bValue = convertDate(bValue);
    }

    if (aValue !== undefined && bValue === undefined) {
      return greaterThan;
    }

    if (aValue === undefined && bValue !== undefined) {
      return lessThan;
    }

    if (aValue === bValue) {
      return 1;
    }

    return aValue > bValue ? greaterThan : lessThan;
  };
};

const LoadMoreContainer = styled(SABox)`
  display: flex;
  justify-content: center;
`;

const ColumnLabelContainer = styled(SABox)`
  display: flex;
  justify-content: space-between;
`;

interface PaymentsTableSort {
  field: PaymentFields;
  isAscending: boolean;
}

interface ColumnLabelProps {
  text: string;
  sort: PaymentsTableSort;
  onClick: (params: any) => void;
}

const ColumnLabel = ({ text, sort, onClick }: ColumnLabelProps) => {
  return (
    <ColumnLabelContainer
      onClick={onClick}
      data-testid={`${text}-column-label`}
    >
      <SAText type="heading-4">{text}</SAText>
      {sort.field === text && (
        <SAIcon
          icon={sort.isAscending ? SAIcons.chevronUp : SAIcons.chevronDown}
          size={SAIconSize.small}
          colorVariant="dark"
          data-testid={`${sort.field}-${
            sort.isAscending ? "ascending" : "descending"
          }`}
        />
      )}
    </ColumnLabelContainer>
  );
};

interface PaymentsColumnOne {
  status: string;
  id: string;
  index: number;
}

const processColumn1 = ({ status, id, index }: PaymentsColumnOne) => {
  const dataTestId = `wc-payments-table-row-${index}-${id}`;

  return (
    <>
      {status.toLowerCase() === "paid" ? (
        <SATag
          variant="secondary"
          label="Paid"
          startIcon={<CashIcon color={SAUX360Theme.color.secondary} />}
          data-testid={`${dataTestId}-paid`}
        />
      ) : (
        <SATag
          variant="alert"
          label="Voided"
          startIcon={
            <SAIcon
              icon={SAIcons.error}
              size={SAIconSize.small}
              colorVariant="alert"
              data-testid={`${dataTestId}-voided`}
            />
          }
        />
      )}
    </>
  );
};

interface WCClaimPaymentsTableProps {
  paymentDetails: PaymentDetails[];
}

export const WCClaimPaymentsTable = ({
  paymentDetails,
}: WCClaimPaymentsTableProps) => {
  const [sortedPayments, setSortedPayments] = useState<PaymentDetails[]>([]);
  const [displayedPayments, setDisplayedPayments] = useState<PaymentDetails[]>(
    []
  );
  const [sort, setSort] = useState<PaymentsTableSort>({
    field: PaymentFields.CheckDate,
    isAscending: false,
  });
  const hiddenPaymentsLength = paymentDetails.length - displayedPayments.length;

  const sortPayments = (field: PaymentFields, defaultIsAscending?: boolean) => {
    const isAscending =
      defaultIsAscending === undefined
        ? sort.field === field
          ? !sort.isAscending
          : false
        : defaultIsAscending;

    setSort({ field, isAscending });
    setSortedPayments(
      [...paymentDetails].sort(getPaymentsSortFunction(field, isAscending))
    );
  };

  const tableData = displayedPayments.map(
    (payment: PaymentDetails, i: number) => [
      { status: payment.paymentstatus, id: payment.invoice_trid, index: i },
      payment.service_fromdate,
      payment.service_thrudate,
      payment.checknumber,
      payment.checkdate,
      formatMoney(payment.chargedamount),
      formatMoney(payment.paidamount),
    ]
  );

  const columns = [
    {
      name: (
        <ColumnLabel
          text={PaymentFields.Status}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.Status)}
        />
      ),
      process: processColumn1,
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.FromDate}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.FromDate)}
        />
      ),
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.ThruDate}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.ThruDate)}
        />
      ),
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.CheckNumber}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.CheckNumber)}
        />
      ),
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.CheckDate}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.CheckDate)}
        />
      ),
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.Charged}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.Charged)}
        />
      ),
    },
    {
      name: (
        <ColumnLabel
          text={PaymentFields.Paid}
          sort={sort}
          onClick={() => sortPayments(PaymentFields.Paid)}
        />
      ),
    },
  ];

  const onLoadMoreClick = () => {
    setDisplayedPayments((currentDP: PaymentDetails[]) => [
      ...currentDP,
      ...sortedPayments.slice(currentDP.length, currentDP.length + 5),
    ]);
  };

  useEffect(() => {
    // reset displayed list if payment list for this payee changes
    sortPayments(PaymentFields.CheckDate, false);
    setDisplayedPayments([]);
  }, [paymentDetails]);

  useEffect(() => {
    setDisplayedPayments((currDisplayed: PaymentDetails[]) => {
      return sortedPayments.slice(0, currDisplayed.length || 5);
    });
  }, [sortedPayments]);

  return (
    <SABox pt="medium">
      <SABox width="100%">
        <SATable
          columns={columns}
          data={tableData}
          variant="table-to-listview"
          disableRowAccent
          data-testid="paymentsTable"
        />
      </SABox>
      {hiddenPaymentsLength > 0 && (
        <LoadMoreContainer>
          <SAButton
            label="LOAD MORE"
            variant="link-small"
            onClick={onLoadMoreClick}
          />
        </LoadMoreContainer>
      )}
    </SABox>
  );
};
