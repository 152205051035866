import { SAButton, SAText } from "@saux/design-system-react";
import React, { useEffect, useState } from "react";
import Joyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import useFeatureFlag from "../../hooks/useFeatureFlag/useFeatureFlag";
import { simpleGTMDataLayer } from "../../util/GTMHelpers";
import ToolTipWrapper, {
  ToolTipContent,
  ToolTipSkipNext,
  ToolTipTitle,
} from "./ProductTour.styles";

export interface ProductTourState {
  stepIndex: number;
  run: boolean;
}

interface StepContent {
  title: string;
  body: string;
}

interface ProductTourProps {
  useNavigation: Function;
  setProductTourCompleted: Function;
}

interface ProductTourSession {
  currentStep: number;
  totalSteps: number;
  displayTour: boolean;
  currentPage: string;
}

const autoScrollNavBar = (navButtonName: string) => {
  // reset the navbar
  const buttons = document.getElementsByTagName("button");
  const tab = Array.from(buttons).find(
    (button: HTMLButtonElement) => button.textContent === navButtonName
  ) as any;

  tab.scrollIntoView(false, { behavior: "smooth" });
};

export const updateProductTourSession = (updateData: any) =>
  sessionStorage.setItem("productTourSession", JSON.stringify(updateData));

export const Tooltip = (
  tour: ProductTourState,
  productTourSession: ProductTourSession
) => {
  const stepText: { [key: string]: StepContent } = {
    ClaimsTab: {
      title: "Need to check or manage your claims?",
      body: "Find more information about your cases on the Claims page.",
    },
  };

  return ({ step, closeProps, tooltipProps }: TooltipRenderProps) => {
    const { title } = stepText[step.content as string];
    const { body } = stepText[step.content as string];

    const currentStep: string = (
      productTourSession.currentStep +
      tour.stepIndex +
      1
    ).toString();
    const totalSteps: string = productTourSession.totalSteps.toString();

    return (
      <ToolTipWrapper {...tooltipProps}>
        <ToolTipContent>
          <SAText
            text={`${currentStep}/${totalSteps}`}
            type="standard"
            weight="bold"
          />
          <ToolTipTitle>
            <SAText text={title} type="heading-3" weight="bold" />
          </ToolTipTitle>
          <SAText text={body} type="paragraph" />
        </ToolTipContent>
        <ToolTipSkipNext test-attr="tooltip-next-button">
          <SAButton variant="medium" label="DONE" {...closeProps} />
        </ToolTipSkipNext>
      </ToolTipWrapper>
    );
  };
};

export const handleJoyrideCallback = (
  productTourSession: ProductTourSession,
  tour: ProductTourState,
  setTour: Function,
  useNavigation: Function,
  setProductTourCompleted: Function
) => {
  return (data: CallBackProps) => {
    const { action, index, type } = data;
    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      document.body.style.overflow = "auto";

      // Update state to advance the tour
      if (action !== ACTIONS.CLOSE) {
        updateProductTourSession({
          ...productTourSession,
          currentStep: productTourSession.currentStep + 1,
        });
        setTour({
          ...tour,
          stepIndex: index + 1,
        });
      } else {
        setProductTourCompleted(true);
        updateProductTourSession({
          ...productTourSession,
          displayTour: false,
        });
        setTour({
          ...tour,
          run: false,
        });
        simpleGTMDataLayer({
          event: `DoneButton-click`,
          event_action: "Done Button Click",
          event_category: "Done",
          event_label: "Done Button Click",
          event_category_snowplow: "Snowplow Done",
        });

        autoScrollNavBar("Overview");
      }
    }
  };
};

const ProductTour = ({
  useNavigation,
  setProductTourCompleted,
}: ProductTourProps) => {
  const productTourSession: ProductTourSession | string = JSON.parse(
    sessionStorage.getItem("productTourSession") ?? '""'
  );
  const { flagDetails } = useFeatureFlag("ProductTour");
  const [tour, setTour] = useState<ProductTourState>({
    stepIndex: 0,
    run:
      typeof productTourSession !== "string"
        ? productTourSession.displayTour
        : false,
  });

  useEffect(() => {
    autoScrollNavBar("Claims");
  }, []);

  if (
    productTourSession &&
    typeof productTourSession !== "string" &&
    flagDetails?.enabled
  ) {
    const steps: Step[] = [
      {
        target: ".navBar",
        content: "ClaimsTab",
        disableBeacon: true,
      },
    ];

    if (tour.run) {
      document.body.style.overflow = "hidden";
    }

    return (
      <Joyride
        test-attr="product-tour-joyride"
        steps={steps}
        tooltipComponent={Tooltip(tour, productTourSession)}
        run={tour.run}
        continuous
        stepIndex={tour.stepIndex}
        disableOverlayClose
        callback={handleJoyrideCallback(
          productTourSession,
          tour,
          setTour,
          useNavigation,
          setProductTourCompleted
        )}
        disableScrolling
        styles={{
          options: {
            zIndex: 10000,
            overlayColor: "rgba(4, 30, 65, 0.6)",
            arrowColor: "rgb(255, 246, 233)",
          },
        }}
      />
    );
  }

  return <></>;
};

export default ProductTour;
