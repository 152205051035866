import { SAAccordion } from "@saux/design-system-react";
import React from "react";
import styled from "styled-components";
import { useClaimsApplicationState } from "../../providers";
import { Snowplow } from "../../util/snowplow/snowplow";

const StyledAccordion = styled(SAAccordion)`
  .accordion-summary {
    background: #ffffff;
  }
  .accordion-title {
    font-size: 18px;
  }
  .accordion-body {
    margin-top: -10px;
  }
  background: #ffffff;
`;

export const WcFAQContainer = (props: {
  title: string;
  children: React.ReactElement;
}) => {
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();

  const handleChange = (question: string) => (
    _e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      Snowplow.track.wcFaqExpanded({
        faq: question,
        accountNumber: accountNumber || "",
        claimNumber: selected || "",
        policyNumber: selectedPolicyNumber || "",
      });
    }
  };

  return (
    <StyledAccordion title={props.title} onChange={handleChange(props.title)}>
      {props.children}
    </StyledAccordion>
  );
};
