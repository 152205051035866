import React from "react";
import { SABox, SAIcon, SAIcons, SAText } from "@saux/design-system-react";
import styled from "styled-components";

export const CenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SAWhiteBox = styled(SABox)`
  background-color: #ffffff;
`;

const CenterText = styled(SAText)`
  text-align: center;
`;

interface ErrorContainerProps {
  icon?: SAIcons | undefined;
  text: string;
  subText?: string;
  size?: string | undefined;
}

export const ErrorContainer = (props: ErrorContainerProps) => {
  return (
    <SAWhiteBox pt="large" pb="large">
      <CenteredBox pt="large">
        <SAIcon
          icon={props.icon}
          size={props.size}
          colorVariant="#B00020"
          data-testid="error-container-icon"
        />
      </CenteredBox>
      <CenteredBox pb="medium">
        <SAText
          weight="bold"
          text={props.text}
          size={props.subText ? "500" : "300"}
        />
      </CenteredBox>

      {props.subText && (
        <CenteredBox padding="medium">
          <CenterText type="standard" text={props.subText} size="300" />
        </CenteredBox>
      )}
    </SAWhiteBox>
  );
};
