import React, { useEffect, useState } from "react";
import {
  SABox,
  SAButton,
  SACard,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAPagination,
  SAPopover,
  SASearch,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { WcClaimsList } from "../wcClaimsList/WcClaimsList";
import { useClaimsApplicationState } from "../../providers";
import { WcAdvancedFilterModal } from "../wcAdvancedFilterModal/WcAdvancedFilterModal";
import { CenteredBox } from "../wcClaimSearch/WcClaimSearch";
import { ClaimData } from "../recentClaimsContainer/RecentClaimsContainer";
import { ErrorContainer } from "../common/ErrorContainer";
import { Snowplow } from "../../util/snowplow/snowplow";

const SAContainerCard = styled(SACard)`
  background-color: #0173c6;
`;

const SASortBox = styled(SABox)`
  background-color: #0855a4;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SAFilterBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 77%;
`;

const SATrashIcon = styled(SAIcon)`
  width: 23px;
  height: 25px;
`;

const SAPaginationBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(112deg)
    brightness(104%) contrast(101%);
`;

const SAPopoverBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const NoPaddingBottomBox = styled(SABox)`
  padding-bottom: 0px;
`;

const SAButtonSmall = styled(SAButton)`
  width: 124px;
  height: 30px;
`;

export enum SortType {
  MostRecent = "Claim Number",
  ClaimStatus = "Claim Status",
  LossLocation = "Loss Location",
  DateOfLoss = "Date of Injury",
  InjuredName = "Injured Name",
}

export interface WcClaimsTabProps {
  searchString: string;
  setSearchString: (search: string) => void;
  openAdvancedFilterModal: boolean;
  setOpenAdvancedFilterModal: (state: boolean) => void;
}

export const WcClaimsTab = ({
  searchString,
  setSearchString,
  openAdvancedFilterModal,
  setOpenAdvancedFilterModal,
}: WcClaimsTabProps) => {
  const { wcClaimsList, accountNumber } = useClaimsApplicationState();

  // states used for advanced filter
  const [filteredClaimList, setFilteredClaimList] = useState<ClaimData[]>([]);
  const [activeFilterCount, setActiveFilterCount] = useState<Number>(0);
  const [clearFilterClicked, setClearFilterClicked] = useState<boolean>(false);

  // state used for search
  const [searchedClaimsList, setSearchedClaimsList] = useState<ClaimData[]>([]);

  // states used for sorting
  // States used for sort order
  const [isAscendingOrder, setIsAscendingOrder] = useState<boolean>(false);

  // states used for popover
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [currentSortType, setCurrentSortType] = useState<SortType>(
    SortType.MostRecent
  );
  const [popoverData, setPopoverData] = useState<SortType[]>([
    SortType.ClaimStatus,
    SortType.LossLocation,
    SortType.DateOfLoss,
    SortType.InjuredName,
  ]);

  const [wcSortedClaimsList, setWcSortedClaimsList] = useState<ClaimData[]>([]);

  // state used for pagination
  const [wcDisplayedClaimsList, setWcDisplayedClaimsList] = useState<
    ClaimData[]
  >([]);

  // data used for pagination
  const totalItems = wcSortedClaimsList.length;
  const itemsPerPage = 20;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  // Data used for popover
  const referenceElement = document.getElementById("togglePopover");
  const popoverBaseData: SortType[] = [
    SortType.MostRecent,
    SortType.ClaimStatus,
    SortType.LossLocation,
    SortType.DateOfLoss,
    SortType.InjuredName,
  ];

  // Function for advanced Filter
  useEffect(() => {
    if (wcClaimsList) {
      setFilteredClaimList(wcClaimsList);
    }
  }, [wcClaimsList]);

  // Functions for search
  useEffect(() => {
    if (searchString && filteredClaimList) {
      const reqData = filteredClaimList.filter(
        (claimItem) =>
          claimItem.claimNumber &&
          claimItem.claimNumber
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) >= 0
      );
      setSearchedClaimsList(reqData);
      Snowplow.track.wcSearchWithClaimNumber({
        accountNumber: accountNumber || "",
        claimNumber: searchString,
      });
    } else if (filteredClaimList) {
      setSearchedClaimsList(filteredClaimList);
    }
  }, [searchString, filteredClaimList]);

  // Functions for Popover
  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen);

  const handlePopoverClose = (index: number | null) => {
    if (index !== null) {
      setCurrentSortType(popoverData[index]);
      setPopoverData(
        popoverBaseData.filter((entry) => entry !== popoverData[index])
      );

      if (
        popoverData[index] === SortType.MostRecent ||
        popoverData[index] === SortType.DateOfLoss ||
        popoverData[index] === SortType.ClaimStatus
      ) {
        setIsAscendingOrder(false);
      } else {
        setIsAscendingOrder(true);
      }

      Snowplow.track.wcSortClaimsList({
        accountNumber: accountNumber || "",
        sortType: popoverData[index],
      });
    }
    setIsPopoverOpen(false);
  };

  // functions for sort
  const toggleSortOrder = () => setIsAscendingOrder(!isAscendingOrder);

  const getSortfunction = (sortType: string, isAscending: boolean) => {
    // reverses the sort function when descending sort order
    let greaterThan = 1;
    let lessThan = -1;
    if (!isAscending) {
      greaterThan = -1;
      lessThan = 1;
    }

    let sortFunction = (a: ClaimData, b: ClaimData) =>
      new Date(a.dateOfLoss) > new Date(b.dateOfLoss) ? greaterThan : lessThan;

    switch (sortType) {
      case SortType.MostRecent:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          a.claimNumber && b.claimNumber && a?.claimNumber > b.claimNumber
            ? greaterThan
            : lessThan;
        break;
      case SortType.ClaimStatus:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          a.status > b.status ? greaterThan : lessThan;
        break;
      case SortType.DateOfLoss:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          new Date(a.dateOfLoss) > new Date(b.dateOfLoss)
            ? greaterThan
            : lessThan;
        break;
      case SortType.InjuredName:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          a.injuredName && b.injuredName && a.injuredName > b.injuredName
            ? greaterThan
            : lessThan;
        break;
      case SortType.LossLocation:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          a.lossLocation && b.lossLocation && a.lossLocation > b.lossLocation
            ? greaterThan
            : lessThan;
        break;
      default:
        sortFunction = (a: ClaimData, b: ClaimData) =>
          new Date(a.dateOfLoss) > new Date(b.dateOfLoss)
            ? greaterThan
            : lessThan;
    }

    return sortFunction;
  };

  useEffect(() => {
    if (searchedClaimsList) {
      const sortedList = [...searchedClaimsList].sort(
        getSortfunction(currentSortType, isAscendingOrder)
      );

      setWcSortedClaimsList(sortedList);
    }
  }, [currentSortType, isAscendingOrder, searchedClaimsList]);

  // function for pagination
  const paginationOnChange = (newPage: number) => {
    setCurrentPageIndex(newPage);
  };

  useEffect(() => {
    if (wcSortedClaimsList) {
      setWcDisplayedClaimsList(
        wcSortedClaimsList.slice(
          currentPageIndex * itemsPerPage,
          (currentPageIndex + 1) * itemsPerPage
        )
      );
    }
  }, [wcSortedClaimsList, currentPageIndex]);

  useEffect(() => {
    setCurrentPageIndex(0);
  }, [wcSortedClaimsList]);

  useEffect(() => {
    // workaround to set SA Search bg color to white
    const searchBar = document.getElementById("wc-search");
    if (searchBar) searchBar.style.background = "white";
  }, []);

  // function for advanced filter modal
  const closeAdvancedFilterModal = () => setOpenAdvancedFilterModal(false);

  // function to clear search
  const clearSearchString = () => {
    setSearchString("");
  };

  return (
    <SAContainerCard variant="minimal" removePadding>
      <WcAdvancedFilterModal
        closeModal={closeAdvancedFilterModal}
        setActiveFilterCount={setActiveFilterCount}
        setFilteredClaimList={setFilteredClaimList}
        openAdvancedFilterModal={openAdvancedFilterModal}
        wcClaimsList={wcClaimsList}
        clearFilterClicked={clearFilterClicked}
        setClearFilterClicked={setClearFilterClicked}
      />
      <NoPaddingBottomBox p="medium" pb="small" data-testid="wc-search-box">
        <SASearch
          id="wc-search"
          onSearch={(search) => setSearchString(search.toString())}
          fullWidth
          placeholder={searchString || "Search Claim Number"}
        />
      </NoPaddingBottomBox>
      <CenteredBox pb="small" pt="medium">
        <SAButtonSmall
          fullWidth={false}
          label="RESET SEARCH"
          onClick={clearSearchString}
          textTransform="uppercase"
          variant="small-secondary-lightweight"
        />
      </CenteredBox>
      <CenteredBox pb="large">
        {activeFilterCount ? (
          <SAFilterBox>
            <SAButton
              fullWidth={false}
              label={`VIEW/EDIT FILTERS (${activeFilterCount})`}
              onClick={() => setOpenAdvancedFilterModal(true)}
              textTransform="uppercase"
              variant="secondary-medium"
            />
            <SATrashIcon
              icon={SAIcons.trash}
              size={SAIconSize.medium}
              data-testid="trash-icon-wc-tab"
              colorVariant="white"
              onClick={() => setClearFilterClicked(true)}
            />
          </SAFilterBox>
        ) : (
          <SAButton
            fullWidth={false}
            label="ADVANCED FILTER"
            onClick={() => setOpenAdvancedFilterModal(true)}
            textTransform="uppercase"
            variant="secondary-medium"
          />
        )}
      </CenteredBox>
      <SASortBox pt="small" pb="small">
        <SAButton
          fullWidth={false}
          label="sortDirection"
          onClick={toggleSortOrder}
          variant="link-small"
        >
          {isAscendingOrder ? (
            <ArrowDownwardIcon
              sx={{ color: "white" }}
              data-testid="down-arrow"
            />
          ) : (
            <ArrowUpwardIcon sx={{ color: "white" }} data-testid="up-arrow" />
          )}
        </SAButton>
        <SAPopoverBox>
          <SAText
            text={`Sort: ${currentSortType}`}
            colorVariant="active-foreground"
            weight="bold"
          />
          <SAButton
            label="togglePopover"
            onClick={togglePopover}
            id="togglePopover"
            variant="link-small"
          >
            <SAIcon
              colorVariant="white"
              icon={isPopoverOpen ? SAIcons.chevronUp : SAIcons.chevronDown}
              size="20px"
              data-testid="toggle-popover"
            />
          </SAButton>
          {isPopoverOpen && (
            <SAPopover
              background="rgba(0, 0, 0, 0.1)"
              data={popoverData}
              offset={[0, 10]}
              onClose={(e: MouseEvent, index: number | null) =>
                handlePopoverClose(index)
              }
              placement="bottom-start"
              referenceElement={referenceElement}
              variant="popover-menu"
            />
          )}
        </SAPopoverBox>
      </SASortBox>
      {!wcDisplayedClaimsList?.length ? (
        <ErrorContainer
          icon={SAIcons.error}
          text="No Results Found"
          size={SAIconSize.large}
        />
      ) : (
        <WcClaimsList
          wcDisplayedClaimsList={wcDisplayedClaimsList}
          sortType={currentSortType}
        />
      )}
      <SAPaginationBox p="small" data-testid="wc-pagination-box">
        <SAPagination
          currentPageIndex={currentPageIndex}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onChange={paginationOnChange}
          variant="simple"
          id="wc-pagination"
          className="wc-pagination"
        />
      </SAPaginationBox>
    </SAContainerCard>
  );
};
