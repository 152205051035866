import { createTheme } from "@material-ui/core";

// TODO: Table this for a more straight forward theme override
export const altBreakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 750,
      lg: 1024,
      xl: 1280,
    },
  },
});
