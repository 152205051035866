import styled from "styled-components";
import { SABox } from "@saux/design-system-react";
import React, { ReactElement } from "react";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";

const IconAndHeaderBox = styled(SABox)`
  display: flex;
  justifycontent: start;
  align-items: start;
  width: 100%;
`;

const ContentBox = styled(SABox)`
  ${(props) => (props.fullWidth ? "width: 100%;" : "")};
`;

interface IncidentDetailIconHeaderProps {
  headerText: string;
  icon: ReactElement;
  children: ReactElement;
  fullWidth?: boolean;
}

export const IncidentDetailIconHeader = (
  props: IncidentDetailIconHeaderProps
) => {
  return (
    <IconAndHeaderBox>
      <SABox pr="small">{props.icon}</SABox>
      <ContentBox fullWidth={props?.fullWidth}>
        <SABox pb="small">
          <AltColorText type="heading-3" text={props.headerText} />
        </SABox>
        {props.children}
      </ContentBox>
    </IconAndHeaderBox>
  );
};
