import React from "react";

export default () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3331 6.05426C14.2649 6.05426 11.7776 8.54157 11.7776 11.6098V56.0543C11.7776 59.1225 14.2649 61.6098 17.3331 61.6098H33.9998C35.5339 61.6098 36.7776 60.3662 36.7776 58.832C36.7776 57.2979 35.5339 56.0543 33.9998 56.0543H17.3331V11.6098H50.6665V28.2765C50.6665 29.8106 51.9101 31.0543 53.4443 31.0543C54.9784 31.0543 56.222 29.8106 56.222 28.2765V11.6098C56.222 8.54157 53.7347 6.05426 50.6665 6.05426H17.3331ZM25.6665 17.1654C24.1324 17.1654 22.8887 18.409 22.8887 19.9431C22.8887 21.4773 24.1324 22.7209 25.6665 22.7209H42.3331C43.8673 22.7209 45.1109 21.4773 45.1109 19.9431C45.1109 18.409 43.8673 17.1654 42.3331 17.1654H25.6665ZM25.6665 28.2765C24.1324 28.2765 22.8887 29.5201 22.8887 31.0543C22.8887 32.5884 24.1324 33.832 25.6665 33.832H36.7776C38.3117 33.832 39.5554 32.5884 39.5554 31.0543C39.5554 29.5201 38.3117 28.2765 36.7776 28.2765H25.6665ZM45.0321 39.3876C44.059 41.8469 42.125 43.8064 39.6832 44.8127C40.3316 49.6 43.4035 53.778 47.8887 55.7921C52.3739 53.778 55.4458 49.6 56.0942 44.8127C53.6524 43.8064 51.7184 41.8469 50.7453 39.3876H45.0321ZM40.0355 36.8162C40.474 35.0624 42.0498 33.832 43.8577 33.832H51.9197C53.7276 33.832 55.3034 35.0624 55.7418 36.8162C56.1175 38.3189 57.2908 39.4921 58.7934 39.8678L59.5455 40.0558C60.8573 40.3838 61.7776 41.5624 61.7776 42.9146C61.7776 50.6888 57.1992 57.7338 50.0951 60.8912L49.3127 61.2389C48.4061 61.6418 47.3713 61.6418 46.4647 61.2389L45.6823 60.8912C38.5782 57.7338 33.9998 50.6888 33.9998 42.9146C33.9998 41.5624 34.9201 40.3838 36.2318 40.0558L36.984 39.8678C38.4866 39.4921 39.6599 38.3189 40.0355 36.8162ZM53.1973 47.5358C54.1178 46.3085 53.8691 44.5673 52.6418 43.6469C51.4145 42.7264 49.6734 42.9751 48.7529 44.2024L46.7158 46.9185L46.4689 46.7333C45.2416 45.8128 43.5005 46.0615 42.58 47.2889C41.6596 48.5161 41.9083 50.2573 43.1356 51.1777L45.6047 53.0296C46.832 53.9501 48.5731 53.7013 49.4936 52.474L53.1973 47.5358Z"
        fill="#005C86"
      />
    </svg>
  );
};
