import React from "react";
import { SABox } from "@saux/design-system-react/box";
import VehicleClaimType from "./ClaimType/VehicleClaimType";
import PropertyClaimType from "./ClaimType/PropertyClaimType";
import InjuryClaimType from "./ClaimType/InjuryClaimType";
import MedicalClaimType from "./ClaimType/MedicalClaimType";
import { useSelectedIncidentState } from "../../providers";
import WorkerCompType from "./ClaimType/WorkerCompType";

export default () => {
  const {
    insuredAsset,
    vehicle,
    property,
    injury,
    medical,
    workerComp,
  } = useSelectedIncidentState();

  let claimTypeSection = <SABox />;

  if (vehicle) {
    claimTypeSection = (
      <VehicleClaimType insuredAsset={insuredAsset} vehicle={vehicle} />
    );
  } else if (property) {
    claimTypeSection = <PropertyClaimType property={property} />;
  } else if (injury) {
    claimTypeSection = <InjuryClaimType injury={injury} />;
  } else if (medical) {
    claimTypeSection = <MedicalClaimType medical={medical} />;
  } else if (workerComp) {
    claimTypeSection = <WorkerCompType worker={workerComp} />;
  }

  return claimTypeSection;
};
