import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { UploadCloudIcon } from "../../assets/Icons";

const DropBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
`;
const CloudBackground = styled.div`
  background-color: #f4f6fa;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  width: 300px;
  margin: auto;
`;
const DropText = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 15px;
`;
export const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  background-color: #0173c6;
  color: #ffffff;
  border-radius: 3px;
  border: 1px solid #0173c6;
  padding-top: 7px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 150px;
`;
export const SelectFiles = styled.input.attrs((props) => ({
  accept:
    ".avi, .bmp, .csv, .doc, .docx, .gif, .htm, .html, .jpeg, .jpg, .mdi, .mov, .mp3, .mpg, .pdf, .png, .ppt, .pptx, .rtf, .tif, .tiff, .txt, .wav, .wma, .xls, .xlsx",
}))`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

interface dropProps {
  data: any;
  dispatch: any;
  stagingHandler: Function;
}

export default ({ data, dispatch, stagingHandler }: dropProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
  };
  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: true });
  };
  const drop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    let files: any[] = [];
    let i = 0;
    for (i = 0; i < e.dataTransfer.files.length; i += 1) {
      files.push(e.dataTransfer.files[i]);
    }
    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      stagingHandler(true);
    }
  };
  const chooseFiles = (e: any) => {
    let files: any[] = [];
    let i = 0;
    for (i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }
    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      dispatch({ type: "ADD_FILE_TO_LIST", files });
      dispatch({ type: "SET_IN_DROP_ZONE", inDropZone: false });
      stagingHandler(true);
    }
  };
  return (
    <DropBox
      onDrop={(e) => drop(e)}
      onDragOver={(e) => dragOver(e)}
      onDragLeave={(e) => dragLeave(e)}
      data-testid="dropArea"
    >
      {isMobile ? (
        <></>
      ) : (
        <>
          <CloudBackground>
            <UploadCloudIcon />
            <DropText>Drag and Drop Files here</DropText>
          </CloudBackground>
          <DropText>or</DropText>
        </>
      )}

      <SelectButton htmlFor="file">
        SELECT FILES
        <SelectFiles
          type="file"
          name="file"
          id="file"
          multiple
          onChange={(e) => {
            chooseFiles(e.target.files);
          }}
        />
      </SelectButton>
    </DropBox>
  );
};
