import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="docIconTest"
  >
    <g id="Icon/Note">
      <circle id="Oval" cx="10" cy="10" r="10" fill="#7C899B" />
      <path
        id="Rectangle"
        d="M13.8819 4.34085L13.8829 4.34185L15.75 6.18938V16.19C15.75 16.4993 15.4993 16.75 15.19 16.75H5.81C5.50072 16.75 5.25 16.4993 5.25 16.19V2.81C5.25 2.50072 5.50072 2.25 5.81 2.25H11.7928L13.8819 4.34085Z"
        fill="white"
        stroke="#374C67"
        strokeWidth="0.5"
      />
      <rect id="Rectangle_2" x="7" y="7" width="5" height="1" fill="#7C899B" />
      <rect id="Rectangle_3" x="7" y="9" width="7" height="1" fill="#7C899B" />
      <rect id="Rectangle_4" x="7" y="11" width="7" height="1" fill="#7C899B" />
      <rect id="Rectangle_5" x="7" y="13" width="7" height="1" fill="#7C899B" />
      <path
        id="Path 4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2V5.32901C12 5.32901 12.0398 6 12.7115 6C13.3833 6 16 6 16 6"
        fill="#374C67"
      />
    </g>
  </svg>
);
