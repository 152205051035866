import padStart from "lodash.padstart";
// TODO: Create separate formatDateTime formatter. DO NOT MIX.

const padZeros = (num: number | string) => padStart(num.toString(), 2, "0");

export default (date?: string | number | Date) => {
  const formattedDate = date instanceof Date ? date : new Date(date as string);
  if (formattedDate.toString() === "Invalid Date") {
    // We don't want to actually display "Invalid Date" in the UI.
    return date;
  }

  const d = formattedDate;
  return `${padZeros(String(d.getMonth() + 1))}/${padZeros(
    d.getDate()
  )}/${d.getFullYear()}`;
};
