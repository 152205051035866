import React from "react";
import styled from "styled-components";
import { SAIcon, SAIcons, SAIconSize } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { SAStack } from "@saux/design-system-react/stack";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";

export interface VehicleProps {
  ymm?: string;
  vin?: string;
}

export interface VehicleClaimTypeProps {
  insuredAsset?: string;
  vehicle?: VehicleProps;
}

const StyledAutoIcon = styled(SAIcon)`
  min-heigh: 30px;
  min-width: 30px;
`;

export default ({ insuredAsset, vehicle }: VehicleClaimTypeProps) => {
  return (
    <SABox mr="small">
      <SABox display="flex">
        <StyledAutoIcon icon={SAIcons.auto} size={SAIconSize.medium} />
        <SABox ml="small">
          <SAStack spacing="small">
            <SABox>
              <AltColorText type="heading-3" text={insuredAsset} />
            </SABox>
            <SABox display="flex">
              <SABox display="flex" flex={1} mr="xxs" ml="xs">
                <SAStack spacing="xs">
                  <SABox display="flex">
                    <AltColorText type="small" text="VIN: " weight="bold" />
                  </SABox>
                </SAStack>
              </SABox>
              <SABox display="flex" flex={1}>
                <SAStack spacing="xs">
                  <SABox display="flex">
                    <AltColorText type="small" text={vehicle?.vin || ""} />
                  </SABox>
                </SAStack>
              </SABox>
            </SABox>
          </SAStack>
        </SABox>
      </SABox>
    </SABox>
  );
};
