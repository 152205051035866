import { useState, useEffect } from "react";

import getConfig, { Env } from "../../config";
import callApiWithAuth from "../callApiWithAuth/callApiWithAuth";

const config = getConfig(process.env.REACT_APP_ENV as Env);

const applicationName = "Customer360";

const useFeatureFlag = (flagName: string) => {
  const [flagDetails, setFlagDetails] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    callApiWithAuth({
      method: "POST",
      url: config.feature_flag_api,
      data: {
        application_name: applicationName,
        flag_name: flagName,
      },
    })
      .then((res) => {
        if (res.data?.Items && res.data.Items[0]) {
          setFlagDetails(res.data.Items[0]);
        } else {
          const noFlagError = new Error(
            `Retrieved no feature flags named "${flagName}" for app "${applicationName}"`
          );
          setError(noFlagError);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, []);

  return {
    flagDetails,
    error,
  };
};

export default useFeatureFlag;
