/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  SABox,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
  SASelect,
} from "@saux/design-system-react";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useClaimsApplicationState } from "../../providers";
import { uploadToS3 } from "../../services";
import { TrashIcon } from "../../assets/Icons";
import { Snowplow } from "../../util/snowplow/snowplow";
import { UploadStatus } from "../uploadModal/UploadModal";

const FileContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;

  border: 1px solid #d9dfeb;
  border-top: none;
  &:first-child {
    border-top: 1px solid #d9dfeb;
  }
`;
export const DocumentSelector = styled(SASelect)`
  select {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #d9dfeb;
    border-radius: 3px;
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
  }
  label {
    border-radius: 3px;
    left: -3px !important;
  }
  option:first-child {
    display: none;
  }
`;
const InformationAndDropdownSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 540px) {
    flex-direction: column;
    gap: 0px;
  }
  @media (max-width: 400px) {
    width: auto;
  }
`;
const ImageAndHeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  @media (max-width: 400px) {
    max-width: 180;
  }
`;
const ImageSection = styled.div`
  align-self: center;
  margin-left: 5px;
`;
const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    max-width: 210px;
  }
  @media (max-width: 700px) {
    max-width: 100px;
  }
`;
export const DropDownContainerSection = styled.div`
  align-self: flex-end;
  padding-bottom: 5px;

  @media (max-width: 540px) {
    align-self: flex-start;
    select {
      width: 220px;
    }
  }
`;
export const DropDown = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  gap: 10px;
  width: 150px;
`;
export const Options = styled.div`
  box-sizing: border-box;
  z-index: 10;
  word-wrap: break-word;
  font-size: 12px;
  position: absolute;
`;
export const Content = styled.div`
  width: 170px;
  padding: 10px;
  word-wrap: break-word;
  z-index: 100;
  background-color: #ffffff;
`;
const MobileFileText = styled(SABox)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  @media (max-width: 540px) {
    padding-top: 0px;
  }
`;

const ErrorText = styled(SAText)`
  color: #e02020;
`;
export const StatusText = styled.div`
  font-weight: 700;
  font-size: 14px;
`;
const FileTextContainer = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  min-width: 0;
`;
export const ButtonContainer = styled.div`
  line-height: 0;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
`;
const DeleteButton = styled(ButtonContainer)`
  align-self: flex-end;
  margin: auto 10px;
`;

export interface uploadProps {
  dispatch: any;
  name: any;
  loading?: boolean;
  error?: Error;
  uploadState: number;
  uploadSuccess: React.Dispatch<React.SetStateAction<UploadStatus[]>>;
  setUpload: Function;
  uploadDocEDS: Function;
  edsResponse: any;
  edsLoad?: boolean;
  getSignedUrl: Function;
  signedUrlData: any;
  file: File;
  index: number;
  documentType: string;
  setDocumentType: Function;
  documentTypes: any[];
  removeFile: (file: any, index: number) => void;
}

export default ({
  name,
  loading,
  error,
  uploadDocEDS,
  edsResponse,
  uploadState,
  uploadSuccess,
  setUpload,
  file,
  index,
  getSignedUrl,
  signedUrlData,
  documentType,
  setDocumentType,
  documentTypes,
  removeFile,
  edsLoad,
}: uploadProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [safeToUpload, setSafeToUpload] = useState(false);
  const [edsUploadError, setEdsUploadError] = useState(false);
  const [s3UploadError, setS3UploadError] = useState(false);
  const [documentError, setDocumentError] = useState(false);
  const [fileSizeError, setfileSizeError] = useState(false);
  const [signedUrl, setSignedUrl] = useState("");
  const [signedUrlSuccess, setSignedUrlSuccess] = useState(false);
  const [readyForS3Upload, setReadyForS3Upload] = useState(false);
  const [s3UploadSuccess, setS3UploadSuccess] = useState(false);
  const [readyForEDSUpload, setReadyForEDSUpload] = useState(false);
  const [edsUploadSuccess, setEdsUploadSuccess] = useState(false);
  const maxFileSizeLimit = 314572800;

  let docData: any;
  const translateDocument = (f: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (err) => {
        reject(err);
        return null;
      };
    });

  const { selected } = useClaimsApplicationState();
  const uploadDocumentSnowplowEvent = (documents: any, uploadResponse: any) => {
    const uploadSucceeded =
      uploadResponse &&
      uploadResponse.uploadClaimDocument &&
      uploadResponse.uploadClaimDocument.responseMessage ===
        "Document uploaded successfully";

    Snowplow.track.uploadDocuments({
      claimNumber: selected || "",
      documents: {
        name: documents.name,
        size: documents.size,
        type: documents.type,
        lastModifiedDate: documents.lastModifiedDate,
      },
      uploadSucceeded,
    });
  };

  const setFileStatus = (status: UploadStatus) => {
    uploadSuccess((prevState: UploadStatus[]) => {
      const currentState = [...prevState];
      currentState[index] = status;
      return currentState;
    });
  };

  const validateAndGetSignedUrl = async () => {
    if (signedUrlData && !edsUploadError) {
      setSafeToUpload(false);
      setSignedUrlSuccess(true);
    }
    if (!signedUrlSuccess && safeToUpload) {
      docData = await translateDocument(file);
    }
    if (documentType === " ") {
      setDocumentError(true);
    }
    if (file.size > maxFileSizeLimit) {
      setFileStatus(UploadStatus.failed);
      setfileSizeError(true);
    }
    if (
      !signedUrlSuccess &&
      safeToUpload &&
      docData &&
      file.type &&
      !documentError &&
      !fileSizeError
    ) {
      try {
        await getSignedUrl();
      } catch (e) {
        setFileStatus(UploadStatus.failed);
        console.warn(e);
      }
    }
  };

  const validateAndUploadToS3 = async () => {
    if (readyForS3Upload && signedUrl) {
      try {
        await uploadToS3(signedUrl, file).then((response: any) => {
          if (response.status === 204) {
            setS3UploadSuccess(true);
            setReadyForEDSUpload(true);
          }
        });
      } catch (e) {
        setS3UploadError(true);
        setFileStatus(UploadStatus.failed);
        console.warn(e);
      }
    }
  };

  const uploadToEDS = async () => {
    try {
      const edsResponse = await uploadDocEDS();
      uploadDocumentSnowplowEvent(file, edsResponse.data || {});
      setFileStatus(UploadStatus.success);
    } catch (e) {
      console.warn(e);
      uploadDocumentSnowplowEvent(file, {});
      setFileStatus(UploadStatus.failed);
    }
  };

  useEffect(() => {
    if (signedUrlData && uploadState > 0) {
      setSignedUrl(signedUrlData.account.items[0].signedUrlClaimsDocument);
      setReadyForS3Upload(true);
    }
  }, [signedUrlData]);

  useEffect(() => {
    if (readyForS3Upload) {
      validateAndUploadToS3();
    }
  }, [readyForS3Upload]);

  useEffect(() => {
    if (readyForEDSUpload) {
      uploadToEDS();
    }
  }, [readyForEDSUpload]);

  useEffect(() => {
    if (
      edsResponse &&
      edsResponse.uploadClaimDocument.responseMessage ===
        "Document uploaded successfully"
    ) {
      setEdsUploadSuccess(true);
      setFileStatus(UploadStatus.success);
    } else if (
      (edsResponse &&
        edsResponse.uploadClaimDocument.responseMessage ===
          "Something went wrong in Claim Document Upload") ||
      (edsResponse && edsResponse.uploadClaimDocument.hasErrors === true)
    ) {
      setEdsUploadError(true);
      setSignedUrlSuccess(false);
      setReadyForS3Upload(false);
      setS3UploadSuccess(false);
      setReadyForEDSUpload(false);
      setSignedUrl("");
      setUpload(0);
    }
  }, [edsResponse]);

  useEffect(() => {
    if (uploadState && uploadState > 0) {
      setSafeToUpload(true);
      setEdsUploadError(false);
      validateAndGetSignedUrl();
    }
  }, [uploadState]);

  const documentTypeOnChange = (event: any) => {
    const docType: any = documentTypes.filter(
      (doc: any) => event.target.value === doc.value
    );
    setDocumentType(docType[0].value);
    setDocumentError(false);
  };

  return (
    <FileContent data-testid="fileContent">
      <InformationAndDropdownSection>
        <ImageAndHeaderSection>
          <ImageSection>
            <SAIcon
              icon={SAIcons.document}
              size={SAIconSize.medium}
              colorVariant="tertiary"
            />
          </ImageSection>

          <HeaderSection>
            <FileTextContainer>
              {(edsLoad || loading) && (
                <SAText type="small" weight="bold" text="Uploading..." />
              )}
              {edsUploadError && edsResponse && (
                <ErrorText
                  type="small"
                  weight="bold"
                  text="Error: Could Not Upload. Please Try Again."
                />
              )}
              {edsUploadSuccess &&
                s3UploadSuccess &&
                !error &&
                !s3UploadError &&
                !edsUploadError && (
                  <SAText type="small" weight="bold" text="Upload Complete" />
                )}
              {documentError && (
                <div>
                  <ErrorText
                    type="small"
                    weight="bold"
                    text="Error: Document Type Not Selected"
                  />
                </div>
              )}
              {fileSizeError && (
                <div>
                  <ErrorText
                    type="small"
                    weight="bold"
                    text="Error: File size too large (300MB max)"
                  />
                </div>
              )}
              {!edsResponse &&
                !edsLoad &&
                !loading &&
                !documentError &&
                !fileSizeError && (
                  <SAText type="small" text="Pending" weight="bold" />
                )}
              <>
                {isMobile ? (
                  <MobileFileText pt="xs">
                    <SAText type="small" text={name} data-testid="fileName" />
                  </MobileFileText>
                ) : (
                  <SABox pt="xs">
                    <SAText type="small" text={name} data-testid="fileName" />
                  </SABox>
                )}
              </>
            </FileTextContainer>
          </HeaderSection>
        </ImageAndHeaderSection>

        {!edsUploadSuccess && (
          <DropDownContainerSection>
            <DocumentSelector
              id="docTypeDropdown"
              data-testid="docTypeDropdown"
              onChange={(e: any) => documentTypeOnChange(e)}
              error={documentError}
              options={[
                { value: " ", label: "Select Document Type" },
                ...documentTypes,
              ]}
              defaultValue=" "
            />
          </DropDownContainerSection>
        )}
      </InformationAndDropdownSection>

      <DeleteButton
        data-testid="removeFile"
        onClick={() => removeFile(file, index)}
      >
        <TrashIcon
          variant={documentError || fileSizeError ? "alert" : "default"}
        />
      </DeleteButton>
    </FileContent>
  );
};
