import React from "react";
import styled from "styled-components";
import {
  SABox,
  SAButton,
  SACard,
  SAModal,
  SAStack,
  SAText,
} from "@saux/design-system-react";
import NoClaimsIcon from "./NoClaimsIcon";
import { Loading, errorMessage } from "../loading";
import NewClaimModal from "../NewClaimModal/NewClaimModal";
import { useFileNewClaim } from "../../hooks/claimsHook";

const CenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    margin: 0px 20px 0px 20px;
  }
`;

const CenterText = styled(SAText)`
  text-align: center;
`;

const IconContainer = styled(SABox)`
  width: 30%;
  height: auto;
  @media (max-width: 768px) {
    width: 70%;
  }
`;

export interface ClaimsPageEmptyStateProps {
  userDisplayName: string;
  account_number: string;
}

export default ({
  userDisplayName,
  account_number,
}: ClaimsPageEmptyStateProps) => {
  const {
    policyDetails,
    open,
    policyError,
    setPolicyError,
    claimLoader,
    handleClose,
    newClaimClick,
    getPolicyQuery,
  } = useFileNewClaim(account_number);

  return (
    <SAStack spacing="large">
      <SABox pt="xl">
        <CenteredBox>
          <IconContainer>
            <NoClaimsIcon />
          </IconContainer>
        </CenteredBox>
      </SABox>
      <CenteredBox>
        <CenterText
          type="heading-3"
          text={`No Claims Found for ${userDisplayName}`}
        />
      </CenteredBox>
      <CenteredBox>
        <CenterText type="standard" text="Need to file a new claim?" />
      </CenteredBox>
      <CenteredBox>
        <Loading loading={claimLoader}>
          <SAButton
            variant="primary"
            label="FILE A NEW CLAIM"
            onClick={getPolicyQuery}
            data-testid="FILE A NEW CLAIM"
          />
        </Loading>
        {policyError && (
          <SAModal
            onClickHandler={(event: React.MouseEvent) => {
              event.stopPropagation();
              setPolicyError(!policyError);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SACard variant="standard" title="Error" width={480} height={85}>
                <SAText text={errorMessage} />
              </SACard>
            </div>
          </SAModal>
        )}
        <NewClaimModal
          open={open}
          handleClose={handleClose}
          handleSubmit={newClaimClick}
          policy_details={policyDetails}
        />
      </CenteredBox>
    </SAStack>
  );
};
