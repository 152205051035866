import React from "react";
import styled from "styled-components";
import { SAIcon, SAIcons, SAIconSize } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { SAStack } from "@saux/design-system-react/stack";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";

export interface MedicalProps {
  name?: string;
  birthYear?: string;
  exposure?: string;
}

export interface MedicalClaimTypeProp {
  insuredAsset?: string;
  medical?: MedicalProps;
}

export const NoTextWrapBox = styled(SABox)`
  white-space: nowrap;
`;

const StyledMedicalIcon = styled(SAIcon)`
  min-heigh: 30px;
  min-width: 30px;
`;

export default ({ medical }: MedicalClaimTypeProp) => {
  return (
    <SABox mr="small">
      <SABox display="flex">
        <StyledMedicalIcon icon={SAIcons.injury} size={SAIconSize.medium} />
        <SABox pl="small">
          <SAStack spacing="small">
            <SABox>
              <AltColorText type="heading-3" text="Medical" />
            </SABox>
            <SABox display="flex">
              <SABox display="flex" flex={1}>
                <SAStack spacing="xs">
                  <NoTextWrapBox display="flex" mr="xs">
                    <AltColorText
                      type="small"
                      text="Injured Party: "
                      weight="bold"
                    />
                  </NoTextWrapBox>
                  <NoTextWrapBox display="flex">
                    <AltColorText
                      type="small"
                      text="Birth Year: "
                      weight="bold"
                    />
                  </NoTextWrapBox>
                  <NoTextWrapBox display="flex">
                    <AltColorText
                      type="small"
                      text="Exposure: "
                      weight="bold"
                    />
                  </NoTextWrapBox>
                </SAStack>
              </SABox>
              <SABox display="flex" flex={1}>
                <SAStack spacing="xs">
                  <NoTextWrapBox display="flex">
                    <AltColorText type="small" text={medical?.name || ""} />
                  </NoTextWrapBox>
                  <NoTextWrapBox display="flex">
                    <AltColorText
                      type="small"
                      text={medical?.birthYear || ""}
                    />
                  </NoTextWrapBox>
                  <NoTextWrapBox display="flex">
                    <AltColorText type="small" text={medical?.exposure || ""} />
                  </NoTextWrapBox>
                </SAStack>
              </SABox>
            </SABox>
          </SAStack>
        </SABox>
      </SABox>
    </SABox>
  );
};
