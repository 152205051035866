import React from "react";

export default () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07979 2.11508C7.45487 1.74001 7.96357 1.5293 8.49401 1.5293H15.8867C16.4171 1.5293 16.9258 1.74001 17.3009 2.11508L22.5283 7.34249C22.9034 7.71756 23.1141 8.22627 23.1141 8.7567V16.1494C23.1141 16.6798 22.9034 17.1885 22.5283 17.5636L17.3009 22.791C16.9258 23.1661 16.4171 23.3768 15.8867 23.3768H8.49401C7.96357 23.3768 7.45487 23.1661 7.07979 22.791L1.85239 17.5636C1.47732 17.1885 1.2666 16.6798 1.2666 16.1494L1.2666 8.7567C1.2666 8.22627 1.47732 7.71756 1.85239 7.34249L7.07979 2.11508ZM15.8867 3.5293H8.49401L3.2666 8.7567V16.1494L8.49401 21.3768H15.8867L21.1141 16.1494V8.7567L15.8867 3.5293Z"
        fill="#B00020"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1954 8.44807C16.5859 8.8386 16.5859 9.47176 16.1954 9.86229L9.59943 16.4582C9.2089 16.8488 8.57574 16.8488 8.18522 16.4582C7.79469 16.0677 7.79469 15.4346 8.18522 15.044L14.7812 8.44807C15.1717 8.05755 15.8049 8.05755 16.1954 8.44807Z"
        fill="#B00020"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1962 16.4581C15.8057 16.8486 15.1725 16.8486 14.782 16.4581L8.18604 9.86212C7.79552 9.4716 7.79552 8.83843 8.18604 8.44791C8.57657 8.05739 9.20973 8.05739 9.60025 8.44791L16.1962 15.0439C16.5867 15.4344 16.5867 16.0676 16.1962 16.4581Z"
        fill="#B00020"
      />
    </svg>
  );
};
