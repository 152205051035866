import React, { useState } from "react";
import { Box } from "@material-ui/core";
import {
  SAText,
  SABox,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
} from "@saux/design-system-react";
import styled from "styled-components";
import { formatDate } from "../../util/formatters";
import { FlexContainer, TextAlignLeftBox } from "../styled";
import { SortType } from "../wcClaimsTab/WcClaimsTab";
import { Snowplow } from "../../util/snowplow/snowplow";
import { useClaimsApplicationState } from "../../providers";

export interface WcClaimsItemProps {
  injuredName?: string;
  claimNumber?: string;
  lossLocation?: string;
  dateOfLoss?: string;
  status?: string;
  selected: boolean;
  injuryDetails?: string;
  dateOfBirth?: Date;
  sortType: SortType;
  changeSelectedAndPolicyNumber: Function;
  policyNumber?: string;
  hideClaimDetails?: boolean;
}

export const AltColorText = styled(SAText)`
  color: #041e41;
`;
export const ClaimNumberWrapper = styled(SABox)`
  white-space: nowrap;
  margin-right: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SAIconBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 10px;
  width: 36px;
  height: 24px;
  background: #f4dffa;
  border: 1px solid #9325b2;
  border-radius: 5px;
`;

export const SAArrowBox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid #0173c6;
  border-radius: 5px;
  padding-top: 4px;
`;

export const SAArrowContainerBox = styled(SABox)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const SAFullWidthBox = styled(SABox)`
  width: 100%;
`;

export const SAColorIcon = styled(SAIcon)`
  color: #9325b2;
`;

const SelectableBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }
`;

const ContainerBox = styled(Box)`
  padding: 10px;
  background: #e4eded;
  border-top: 1px solid #cedbdb;
`;

export const WcClaimsItem = ({
  injuredName,
  claimNumber,
  lossLocation,
  dateOfLoss,
  status,
  selected,
  injuryDetails,
  sortType,
  policyNumber,
  changeSelectedAndPolicyNumber,
  hideClaimDetails,
}: WcClaimsItemProps) => {
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const { accountNumber } = useClaimsApplicationState();

  const toggleShowDetails = () => {
    if (!showDetails) {
      Snowplow.track.wcSnowplowEvent({
        event: "wcShowDetailedClaim",
        accountNumber: accountNumber || "",
        claimNumber: claimNumber || "",
        policyNumber: policyNumber || "",
      });
    }
    setShowDetails(!showDetails);
  };

  const selectCurrentClaim = () => {
    changeSelectedAndPolicyNumber(
      claimNumber,
      policyNumber,
      !!hideClaimDetails
    );
  };

  // function to get the second field label depending on sortType
  const getSecondFieldText = () => {
    if (sortType === SortType.ClaimStatus) {
      return "Claim Status:";
    }
    if (sortType === SortType.LossLocation) {
      return "Loss Location:";
    }
    return "Date of Injury:";
  };

  // function to get the second field value depending on sortType
  const getSecondFieldData = () => {
    if (sortType === SortType.LossLocation) {
      return lossLocation;
    }

    return formatDate(dateOfLoss) as string;
  };

  return (
    <ContainerBox
      key={claimNumber}
      className="wc-claims-item"
      style={
        selected
          ? {
              background: "white",
              borderLeft: "4px solid #4D840B",
            }
          : {
              background: "#EDEDED",
            }
      }
    >
      <SAColumns columns={{ md: [10, 2] }} spacing="xs">
        <SelectableBox
          onClick={selectCurrentClaim}
          data-testid="wc-claim-item-box"
        >
          <FlexContainer pb="small">
            <ClaimNumberWrapper>
              <SAIconBox mr="small">
                <SAColorIcon
                  icon={SAIcons.medicalSuitcase}
                  size={SAIconSize.small}
                  colorVariant="highlight"
                />
              </SAIconBox>
              <SAFullWidthBox data-testid={claimNumber} pl="medium">
                <AltColorText
                  type="heading-4"
                  text={claimNumber}
                  onClick={selectCurrentClaim}
                />
              </SAFullWidthBox>
            </ClaimNumberWrapper>
          </FlexContainer>
          <SAColumns columns={{ md: [5, 7] }} spacing="xs">
            <TextAlignLeftBox pb="xxs">
              <AltColorText type="small" text="Injured Name:" />
            </TextAlignLeftBox>
            <TextAlignLeftBox pb="xxs">
              <AltColorText type="small" text={injuredName} />
            </TextAlignLeftBox>
          </SAColumns>

          {showDetails ? (
            <>
              <SAColumns columns={{ md: [5, 7] }} spacing="xs">
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text="Date of Injury:" />
                </TextAlignLeftBox>
                <TextAlignLeftBox pb="xxs">
                  <AltColorText
                    type="small"
                    text={formatDate(dateOfLoss) as string}
                  />
                </TextAlignLeftBox>
              </SAColumns>
              <SAColumns columns={{ md: [5, 7] }} spacing="xs">
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text="Injury Details:" />
                </TextAlignLeftBox>
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text={injuryDetails} />
                </TextAlignLeftBox>
              </SAColumns>
              <SAColumns columns={{ md: [5, 7] }} spacing="xs">
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text="Loss Location:" />
                </TextAlignLeftBox>
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text={lossLocation} />
                </TextAlignLeftBox>
              </SAColumns>
              <SAColumns columns={{ md: [5, 7] }} spacing="xs">
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text="Claim Status:" />
                </TextAlignLeftBox>
                <TextAlignLeftBox pb="xxs">
                  <SAText
                    type="small"
                    text={status}
                    colorVariant={
                      status === "Open" || status === "open"
                        ? "secondary"
                        : "alert"
                    }
                  />
                </TextAlignLeftBox>
              </SAColumns>
            </>
          ) : (
            <SAColumns columns={{ md: [5, 7] }} spacing="xs">
              <TextAlignLeftBox pb="xxs">
                <AltColorText type="small" text={getSecondFieldText()} />
              </TextAlignLeftBox>
              {sortType === SortType.ClaimStatus ? (
                <TextAlignLeftBox pb="xxs">
                  <SAText
                    type="small"
                    text={status}
                    colorVariant={
                      status === "Open" || status === "open"
                        ? "secondary"
                        : "alert"
                    }
                  />
                </TextAlignLeftBox>
              ) : (
                <TextAlignLeftBox pb="xxs">
                  <AltColorText type="small" text={getSecondFieldData()} />
                </TextAlignLeftBox>
              )}
            </SAColumns>
          )}
        </SelectableBox>
        <SAArrowContainerBox>
          <SAArrowBox>
            <SAIcon
              icon={showDetails ? SAIcons.chevronUp : SAIcons.chevronDown}
              size={SAIconSize.small}
              colorVariant="primary"
              onClick={toggleShowDetails}
              data-testid="toggle-item-details"
            />
          </SAArrowBox>
        </SAArrowContainerBox>
      </SAColumns>
    </ContainerBox>
  );
};
