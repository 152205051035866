import { SABox, SAText } from "@saux/design-system-react";
import React, { ReactElement } from "react";

export const openLink = (e: React.MouseEvent<HTMLElement>, link: string) => {
  e.preventDefault();
  window.open(link);
};

interface KeyValueTextsProps {
  key: string;
  value: string;
  link?: string;
}

export const TextBulletPoints = (props: {
  elements: (ReactElement | string)[];
  listStyleType?: string | undefined;
}) => {
  return (
    <ul style={{ listStyleType: props.listStyleType }}>
      {props.elements.map((item: ReactElement | string, index) => (
        <li
          style={{ marginBottom: "10px" }}
          key={`${item}`}
          data-testid={`${index}-list-element`}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export const KeyValueTexts = (props: {
  pairs: KeyValueTextsProps[];
  boldKey?: boolean;
  mb?: string;
}) => {
  return (
    <SABox>
      {props.pairs.map((item) => (
        <SABox mb={props.mb || "8px"} key={item.key} data-testid={item.key}>
          <SAText
            type="standard"
            weight={
              (props.boldKey && (props.boldKey ? "bold" : undefined)) || "bold"
            }
            text={`${item.key}: `}
          />
          <SAText
            type="standard"
            text={item.value}
            colorVariant={item.link ? "link" : undefined}
            onClick={(e) => (item.link ? openLink(e, item.link) : {})}
          />
        </SABox>
      ))}
    </SABox>
  );
};
