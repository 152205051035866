import React, { useState } from "react";
import styled from "styled-components";
import { Box, useMediaQuery } from "@material-ui/core";
import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAModal,
  SAStack,
  SAText,
} from "@saux/design-system-react";
import { RecentClaimsContainer, ClaimsDetailContainer } from "../../components";
import ClaimsPageEmptyState from "../../components/claimsPageEmptyState";
import FAQDataContainer from "../../components/FAQSection/FAQDataContainer";
import { CenteredBox } from "../../components/recentClaimsContainer/EmptyClaimsContainer";
import { useClaimsApplicationState } from "../../providers";
import { altBreakpointsTheme } from "../../overrides";
import { Loading, errorMessage } from "../../components/loading";
import { ModalControllerProvider } from "../../providers/ModalController";
import { ModalOverlay } from "../../components/modalOverlay";
import NewClaimModal from "../../components/NewClaimModal/NewClaimModal";
import { useFileNewClaim } from "../../hooks/claimsHook";
import { ViewWCButton } from "../../components/viewWCButton/ViewWCButton";
import { ViewOtherClaimsButton } from "../../components/viewOtherClaimsButton/ViewOtherClaimsButton";
import { WcClaimsTab } from "../../components/wcClaimsTab/WcClaimsTab";
import { WcClaimSearch } from "../../components/wcClaimSearch/WcClaimSearch";

export interface NewClaimLinkProps {
  account_number: string;
}

export const NewClaimLink = ({ account_number }: NewClaimLinkProps) => {
  const {
    policyDetails,
    open,
    policyError,
    setPolicyError,
    claimLoader,
    handleClose,
    newClaimClick,
    getPolicyQuery,
  } = useFileNewClaim(account_number);

  return (
    <SABox>
      {policyError && (
        <SAModal
          onClickHandler={(event: React.MouseEvent) => {
            event.stopPropagation();
            setPolicyError(!policyError);
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SACard variant="standard" title="Error" width={480} height={85}>
              <SAText text={errorMessage} />
            </SACard>
          </div>
        </SAModal>
      )}
      <Loading loading={claimLoader}>
        <SAButton
          label="FILE A NEW CLAIM"
          onClick={getPolicyQuery}
          variant="primary"
        />
      </Loading>
      <NewClaimModal
        open={open}
        handleClose={handleClose}
        handleSubmit={newClaimClick}
        policy_details={policyDetails}
      />
    </SABox>
  );
};

const HeaderBox = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 20px 0px;
`;

const ClaimNumberHeaderBox = styled(SABox)`
  display: block;
  text-align: left;
`;

const SACenterBox = styled(SABox)`
  display: flex;
  justify-content: center;
`;

const SADetailsBox = styled(SABox)`
  height: 100%;
`;

export interface ClaimsPageProps {
  loadingIncidentStatus: boolean;
  errorIncidentStatus: boolean;
  accountNumber: string;
  claimCallLoading: boolean;
  claimCallErrorStatus: boolean;
  wcClaimCallLoading: boolean;
  wcClaimCallErrorStatus: boolean;
}
export const ClaimsPage = ({
  loadingIncidentStatus,
  errorIncidentStatus,
  accountNumber,
  claimCallLoading,
  claimCallErrorStatus,
  wcClaimCallLoading,
  wcClaimCallErrorStatus,
}: ClaimsPageProps) => {
  const {
    recentClaimsList,
    priorClaimsList,
    selected,
    userDisplayName,
    hasWorkersComp,
    isWorkersCompView,
  } = useClaimsApplicationState();

  // state to track the searched claim number string
  const [searchString, setSearchString] = useState<string>("");

  // state to open/close advanced filter modal
  const [
    openAdvancedFilterModal,
    setOpenAdvancedFilterModal,
  ] = useState<boolean>(false);

  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(altBreakpointsTheme.breakpoints.down("md"));

  // render loading depending on view type
  const renderLoading = isWorkersCompView
    ? wcClaimCallLoading
    : claimCallLoading;
  const renderError = isWorkersCompView
    ? wcClaimCallErrorStatus
    : claimCallErrorStatus;

  const emptyClaimsList = !recentClaimsList?.length && !priorClaimsList?.length;

  return (
    <>
      {renderLoading ||
      renderError ||
      (emptyClaimsList && !isWorkersCompView) ? (
        <SABox padding="medium">
          {hasWorkersComp && (
            <SACenterBox>
              {isWorkersCompView ? <ViewOtherClaimsButton /> : <ViewWCButton />}
            </SACenterBox>
          )}
          <Loading loading={renderLoading}>
            <ClaimsPageEmptyState
              userDisplayName={userDisplayName || ""}
              account_number={accountNumber}
            />
          </Loading>
        </SABox>
      ) : (
        <ModalControllerProvider>
          <>
            <ModalOverlay />
            <Box margin="20px 0px 20px 0px">
              {isTablet ? (
                <SAStack spacing="medium">
                  <SACard variant="minimal">
                    <CenteredBox>
                      <NewClaimLink account_number={accountNumber} />
                    </CenteredBox>
                  </SACard>
                  <RecentClaimsContainer account_number={accountNumber} />
                  {!isMobile && <FAQDataContainer />}
                </SAStack>
              ) : (
                <SAColumns
                  columns={isWorkersCompView ? { xs: [3, 9] } : { sm: [4, 8] }}
                  spacing="medium"
                >
                  <SAStack spacing="large">
                    {hasWorkersComp && (
                      <SACenterBox>
                        {isWorkersCompView ? (
                          <ViewOtherClaimsButton />
                        ) : (
                          <ViewWCButton />
                        )}
                      </SACenterBox>
                    )}
                    {isWorkersCompView ? (
                      <WcClaimsTab
                        searchString={searchString}
                        setSearchString={setSearchString}
                        openAdvancedFilterModal={openAdvancedFilterModal}
                        setOpenAdvancedFilterModal={setOpenAdvancedFilterModal}
                      />
                    ) : (
                      <SAStack spacing="large">
                        <RecentClaimsContainer account_number={accountNumber} />
                        <FAQDataContainer />
                      </SAStack>
                    )}
                  </SAStack>
                  <SADetailsBox>
                    <Loading
                      loading={loadingIncidentStatus}
                      error={errorIncidentStatus}
                    >
                      {selected === "" && isWorkersCompView ? (
                        <WcClaimSearch
                          setSearchString={setSearchString}
                          setOpenAdvancedFilterModal={
                            setOpenAdvancedFilterModal
                          }
                        />
                      ) : (
                        <SABox>
                          {selected === "emptyRecent" ||
                          selected === "emptyPrior" ? (
                            <SABox m="small">
                              <SAStack>
                                <SAText
                                  type="heading-3"
                                  text={
                                    selected === "emptyRecent"
                                      ? "No Recent Claims"
                                      : "No Prior Claims"
                                  }
                                />
                              </SAStack>
                            </SABox>
                          ) : (
                            <HeaderBox>
                              <ClaimNumberHeaderBox>
                                <SAText type="standard" text="Claim Number:" />
                                <SAText type="heading-2" text={selected} />
                              </ClaimNumberHeaderBox>
                              <NewClaimLink account_number={accountNumber} />
                            </HeaderBox>
                          )}
                          <ClaimsDetailContainer />
                        </SABox>
                      )}
                    </Loading>
                  </SADetailsBox>
                </SAColumns>
              )}
            </Box>
          </>
        </ModalControllerProvider>
      )}
    </>
  );
};
