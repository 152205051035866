import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { useMediaQuery, useTheme } from "@material-ui/core";
import {
  SABox,
  SAButton,
  SACard,
  SAColumns,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import {
  useModalController,
  ModalStates,
  ModalTypes,
} from "../../providers/ModalController";
import { DocumentListItem } from "../claimsDetail/ClaimDetailsDropdown";
import { formatDate } from "../../util/formatters";
import { Loading } from "../loading";
import { PhotosAndFilesTable } from "./PhotosAndFilesTable";
import MobileFileListContainer from "./MobileFileListContainer";
import RefreshIcon from "./RefreshIcon";

export const StyledSABox = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const HeaderSection = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;
`;

export const StyledButtonContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const GreyTextLight = styled(SAText)`
  color: #999999;
`;

export const GreyTextDark = styled(SAText)`
  color: #666666;
`;

export const RefreshButton = styled(SAButton)`
  padding: 5px 15px;
  font-size: 16px;
`;

export const transformDocData = (data: any, isMobile: boolean) => {
  const information = data?.account?.items[0]?.claimDocumentList;
  const docTableData: [string[]] = information?.map(
    (occurrence: DocumentListItem) => {
      const tableRow = [];
      tableRow.push(formatDate(occurrence.createTime));
      tableRow.push(occurrence.name);
      tableRow.push(occurrence.mimeType);
      tableRow.push(occurrence.author);
      tableRow.push(occurrence.documentUrl);
      return tableRow;
    }
  );

  if (isMobile) {
    return information;
  }

  return docTableData;
};

export default () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { dispatchModal } = useModalController();
  const {
    accountNumber,
    selected,
    claimsIncidentsList,
  } = useClaimsApplicationState();
  const { publicID } = useSelectedIncidentState();
  const [refetchClaimDocuments, setRefetchClaimDocuments] = useState(false);

  let documentData: any;

  const get_claim_document_list = loader(
    "../../graphql/queries/Get_Claim_Document_List.graphql"
  );

  const fetchVariables = {
    account_number: accountNumber,
    claim_number: selected,
    incidentId: publicID,
    pollInterval: 0,
  };
  const [
    getDocumentList,
    { data: documentListResponse, loading, error, refetch },
  ] = useLazyQuery(get_claim_document_list, {
    variables: fetchVariables,
  });

  useEffect(() => {
    if (claimsIncidentsList?.length) {
      getDocumentList();
    }
  }, []);

  useEffect(() => {
    if (refetchClaimDocuments && refetch) {
      setRefetchClaimDocuments(false);
      refetch(fetchVariables);
    }
  }, [refetchClaimDocuments]);

  const showModalHandler = (open: boolean) => {
    if (dispatchModal) {
      if (open) {
        dispatchModal({
          type: ModalStates.On,
          name: ModalTypes.Upload,
          claim: publicID,
        });
      } else {
        dispatchModal({ type: ModalStates.Off, name: ModalTypes.Upload });
      }
    }
  };

  if (documentListResponse && !loading) {
    documentData = transformDocData(documentListResponse, isMobile);
  }

  const MemoizedContent = () =>
    useMemo(
      () => (
        <>
          {isMobile ? (
            <Loading loading={loading} error={error}>
              <MobileFileListContainer
                documentData={documentData}
                showModalHandler={showModalHandler}
                setRefetchClaimDocuments={setRefetchClaimDocuments}
              />
            </Loading>
          ) : (
            <Loading loading={loading} error={error}>
              <StyledSABox m="medium">
                <SAColumns spacing="xs" columns={[12]}>
                  <SACard variant="minimal">
                    <HeaderSection>
                      <SAText type="heading-3" text="Photos & Files" />
                      <RefreshButton
                        data-testid="refreshFilesPhotosButton"
                        label="REFRESH"
                        endIcon={<RefreshIcon />}
                        variant="primary-small"
                        onClick={() => setRefetchClaimDocuments(true)}
                      />
                    </HeaderSection>

                    <PhotosAndFilesTable docData={documentData} />
                    <StyledButtonContainer>
                      <SAButton
                        data-testid="addFilesPhotosButton"
                        label="+ ADD FILES & PHOTOS"
                        variant="primary-small"
                        onClick={() => showModalHandler(true)}
                      />
                    </StyledButtonContainer>
                  </SACard>
                </SAColumns>
              </StyledSABox>
            </Loading>
          )}
        </>
      ),
      [isMobile, loading, error, documentData]
    );

  return <MemoizedContent />;
};
