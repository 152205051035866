import React, { useContext, ReactElement } from "react";
import { FeatureFlagObject } from "../services";

export interface FeatureFlags {
  featureFlags: FeatureFlagObject[];
}

export const FeatureFlagsContext = React.createContext<FeatureFlags>({
  featureFlags: [],
});

export interface WithFeatureFlagsContextInterface {
  children: ReactElement;
  featureFlags: FeatureFlagObject[];
}

export const WithFeatureFlagsContext = ({
  children,
  featureFlags,
}: WithFeatureFlagsContextInterface) => {
  return (
    <FeatureFlagsContext.Provider value={{ featureFlags }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useDCXFeatureFlags = () => {
  return useContext(FeatureFlagsContext);
};
