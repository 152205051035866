import { SABox, SAIcon, SATag } from "@saux/design-system-react";
import styled from "styled-components";

export const FlexContainer = styled(SABox)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const StyledTag = styled(SATag)`
  font-weight: 700;
  background-color: white;
  display: flex;
`;

export const StyledIcon = styled(SAIcon)`
  min-heigh: 20px;
  min-width: 20px;
`;

export const ClaimNumberWrapper = styled(SABox)`
  white-space: nowrap;
  margin-right: 58px;
`;

export const TextAlignLeftBox = styled(SABox)`
  text-align: left;
`;
