import React from "react";

export default () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill="#65A100" />
      <path
        d="M5 10C5 10 8.15902 13.5 8.44503 13.5C8.73103 13.5 15 6.5 15 6.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
