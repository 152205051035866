import React from "react";
import { SAIcon, SAIcons, SAIconSize } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import formatTitleCaseOther from "../../util/formatters/formatTitleCaseOther";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import { useSelectedIncidentState } from "../../providers";
import { StyledTag, TextAlignLeftBox } from "../styled";
import { IncidentDetailIconHeader } from "./IncidentDetailIconHeader";

export default () => {
  const { status, workerComp } = useSelectedIncidentState();

  const calculateStep = (status: String | undefined) => {
    if (status === "Review in Progress") {
      return "Step 2 of 4";
    }
    if (status === "Evaluate") {
      return "Step 3 of 4";
    }
    if (status === "Payment") {
      return "Step 4 of 4";
    }
    return "";
  };

  return (
    <SABox pr="large">
      <IncidentDetailIconHeader
        headerText="Status"
        icon={
          <SAIcon
            icon={SAIcons.clock}
            size={SAIconSize.medium}
            colorVariant="primary"
          />
        }
      >
        <SABox>
          <SABox py="xs">
            <StyledTag
              variant="primary-outline-small"
              label={formatTitleCaseOther(status || "")}
            />
          </SABox>
          {!workerComp && (
            <TextAlignLeftBox>
              <AltColorText type="small" text={calculateStep(status)} />
            </TextAlignLeftBox>
          )}
        </SABox>
      </IncidentDetailIconHeader>
    </SABox>
  );
};
