import React from "react";

export default () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2745 6.63836C11.6883 7.72422 10.484 8.56816 9.6619 9.16985C9.38623 9.37518 9.16259 9.53555 8.99079 9.65038C8.81916 9.76538 8.59075 9.88278 8.30529 10.0026C8.01996 10.1225 7.75408 10.1823 7.5074 10.1823H7.49273C7.24603 10.1823 6.98004 10.1225 6.69471 10.0026C6.40952 9.88278 6.18097 9.76538 6.00917 9.65038C5.83754 9.53555 5.61391 9.37518 5.33823 9.16985C4.68525 8.68555 3.4835 7.84159 1.73277 6.63836C1.45709 6.45257 1.21279 6.2396 1 6V11.8258C1 12.149 1.11356 12.4252 1.34089 12.6551C1.56825 12.8852 1.84155 13 2.16076 13H12.8394C13.1586 13 13.4317 12.8852 13.6591 12.6551C13.8864 12.4251 14 12.149 14 11.8258V6C13.7921 6.23466 13.5503 6.44763 13.2745 6.63836Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.24769 5.90643C2.41221 6.01357 2.90793 6.33169 3.73495 6.86066C4.56193 7.38976 5.19543 7.79704 5.63559 8.08267C5.6839 8.11407 5.78672 8.18209 5.94382 8.28704C6.10102 8.39197 6.23166 8.47688 6.33553 8.54152C6.43957 8.60626 6.5653 8.67881 6.71284 8.75922C6.86038 8.83947 6.99949 8.89983 7.12999 8.93977C7.26059 8.98008 7.38154 9 7.49273 9H7.50727C7.61856 9 7.73954 8.98008 7.87014 8.93977C8.00065 8.89983 8.13986 8.83934 8.28716 8.75922C8.4347 8.67869 8.56043 8.60626 8.66444 8.54152C8.76848 8.47688 8.89895 8.39197 9.05618 8.28704C9.21328 8.18199 9.3161 8.11407 9.36455 8.08267C9.80931 7.79704 10.9411 7.07151 12.7596 5.9063C13.1125 5.67872 13.4075 5.40407 13.6445 5.08267C13.8816 4.76126 14 4.42419 14 4.07151C14 3.77685 13.8851 3.52459 13.6554 3.31484C13.4256 3.10496 13.1535 3 12.8392 3H2.16063C1.78836 3 1.50171 3.11606 1.30108 3.34817C1.10032 3.58042 1 3.87055 1 4.21885C1 4.50006 1.13301 4.80486 1.39899 5.13299C1.66487 5.46115 1.94793 5.71891 2.24769 5.90643Z"
      fill="#0173C6"
    />
  </svg>
);
