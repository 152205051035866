import React from "react";

export default () => (
  <svg
    width="66"
    height="55"
    viewBox="0 0 66 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="illustration-upload-cloud">
      <path
        id="Path"
        d="M44 38L33 27L22 38"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M32.5 27V52"
        stroke="#80D4E8"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M55.8904 45C61.3617 42.0145 64.1145 35.696 62.5774 29.6515C61.0403 23.607 55.6045 19.3747 49.373 19.3705H45.937C43.6766 10.6195 36.263 4.16695 27.2907 3.14157C18.3184 2.11618 9.64243 6.72991 5.46932 14.7458C1.29622 22.7616 2.48877 32.5224 8.46875 39.2955"
        stroke="#0173C6"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M44 38L33 27L22 38"
        stroke="#80D4E8"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
