export default (text: string | undefined | null) => {
  if (!text) {
    return undefined;
  }

  const lowerCase = text.toLowerCase().split(" ");
  const titleCase = lowerCase.map((word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return titleCase.join(" ");
};
