import { useLazyQuery, useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { useState } from "react";
import getConfig, { Env } from "../config";

const get_policy_number = loader(
  "./../graphql/queries/Get_Policy_Number.graphql"
);

const update_claim_details_mutation = loader(
  "./../graphql/mutations/Update_Claim_Details.graphql"
);

interface PolicyDetails {
  policy_number: string;
  product_code: string;
}

const config = getConfig(process.env.REACT_APP_ENV as Env);

const wcProductCode = "WORKERS COMP";

export const useFileNewClaim = (account_number: string) => {
  const [policyDetails, setPolicyDetails] = useState<PolicyDetails[]>([]);
  const [open, setOpen] = useState(false);
  const [policyError, setPolicyError] = useState(false);
  const [claimLoader, setClaimLoader] = useState(false);
  const [getPolicyNumber, { error: claimCallError }] = useLazyQuery(
    get_policy_number,
    {
      fetchPolicy: "network-only",
    }
  );
  const [updateClaimDetails, { data, loading, error }] = useMutation(
    update_claim_details_mutation
  );

  const handleClose = () => {
    setPolicyDetails([]);
    setOpen(false);
  };

  const newClaimClick = (policyDetails: PolicyDetails) => {
    const [policy_name, policy_number] = [
      policyDetails.product_code,
      policyDetails.policy_number,
    ];
    if (policyDetails.product_code === wcProductCode) {
      setOpen(false);
      window.open(`${config.worker_comp_route}`, "_blank");
    } else {
      updateClaimDetails({
        variables: { account_number, policy_number, policy_name },
      })
        .then((queryResult) => {
          if (!queryResult.data?.fnolUuid?.uuid) {
            throw new Error();
          } else {
            setOpen(false);
            window.open(
              `${config.start_a_new_claim}uuid=${queryResult.data.fnolUuid.uuid}`,
              "_blank"
            );
          }
        })
        .catch((err: Error) => {
          console.error("updateClaimDetails ERROR: ", err);
          setOpen(false);
          setPolicyError(true);
        });
    }
  };

  const variables = {
    account_number,
  };

  const getPolicyQuery = () => {
    setClaimLoader(true);
    getPolicyNumber({ variables })
      .then((queryResult: any) => {
        if (queryResult.data.account.items[0]) {
          setPolicyDetails(queryResult.data.account.items[0].policyPicker);
          setClaimLoader(false);
          setOpen(true);
        } else {
          throw Error();
        }
      })
      .catch((err: Error) => {
        console.error("getPolicyNumber ERROR: ", err);
        setClaimLoader(false);
        setPolicyError(true);
      });
  };

  return {
    policyDetails,
    open,
    policyError,
    setPolicyError,
    claimLoader,
    handleClose,
    newClaimClick,
    getPolicyQuery,
  };
};
