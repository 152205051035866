import { SAInput } from "@saux/design-system-react";
import { SAInputFieldProps } from "@saux/design-system-react/input/variants";
import React, { useEffect, useState } from "react";

interface WholeDollarInputProps {
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (value: number | null) => void;
  onBlur?: (value: number | null) => void;
}

export const WholeDollarInput = (
  props: WholeDollarInputProps &
    Omit<SAInputFieldProps, "onChange" | "onBlur"> &
    React.RefAttributes<HTMLInputElement>
) => {
  const [dollarValue, setDollarValue] = useState<number | null>(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[$\s]/, "");
    const intValue = parseInt(filteredValue, 10);
    let newDollarValue: number | null = null;

    if (Number.isNaN(intValue)) {
      if (filteredValue !== "") {
        newDollarValue = dollarValue;
      }
    } else {
      newDollarValue = intValue;
    }

    setDollarValue(newDollarValue);

    if (props.onChange && newDollarValue !== dollarValue) {
      props.onChange(newDollarValue);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(/[$\s]/, "");
    const intValue = parseInt(filteredValue, 10);
    let blurValue: number | null = null;

    if (!Number.isNaN(intValue)) {
      blurValue = intValue;
    }

    if (props.onBlur) {
      props.onBlur(blurValue);
    }
  };

  const parseAndSetValue = (value: string | number) => {
    let finalValue: number | null = null;

    if (typeof value === "number") {
      finalValue = Math.round(value);
    } else if (typeof value === "string") {
      finalValue = parseInt(value, 10);

      if (Number.isNaN(finalValue)) {
        finalValue = dollarValue;
      }
    }

    setDollarValue(finalValue);
  };

  useEffect(() => {
    if (props.defaultValue !== undefined) {
      parseAndSetValue(props.defaultValue);
    }
  }, []);

  useEffect(() => {
    if (props.value !== undefined) {
      parseAndSetValue(props.value);
    }
  }, [props.value]);

  return (
    <SAInput
      {...props}
      type="text"
      backgroundColor="white"
      value={dollarValue === null ? "" : `$${dollarValue}`}
      onChange={onChange}
      onBlur={onBlur}
      placeholder="$0"
      defaultValue={undefined}
    />
  );
};
