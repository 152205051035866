import React from "react";

export const MapPinIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.1818C18 12.1746 16.9261 14.2416 15.3996 16.1209C14.2283 17.5629 12.917 18.739 12 19.4869C11.083 18.739 9.77166 17.5629 8.60041 16.1209C7.07388 14.2416 6 12.1746 6 10.1818C6 8.52798 6.64281 6.95067 7.77316 5.79463C8.90202 4.64012 10.4234 4 12 4C13.5766 4 15.098 4.64012 16.2268 5.79463C17.3572 6.95067 18 8.52798 18 10.1818ZM20 10.1818C20 15.5271 14.3556 20.2309 12.5491 21.5996C12.2212 21.8479 11.7788 21.8479 11.4509 21.5996C9.6444 20.2309 4 15.5271 4 10.1818C4 8.01187 4.84285 5.93079 6.34315 4.3964C7.84344 2.86201 9.87827 2 12 2C14.1217 2 16.1566 2.86201 17.6569 4.3964C19.1571 5.93079 20 8.01187 20 10.1818ZM10 10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10C14 11.1046 13.1046 12 12 12C10.8954 12 10 11.1046 10 10ZM12 6C9.79086 6 8 7.79086 8 10C8 12.2091 9.79086 14 12 14C14.2091 14 16 12.2091 16 10C16 7.79086 14.2091 6 12 6Z"
        fill="#0173C6"
      />
    </svg>
  );
};
