import { SAIconColorVariant } from "@saux/design-system-react";
import React from "react";

export interface CashIconProps {
  width?: string;
  height?: string;
  color?: any;
}

export default ({ width, height, color }: CashIconProps) => {
  const fill = color || "#666666";

  return (
    <svg
      width={width || "14"}
      height={height || "14"}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      color={fill}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18V6H20V18H4ZM3.75 4C2.7835 4 2 4.7835 2 5.75V18.25C2 19.2165 2.7835 20 3.75 20H20.25C21.2165 20 22 19.2165 22 18.25V5.75C22 4.7835 21.2165 4 20.25 4H3.75ZM12 10H11.3333C11.1492 10 11 10.1492 11 10.3333V10.6666C11 10.8507 11.1492 11 11.3333 11H12.5C13.8807 11 15 12.1193 15 13.5C15 14.7095 14.1411 15.7184 13 15.95V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16H10.5C9.94772 16 9.5 15.5523 9.5 15C9.5 14.4477 9.94772 14 10.5 14H12H12.5C12.7761 14 13 13.7761 13 13.5C13 13.2238 12.7761 13 12.5 13H11.3333C10.0447 13 9 11.9553 9 10.6666V10.3333C9 9.15784 9.86924 8.18537 11 8.02363V8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8H13.5C14.0523 8 14.5 8.44772 14.5 9C14.5 9.55228 14.0523 10 13.5 10H12Z"
        fill={fill}
        color={fill}
      />
    </svg>
  );
};
