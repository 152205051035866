import React, { useState, useEffect } from "react";
import { SAAlert, SABox, SAStack } from "@saux/design-system-react";
import { Box, useMediaQuery } from "@material-ui/core";
import {
  SelectedIncidentState,
  useClaimsApplicationState,
} from "../../providers";
import ClaimsDetail from "../claimsDetail";
import { Accordion } from "../accordion";
import { altBreakpointsTheme } from "../../overrides";
import { ClaimDetailsDropdown } from "../claimsDetail/ClaimDetailsDropdown";
import { NoIncidentsView } from "../claimsDetail/NoIncidentsView";
import { ClaimsDetailProps } from "../claimsDetail/ClaimsDetail";

export interface ClaimsDetailContainerProps {
  callback?: Function;
}

export default ({ callback }: ClaimsDetailContainerProps) => {
  const { claimsIncidentsList, hideClaimDetails } = useClaimsApplicationState();
  const [expandedIncidentIndex, setExpandedIncidentIndex] = useState<
    number | undefined
  >();
  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(altBreakpointsTheme.breakpoints.down("sm"));

  useEffect(() => {
    if (
      (expandedIncidentIndex === 0 || expandedIncidentIndex) &&
      !(isMobile || isTablet)
    ) {
      document
        .getElementById(`incident-${expandedIncidentIndex + 1}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  }, [expandedIncidentIndex]);

  const defaultCallback = ({ expanded, index }: any) => {
    if (callback) {
      callback({ expanded, index });
    }

    if (expanded) {
      setExpandedIncidentIndex(index);
    }
  };

  return (
    <SABox>
      {claimsIncidentsList?.length ? (
        <>
          {claimsIncidentsList.map(
            (displayedItem: ClaimsDetailProps | undefined, index: number) => {
              return (
                <Box id={`incident-${index + 1}`} key={`incident-${index + 1}`}>
                  <SelectedIncidentState value={{ ...displayedItem }}>
                    <SABox mt="small" mb="large">
                      <Accordion
                        displayIcon={!isTablet && !hideClaimDetails}
                        expand={
                          index === expandedIncidentIndex &&
                          !isMobile &&
                          !hideClaimDetails
                        }
                        callback={(expanded) =>
                          defaultCallback({
                            expanded,
                            index,
                          })
                        }
                        title={
                          <SAStack spacing="medium">
                            {hideClaimDetails && (
                              <SAAlert
                                severity="info"
                                data-testid="litigated-claim-alert"
                              >
                                Due to the sensitive nature of this claim,
                                please contact your Adjuster.
                              </SAAlert>
                            )}
                            <ClaimsDetail />
                          </SAStack>
                        }
                        content={
                          <ClaimDetailsDropdown
                            isWorkerComp={!!displayedItem?.workerComp}
                          />
                        }
                        disable={hideClaimDetails}
                      />
                    </SABox>
                  </SelectedIncidentState>
                </Box>
              );
            }
          )}
        </>
      ) : (
        <NoIncidentsView />
      )}
    </SABox>
  );
};
