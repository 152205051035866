import {
  SABox,
  SAIcons,
  SAIconSize,
  SASpinner,
  SAText,
} from "@saux/design-system-react";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import { ErrorContainer } from "../common/ErrorContainer";

const CenteredBox = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const CenterText = styled(SAText)`
  text-align: center;
`;

export interface LoadingProps {
  loading?: boolean | undefined;
  error?: ApolloError | boolean | undefined;
  children: ReactElement;
  icon?: SAIcons | undefined;
  text?: string;
  subText?: string;
  size?: string | undefined;
}

export const errorMessage = "Something went wrong";
export const tryLaterMessage = "Please try again later";
export const defaultErrorMessage = "There was an error";
export const refreshPageMessage = "Refresh the page to try again";

export const Loading = (props: LoadingProps) => {
  let message = defaultErrorMessage;
  let subText = "";

  if (props.loading) {
    return (
      <SABox>
        <CenteredBox padding="medium">
          <SASpinner variant="circular-continuous" delay={0} />
        </CenteredBox>
        <CenteredBox padding="xs">
          <CenterText type="standard" text="Loading" />
        </CenteredBox>
      </SABox>
    );
  }

  if (props.error) {
    if (typeof props.error === "boolean") {
      subText = tryLaterMessage;
    } else if (props.error.networkError?.message.includes("401")) {
      subText = refreshPageMessage;
    } else {
      message = errorMessage;
      subText = tryLaterMessage;
    }

    return (
      <ErrorContainer
        icon={props.icon || SAIcons.error}
        text={props.text || message}
        size={props.size || SAIconSize.xl}
        subText={subText}
      />
    );
  }

  return React.Children.only(props.children);
};
