import React, { useEffect } from "react";
import styled from "styled-components";
import {
  SelectedIncidentState,
  useClaimsApplicationState,
} from "../../providers";
import { useModalController } from "../../providers/ModalController";

const ModalOverlayStyled = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(4, 30, 65, 0.6);
  height: 100vh;
  z-index: 99999;
  overflow-y: auto;
`;

export const ModalOverlay = () => {
  const { open, Modal, claim } = useModalController();
  const { claimsIncidentsList } = useClaimsApplicationState();

  const value = claimsIncidentsList?.find(
    (value: any) => value.publicID === claim
  );

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
  }, [open]);

  return open ? (
    <SelectedIncidentState value={{ ...value }}>
      <ModalOverlayStyled id="ModalOverlayOpen">
        {React.cloneElement(Modal)}
      </ModalOverlayStyled>
    </SelectedIncidentState>
  ) : (
    <div id="ModalOverlayClosed" />
  );
};
