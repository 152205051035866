import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { SAStack, SABox } from "@saux/design-system-react";
import { SAColumns } from "@saux/design-system-react/columns";
import ClaimsLatestPayments from "../claimsLatestPayments";
import ClaimsAlerts from "../claimsAlerts";
import { ClaimsLatestPayment } from "../claimsLatestPayments/ClaimsLatestPayments";
import { ClaimsAlertProps } from "../claimsAlerts/ClaimsAlerts";

export interface ClaimsSummaryWrapperProps {
  payments?: ClaimsLatestPayment | undefined;
  alerts?: ClaimsAlertProps | undefined;
}

export default ({ payments, alerts }: ClaimsSummaryWrapperProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile ? (
        <SABox p="small">
          <SAStack spacing="medium">
            <SABox>
              <ClaimsLatestPayments {...payments} />
            </SABox>
            <SABox>
              <ClaimsAlerts {...alerts} />
            </SABox>
          </SAStack>
        </SABox>
      ) : (
        <SABox display="flex" m="medium">
          <SAColumns spacing="large">
            <SABox>
              <ClaimsLatestPayments {...payments} />
            </SABox>
            <SABox>
              <ClaimsAlerts {...alerts} />
            </SABox>
          </SAColumns>
        </SABox>
      )}
    </>
  );
};
