import { useLazyQuery } from "@apollo/client";
import {
  SAIcon,
  SAIcons,
  SAIconSize,
  SATable,
} from "@saux/design-system-react";
import { loader } from "graphql.macro";
import React from "react";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { download } from "../../services";

export interface PhotosAndFilesTableProps {
  docData: any;
}

export const PhotosAndFilesTable = ({ docData }: PhotosAndFilesTableProps) => {
  const { accountNumber } = useClaimsApplicationState();
  const { publicID } = useSelectedIncidentState();
  const get_download_url = loader(
    "../../graphql/queries/Download_Claims_Document.graphql"
  );
  const [getDownloadUrl] = useLazyQuery(get_download_url, {
    onCompleted: (returnData: any) => {
      const downloadLink =
        returnData?.account?.items[0]?.downloadClaimsDocument;
      download(downloadLink);
    },
  });

  const actionMenuDownload = (event: any, selectedRowIndex: number) => {
    const row = docData[selectedRowIndex];
    const edsUrl = row[4];

    const variables = {
      account_number: accountNumber,
      document_url: edsUrl,
      incident_id: publicID,
    };

    getDownloadUrl({ variables });
  };

  const onRowClick = () => {};

  return (
    <SATable
      variant="table-to-listview"
      columns={["Date", "File Name", "Document Type", "Provided By"]}
      data={docData || []}
      onRowClick={onRowClick}
      onActionMenuClick={actionMenuDownload}
      actionMenuIcon={
        <SAIcon
          icon={SAIcons.download}
          size={SAIconSize.medium}
          colorVariant="secondary"
        />
      }
    />
  );
};
