import React from "react";
import {
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
} from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";
import { WorkerCompProps } from "../ClaimsDetail";
import { IncidentDetailIconHeader } from "../IncidentDetailIconHeader";
import { TextAlignLeftBox } from "../../styled";

export interface WorkerCompTypeProps {
  worker?: WorkerCompProps;
}

export default ({ worker }: WorkerCompTypeProps) => {
  return (
    <IncidentDetailIconHeader
      headerText="Workers Comp"
      icon={<SAIcon icon={SAIcons.idCard} size={SAIconSize.medium} />}
      fullWidth
    >
      <SABox data-testid="workerCompType">
        <SAColumns columns={[5, 7]}>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text="Policy Number: " weight="bold" />
          </TextAlignLeftBox>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text={worker?.policyNumber || ""} />
          </TextAlignLeftBox>
        </SAColumns>
        <SAColumns columns={[5, 7]}>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text="Injured Name: " weight="bold" />
          </TextAlignLeftBox>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text={worker?.injuredName || ""} />
          </TextAlignLeftBox>
        </SAColumns>
      </SABox>
    </IncidentDetailIconHeader>
  );
};
