import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="alertPhoneTest"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM4.77428 3L3.90008 3.31812C2.81563 3.95558 2.71112 5.13957 3.58656 6.87009C4.89972 9.46587 7.7391 11.5642 8.92986 11.8532C10.1206 12.1422 10.5495 11.983 11.3502 11.5642C11.8841 11.285 12.089 10.8349 11.9649 10.2139L9.9003 8.68768L9.71088 8.55677C9.40508 8.35852 9.29929 8.39186 8.92986 8.68768C8.6388 8.92075 8.24188 8.92075 7.7391 8.68768C7.18107 8.29227 6.74441 7.90971 6.42915 7.54C6.11389 7.17029 5.94709 6.82388 5.92874 6.50078C6.19223 6.07023 6.35903 5.7483 6.42915 5.53499C6.53433 5.21502 6.32397 4.80361 5.92874 4.27431C5.70289 3.97186 5.3879 3.61658 4.98377 3.20846L4.77428 3Z"
      fill="#0173C6"
    />
  </svg>
);
