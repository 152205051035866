import React from "react";
import {
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import { SABox } from "@saux/design-system-react/box";
import { formatTitleCase } from "../../util/formatters";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { IncidentDetailIconHeader } from "./IncidentDetailIconHeader";
import { TextAlignLeftBox } from "../styled";
import { Snowplow } from "../../util/snowplow/snowplow";

const ClickableSAText = styled(SAText)`
  &:hover {
    cursor: pointer;
  }
`;

export const DEFAULT_ADJUSTER_PHONE: string = "1-800-789-2242";

export default () => {
  const { adjuster, workerComp } = useSelectedIncidentState();
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();

  const adjusterEmailClicked = () => {
    Snowplow.track.wcAdjusterEmailClicked({
      accountNumber: accountNumber || "",
      claimNumber: selected || "",
      policyNumber: selectedPolicyNumber || "",
      adjusterName: adjuster?.displayName || "",
      adjusterEmail: adjuster?.email || "",
    });
  };

  const adjusterPhoneClicked = () => {
    Snowplow.track.wcAdjusterPhoneClicked({
      accountNumber: accountNumber || "",
      claimNumber: selected || "",
      policyNumber: selectedPolicyNumber || "",
      adjusterName: adjuster?.displayName || "",
      adjusterPhone: adjuster?.phoneNumber || "",
    });
  };

  const SAI0005 = "State Auto Care Property Team";
  const adjusterName = formatTitleCase(adjuster?.displayName) || "N/A";

  return (
    <IncidentDetailIconHeader
      headerText="Adjuster"
      icon={<SAIcon icon={SAIcons.adjuster} size={SAIconSize.medium} />}
      fullWidth
    >
      <SABox>
        <SAColumns columns={workerComp ? [4, 8] : [2, 10]}>
          <TextAlignLeftBox>
            <AltColorText type="small" text="Name: " weight="bold" />
          </TextAlignLeftBox>
          <TextAlignLeftBox>
            <AltColorText type="small" text={adjusterName} />
          </TextAlignLeftBox>
        </SAColumns>
        {workerComp && (
          <>
            {adjusterName !== SAI0005 && adjuster?.email && (
              <SAColumns columns={[4, 8]}>
                <TextAlignLeftBox>
                  <AltColorText type="small" text="Email: " weight="bold" />
                </TextAlignLeftBox>
                <TextAlignLeftBox>
                  <ClickableSAText
                    type="small"
                    colorVariant="link"
                    text={adjuster?.email}
                    onClick={(e) => {
                      window.location.href = `mailto:${adjuster?.email}`;
                      e.preventDefault();
                      adjusterEmailClicked();
                    }}
                  />
                </TextAlignLeftBox>
              </SAColumns>
            )}
            <SAColumns columns={[4, 8]}>
              <TextAlignLeftBox>
                <AltColorText
                  type="small"
                  text="Phone Number: "
                  weight="bold"
                />
              </TextAlignLeftBox>
              <TextAlignLeftBox pl="xxs">
                <ClickableSAText
                  type="small"
                  colorVariant="link"
                  text={adjuster?.phoneNumber || DEFAULT_ADJUSTER_PHONE}
                  onClick={(e) => {
                    window.location.href = `tel:${
                      adjuster?.phoneNumber || DEFAULT_ADJUSTER_PHONE
                    }`;
                    e.preventDefault();
                    adjusterPhoneClicked();
                  }}
                />
              </TextAlignLeftBox>
            </SAColumns>
          </>
        )}
      </SABox>
    </IncidentDetailIconHeader>
  );
};
