import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { SABox, SAStack, SAText } from "@saux/design-system-react";
import styled from "styled-components";
import { altBreakpointsTheme } from "../../overrides";
import { NewClaimLink } from "../../page/ClaimsPage/ClaimsPage";
import { formatTitleCase } from "../../util/formatters";
import NoClaimsIcon from "../claimsPageEmptyState/NoClaimsIcon";

export interface EmptyClaimsContainerProps {
  type?: string;
  account_number: string;
}

export const CenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NarrowBox = styled(SABox)`
  width: 60%;
  height: auto;
`;

export default ({ type, account_number }: EmptyClaimsContainerProps) => {
  const isMobile = useMediaQuery(
    altBreakpointsTheme.breakpoints.between("sm", "lg")
  );

  return (
    <SABox>
      <SAStack spacing="medium">
        {isMobile ? (
          <CenteredBox>
            <NarrowBox>
              <NoClaimsIcon />
            </NarrowBox>
          </CenteredBox>
        ) : (
          <NoClaimsIcon />
        )}
        <CenteredBox>
          <SAText
            type="heading-3"
            text={`No ${formatTitleCase(type)} Claims`}
          />
        </CenteredBox>
        <CenteredBox>
          <SAText type="standard" text="Need to file a new claim?" />
        </CenteredBox>
        <CenteredBox>
          <NewClaimLink account_number={account_number} />
        </CenteredBox>
      </SAStack>
    </SABox>
  );
};
