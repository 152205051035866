import { useMediaQuery } from "@material-ui/core";
import { SABox, SATabBar } from "@saux/design-system-react";
import React, { useState } from "react";
import styled from "styled-components";
import { useIncidentType } from "../../hooks";
import { altBreakpointsTheme } from "../../overrides";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { Snowplow } from "../../util/snowplow/snowplow";
import ClaimAdjusterDropdownSection from "../claimAdjusterDropdownSection/ClaimAdjusterDropdownSection";
import ClaimsSummaryWrapper from "../claimsSummaryWrapper";
import OverviewSection from "../overviewSection/OverviewSection";
import PhotosAndFiles from "../photosAndFilesSection/PhotosAndFiles";
import { WcDetailsTab } from "../wcClaimDetailsTab/WcDetailsTab";
import { WCClaimPayments } from "../WCClaimPayments/WCClaimPayments";
import { WcFAQSection } from "../wcFAQSection/WcFAQSection";
import IncidentRelatedFAQSection from "./FAQSection/IncidentRelatedFAQSection";

export interface DocumentListItem {
  author?: string | null;
  category?: string | null;
  createTime?: string;
  documentSubType?: string | null;
  documentType?: string | null;
  documentUrl?: string | null;
  mimeType?: string | null;
  name?: string | null;
}

interface ClaimsDetailsProps {
  isWorkerComp?: boolean;
}

export const ClaimDetailsDropdownTabBar = styled(SATabBar)`
  background-color: #f4f6fa;

  button {
    cursor: pointer;
  }

  & + div {
    height: calc(100vh - 9rem);
    overflow: auto;
    margin: 30px 0;
  }

  @media (min-width: 750px) {
    background-color: inherit;

    & + div {
      height: 100%;
    }
  }
`;

export const ClaimDetailsDropdown = ({ isWorkerComp }: ClaimsDetailsProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const incidentType = useIncidentType();
  const { summary, adjuster } = useSelectedIncidentState();
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();
  const isMobile = useMediaQuery(altBreakpointsTheme.breakpoints.down("sm"));
  const itemLabels = isWorkerComp
    ? ["Overview & Updates", "Details", "Adjuster Info", "Payments", "FAQs"]
    : ["Summary", "Photos & Files", "Adjuster Information", "FAQs"];

  const tabChanged = (newActiveIndex: number) => {
    setActiveIndex(newActiveIndex);
  };

  const wcTabOpened = (event: string) => {
    Snowplow.track.wcSnowplowEvent({
      event,
      accountNumber: accountNumber || "",
      claimNumber: selected || "",
      policyNumber: selectedPolicyNumber || "",
    });
  };

  const renderContent = (returnIndex: number) => {
    switch (returnIndex) {
      case 1:
        return <PhotosAndFiles />;
      case 2:
        return <ClaimAdjusterDropdownSection adjuster={adjuster} />;
      case 3:
        return <IncidentRelatedFAQSection incidentType={incidentType} />;
      default:
        return <ClaimsSummaryWrapper {...summary} />;
    }
  };

  const renderContentWorkerComp = (returnIndex: number) => {
    switch (returnIndex) {
      case 0:
        return <OverviewSection />;
      case 1:
        wcTabOpened("wcDetailsTabOpened");
        return <WcDetailsTab />;
      case 2:
        wcTabOpened("wcAdjusterTabOpened");
        return (
          <ClaimAdjusterDropdownSection adjuster={adjuster} isWorkerComp />
        );
      case 3:
        wcTabOpened("wcPaymentsTabOpened");
        return <WCClaimPayments />;
      case 4:
        wcTabOpened("wcFaqTabOpened");
        return <WcFAQSection />;
      default:
        return <OverviewSection />;
    }
  };

  const contentToRender = isWorkerComp
    ? renderContentWorkerComp(activeIndex)
    : renderContent(activeIndex);

  return (
    <SABox
      style={{
        background: isMobile ? "#ffffff" : "#E6EAF1",
        padding: isMobile ? "0px 20px 20px 20px" : "40px 20px 20px 20px",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        margin: "20px -20px -30px -20px",
      }}
    >
      <ClaimDetailsDropdownTabBar
        activeIndex={activeIndex}
        itemLabels={itemLabels}
        onChange={tabChanged}
      />
      {contentToRender}
    </SABox>
  );
};
