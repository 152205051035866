import React from "react";
import { SABox, SAButton, SACard, SAStack } from "@saux/design-system-react";
import { StyledButtonContainer, RefreshButton } from "./PhotosAndFiles";
import { DocumentListItem } from "../claimsDetail/ClaimDetailsDropdown";
import MobileFileListItem from "./MobileFileListItem";
import RefreshIcon from "./RefreshIcon";

export interface MobileFileListContainerProps {
  documentData: any;
  showModalHandler: Function;
  setRefetchClaimDocuments: any;
}

export default ({
  documentData,
  showModalHandler,
  setRefetchClaimDocuments,
}: MobileFileListContainerProps) => {
  const onClickHandler = () => {
    showModalHandler(true);
  };

  return (
    <SABox>
      <SACard variant="minimal" removePadding>
        <StyledButtonContainer pr="small" pb="large">
          <RefreshButton
            data-testid="refreshFilesPhotosButton"
            label="REFRESH"
            endIcon={<RefreshIcon />}
            variant="primary-small"
            onClick={() => setRefetchClaimDocuments(true)}
          />
        </StyledButtonContainer>
        <SAStack>
          {(documentData || [])?.map((f: DocumentListItem) => (
            <MobileFileListItem f={f} />
          ))}
        </SAStack>
        <StyledButtonContainer pr="small" pb="large">
          <SAButton
            label="+ ADD FILES & PHOTOS"
            variant="primary-small"
            onClick={onClickHandler}
          />
        </StyledButtonContainer>
      </SACard>
    </SABox>
  );
};
