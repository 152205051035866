import { useSelectedIncidentState } from "../providers";

export enum IncidentTypes {
  Auto = "Auto",
  Property = "Property",
  Injury = "Injury",
  Medical = "Medical",
  WorkerComp = "WorkerComp",
  None = "",
}

export const useIncidentType = (): IncidentTypes => {
  const {
    vehicle,
    property,
    injury,
    medical,
    workerComp,
  } = useSelectedIncidentState();
  let incidentType = IncidentTypes.None;

  if (vehicle) {
    incidentType = IncidentTypes.Auto;
  }

  if (property) {
    incidentType = IncidentTypes.Property;
  }

  if (injury) {
    incidentType = IncidentTypes.Injury;
  }

  if (workerComp) {
    incidentType = IncidentTypes.WorkerComp;
  }

  if (medical) {
    incidentType = IncidentTypes.Medical;
  }

  return incidentType;
};
