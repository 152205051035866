import React from "react";
import {
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
} from "@saux/design-system-react";
import { useClaimsApplicationState } from "../../providers";

export const ViewWCButton = () => {
  const { setIsWorkersCompView, setSelected } = useClaimsApplicationState();

  return (
    <SAButton
      endIcon={
        <SAIcon
          icon={SAIcons.chevronRight}
          size={SAIconSize.small}
          colorVariant="primary"
        />
      }
      label="VIEW WORKERS COMP CLAIMS"
      onClick={() => {
        // Reset selected claims
        setSelected("");
        setIsWorkersCompView(true);
      }}
      variant="secondary-medium-outline"
    />
  );
};
