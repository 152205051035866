import React from "react";
import { SAIcon, SAIcons, SAIconSize } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { SAStack } from "@saux/design-system-react/stack";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";

export interface InjuryProps {
  name?: string;
  birthYear?: string;
}

export interface InjuryClaimTypeProps {
  injury?: InjuryProps;
}

export default ({ injury }: InjuryClaimTypeProps) => {
  return (
    <SABox mr="small">
      <SABox display="flex">
        <SAIcon icon={SAIcons.injury} size={SAIconSize.medium} />
        <SABox pl="small">
          <SAStack>
            <SABox>
              <AltColorText type="heading-3" text="Injury" />
            </SABox>
            <SABox display="flex">
              <SABox display="flex" flex={1} mr="xxs" ml="xs">
                <SAStack>
                  <SABox display="flex">
                    <AltColorText
                      type="small"
                      text="Injured Party: "
                      weight="bold"
                    />
                  </SABox>
                  <SABox display="flex">
                    <AltColorText
                      type="small"
                      text="Birth Year: "
                      weight="bold"
                    />
                  </SABox>
                </SAStack>
              </SABox>
              <SABox display="flex">
                <SAStack>
                  <SABox display="flex">
                    <AltColorText type="small" text={injury?.name || ""} />
                  </SABox>
                  <SABox display="flex">
                    <AltColorText type="small" text={injury?.birthYear || ""} />
                  </SABox>
                </SAStack>
              </SABox>
            </SABox>
          </SAStack>
        </SABox>
      </SABox>
    </SABox>
  );
};
