import {
  SAAutocomplete,
  SABox,
  SAButton,
  SACard,
  SACheckbox,
  SAColumns,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAInput,
  SAModal,
  SASelectOption,
  SASeparator,
  SAText,
} from "@saux/design-system-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useClaimsApplicationState } from "../../providers";
import { formatTitleCase } from "../../util/formatters";
import { Snowplow } from "../../util/snowplow/snowplow";
import { WholeDollarInput } from "../common/WholeDollarInput/WholeDollarInput";
import {
  WCClaimPaymentsFilters,
  WhiteBGSelect,
  MinMax,
} from "./WCClaimPayments";
import { convertDate } from "./WCClaimPaymentsTable";

const CardContainer = styled(SABox)`
  max-width: 1000px;
  justify-content: center;
  overflow-y: auto;
  height: max-content;
`;

const FlexModal = styled(SAModal)`
  display: flex;
  justify-content: center;
  overflow-x: auto;
  overflow-y: auto;
`;

const ModalBackdrop = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(4, 30, 65, 0.6);
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
`;

const HeaderContainer = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const ButtonContainer = styled(SABox)`
  display: flex;
  justify-content: end;
`;

const CheckboxContainer = styled(SABox)`
  display: flex;
`;

interface WCClaimPaymentsFilterModalErrors {
  serviceDate?: string;
  checkDate?: string;
  singleCharge?: string;
  singlePayment?: string;
  chargedToDate?: string;
  paidToDate?: string;
}

interface WCClaimPaymentsFilterModalProps {
  selectOptions: string[];
  checkNumberOptions: string[];
  filters: React.MutableRefObject<WCClaimPaymentsFilters>;
  setShowFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedNames: React.Dispatch<React.SetStateAction<string[]>>;
  filterPayments: (filters: WCClaimPaymentsFilters) => void;
}

export const WCClaimPaymentsFilterModal = ({
  selectOptions,
  checkNumberOptions,
  filters,
  setShowFilterModal,
  setSelectedNames,
  filterPayments,
}: WCClaimPaymentsFilterModalProps) => {
  const [errors, setErrors] = useState<WCClaimPaymentsFilterModalErrors>({});
  const [clear, setClear] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<WCClaimPaymentsFilters>({
    ...filters.current,
  });
  const editedFilters = useRef<WCClaimPaymentsFilters>({
    ...filters.current,
  });
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();

  const onNameSelect = (e: any) => {
    const names = e.target?.value?.filter((value: string) => !!value);
    editedFilters.current.payeeName = names;

    setDefaultValues((currentValue: WCClaimPaymentsFilters) => {
      return { ...currentValue, payeeName: names };
    });
  };

  const onStatusChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked, value } = e.currentTarget;
    const status = checked
      ? [...(editedFilters?.current?.status || []), value]
      : [...(editedFilters?.current?.status || [])].filter(
          (status: string) => status !== value
        );
    editedFilters.current.status = status;
  };

  const onCheckNumberSelect = (item: string) => {
    editedFilters.current.checkNumber = item;
  };

  const onServiceDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    boundary: keyof MinMax<string>
  ) => {
    editedFilters.current.serviceDate = {
      ...(editedFilters.current.serviceDate || {}),
      [boundary]: e.target.value,
    };
  };

  const onServiceDateBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    const isError =
      editedFilters.current.serviceDate?.min !== undefined &&
      editedFilters.current.serviceDate?.max !== undefined &&
      convertDate(editedFilters.current.serviceDate.min) >
        convertDate(editedFilters.current.serviceDate.max);

    if ((isError && !errors.serviceDate) || (!isError && errors.serviceDate)) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          serviceDate: isError
            ? "From Date cannot be later than Thru Date"
            : undefined,
        };
      });
    }
  };

  const onCheckDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    boundary: keyof MinMax<string>
  ) => {
    editedFilters.current.checkDate = {
      ...(editedFilters.current.checkDate || {}),
      [boundary]: e.target.value,
    };
  };

  const onCheckDateBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    const isError =
      editedFilters.current.checkDate?.min !== undefined &&
      editedFilters.current.checkDate?.max !== undefined &&
      convertDate(editedFilters.current.checkDate.min) >
        convertDate(editedFilters.current.checkDate.max);

    if ((isError && !errors.checkDate) || (!isError && errors.checkDate)) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          checkDate: isError
            ? "Start Date cannot be later than End Date"
            : undefined,
        };
      });
    }
  };

  const onSingleChargeChange = (
    value: number | null,
    boundary: keyof MinMax<number>
  ) => {
    editedFilters.current.singleCharge = {
      ...(editedFilters.current.singleCharge || {}),
      [boundary]: value === null ? undefined : value,
    };
  };

  const onSingleChargeBlur = (_value: number | null) => {
    const isError =
      editedFilters.current.singleCharge?.min !== undefined &&
      editedFilters.current.singleCharge?.max !== undefined &&
      editedFilters.current.singleCharge.min >
        editedFilters.current.singleCharge.max;

    if (
      (isError && !errors.singleCharge) ||
      (!isError && errors.singleCharge)
    ) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          singleCharge: isError
            ? "Charged Minimum cannot be greater than Charged Maximum"
            : undefined,
        };
      });
    }
  };

  const onSinglePaymentChange = (
    value: number | null,
    boundary: keyof MinMax<number>
  ) => {
    editedFilters.current.singlePayment = {
      ...(editedFilters.current.singlePayment || {}),
      [boundary]: value === null ? undefined : value,
    };
  };

  const onSinglePaymentBlur = (_value: number | null) => {
    const isError =
      editedFilters.current.singlePayment?.min !== undefined &&
      editedFilters.current.singlePayment?.max !== undefined &&
      editedFilters.current.singlePayment.min >
        editedFilters.current.singlePayment.max;

    if (
      (isError && !errors.singlePayment) ||
      (!isError && errors.singlePayment)
    ) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          singlePayment: isError
            ? "Paid Minimum cannot be greater than Paid Maximum"
            : undefined,
        };
      });
    }
  };

  const onChargedToDateChange = (
    value: number | null,
    boundary: keyof MinMax<number>
  ) => {
    editedFilters.current.chargedToDate = {
      ...(editedFilters.current.chargedToDate || {}),
      [boundary]: value === null ? undefined : value,
    };
  };

  const onChargedToDateBlur = (_value: number | null) => {
    const isError =
      editedFilters.current.chargedToDate?.min !== undefined &&
      editedFilters.current.chargedToDate?.max !== undefined &&
      editedFilters.current.chargedToDate.min >
        editedFilters.current.chargedToDate.max;

    if (
      (isError && !errors.chargedToDate) ||
      (!isError && errors.chargedToDate)
    ) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          chargedToDate: isError
            ? "Charged to Date Minimum cannot be greater than Charged to Date Maximum"
            : undefined,
        };
      });
    }
  };

  const onPaidToDateChange = (
    value: number | null,
    boundary: keyof MinMax<number>
  ) => {
    editedFilters.current.paidToDate = {
      ...(editedFilters.current.paidToDate || {}),
      [boundary]: value === null ? undefined : value,
    };
  };

  const onPaidToDateBlur = (_value: number | null) => {
    const isError =
      editedFilters.current.paidToDate?.min !== undefined &&
      editedFilters.current.paidToDate?.max !== undefined &&
      editedFilters.current.paidToDate.min >
        editedFilters.current.paidToDate.max;

    if ((isError && !errors.paidToDate) || (!isError && errors.paidToDate)) {
      setErrors((currentValue: WCClaimPaymentsFilterModalErrors) => {
        return {
          ...currentValue,
          paidToDate: isError
            ? "Paid to Date Minimum cannot be greater than Paid to Date Maximum"
            : undefined,
        };
      });
    }
  };

  const clearFields = () => {
    editedFilters.current = {};
    setDefaultValues({});
    setErrors({});
    setClear(true);
  };

  const cancel = () => {
    setShowFilterModal(false);
  };

  const saveAndApplyFilters = () => {
    filters.current = { ...editedFilters.current };
    filterPayments(editedFilters.current);
    setSelectedNames([...(editedFilters.current.payeeName || [])]);
    setShowFilterModal(false);
    const filterNames: string[] = [];
    Object.keys(filters.current).forEach((key) => {
      const obj = filters.current[key as keyof WCClaimPaymentsFilters];
      if (typeof obj === "object" && !Array.isArray(obj)) {
        Object.keys(obj).forEach((rangeKeyName) => {
          filterNames.push(`${key}${formatTitleCase(rangeKeyName)}`);
        });
      } else {
        filterNames.push(key);
      }
    });

    if (filterNames.length >= 1) {
      Snowplow.track.wcPaymentsFilterApplied({
        accountNumber: accountNumber || "",
        claimNumber: selected || "",
        policyNumber: selectedPolicyNumber || "",
        filters: filterNames,
      });
    }
  };

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  return (
    <ModalBackdrop>
      <FlexModal onClickHandler={() => {}} zIndex={1000}>
        <CardContainer p={{ xs: "small", sm: "large", md: "xl", xl: "xxl" }}>
          <SACard variant="minimal">
            <SABox>
              <HeaderContainer pb="medium">
                <SABox>
                  <SAText type="heading-2">Filter Payments</SAText>
                </SABox>
                <SABox>
                  <SAIcon
                    icon={SAIcons.x}
                    size={SAIconSize.medium}
                    colorVariant="dark"
                    onClick={cancel}
                    data-testid="cancelFilterModal"
                  />
                </SABox>
              </HeaderContainer>
              <SABox pb="large">
                <WhiteBGSelect
                  label="Payee Name"
                  id="payeeNameSelectInModal"
                  multiple
                  variant="checkbox"
                  onChange={onNameSelect}
                  clickToRemove
                  fullWidth
                  data-testid="payeeNameSelectInModal"
                >
                  {selectOptions.map((name: string) => (
                    <SASelectOption
                      value={name}
                      selected={
                        defaultValues.payeeName?.includes(name) || false
                      }
                      key={name}
                    >
                      {name}
                    </SASelectOption>
                  ))}
                </WhiteBGSelect>
              </SABox>
              <SABox pb="large">
                <SAColumns columns={{ xs: [12], md: [6, 6] }} spacing="large">
                  <SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Payment Status</SAText>
                    </SABox>
                    <CheckboxContainer pb="large">
                      <SABox pr="large">
                        <SACheckbox
                          id="filterPaid"
                          label="Paid"
                          defaultChecked={
                            defaultValues.status?.includes("paid") || false
                          }
                          value="paid"
                          onChange={onStatusChange}
                          key={`filterPaid-${clear}`}
                        />
                      </SABox>
                      <SABox>
                        <SACheckbox
                          id="filterVoided"
                          label="Voided"
                          defaultChecked={
                            defaultValues.status?.includes("voided") || false
                          }
                          value="voided"
                          onChange={onStatusChange}
                          key={`filterVoided-${clear}`}
                        />
                      </SABox>
                    </CheckboxContainer>
                    <SABox pb="small">
                      <SAText type="heading-4">Invoice Date Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <SAInput
                          id="serviceFromDate"
                          label="From Date"
                          type="date"
                          defaultValue={defaultValues.serviceDate?.min || ""}
                          onChange={(e) => onServiceDateChange(e, "min")}
                          onBlur={onServiceDateBlur}
                          hint={errors.serviceDate}
                          error={!!errors.serviceDate}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          key={`serviceFromDate-${clear}`}
                        />
                        <SAInput
                          id="serviceThruDate"
                          label="Thru Date"
                          type="date"
                          defaultValue={defaultValues.serviceDate?.max || ""}
                          onChange={(e) => onServiceDateChange(e, "max")}
                          onBlur={onServiceDateBlur}
                          error={!!errors.serviceDate}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          key={`serviceThruDate-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Check Number</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAAutocomplete
                        id="checkNumber"
                        options={checkNumberOptions}
                        label="Check Number"
                        onSelect={onCheckNumberSelect}
                        value={defaultValues.checkNumber || ""}
                        fullWidth
                        key={`checkNumber-${clear}`}
                      />
                    </SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Check Date Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <SAInput
                          id="checkStartDate"
                          label="Start Date"
                          type="date"
                          defaultValue={defaultValues.checkDate?.min || ""}
                          onChange={(e) => onCheckDateChange(e, "min")}
                          onBlur={onCheckDateBlur}
                          hint={errors.checkDate}
                          error={!!errors.checkDate}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          key={`checkStartDate-${clear}`}
                        />
                        <SAInput
                          id="checkEndDate"
                          label="End Date"
                          type="date"
                          defaultValue={defaultValues.checkDate?.max || ""}
                          onChange={(e) => onCheckDateChange(e, "max")}
                          onBlur={onCheckDateBlur}
                          error={!!errors.checkDate}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          key={`checkEndDate-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                  </SABox>
                  <SASeparator
                    horizontal
                    display={{ xs: "block", md: "none" }}
                  />
                  <SASeparator vertical display={{ xs: "none", md: "block" }} />
                  <SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Charged Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <WholeDollarInput
                          id="singleChargeMin"
                          label="Minimum Amount"
                          defaultValue={defaultValues.singleCharge?.min || ""}
                          onChange={(value) =>
                            onSingleChargeChange(value, "min")
                          }
                          onBlur={onSingleChargeBlur}
                          hint={errors.singleCharge}
                          error={!!errors.singleCharge}
                          fullWidth
                          data-testid="singleChargeMin"
                          key={`singleChargeMin-${clear}`}
                        />
                        <WholeDollarInput
                          id="singleChargeMax"
                          label="Maximum Amount"
                          defaultValue={defaultValues.singleCharge?.max || ""}
                          onChange={(value) =>
                            onSingleChargeChange(value, "max")
                          }
                          onBlur={onSingleChargeBlur}
                          error={!!errors.singleCharge}
                          fullWidth
                          data-testid="singleChargeMax"
                          key={`singleChargeMax-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Paid Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <WholeDollarInput
                          id="singlePaymentMin"
                          label="Minimum Amount"
                          defaultValue={defaultValues.singlePayment?.min || ""}
                          onChange={(value) =>
                            onSinglePaymentChange(value, "min")
                          }
                          onBlur={onSinglePaymentBlur}
                          hint={errors.singlePayment}
                          error={!!errors.singlePayment}
                          fullWidth
                          data-testid="singlePaymentMin"
                          key={`singlePaymentMin-${clear}`}
                        />
                        <WholeDollarInput
                          id="singlePaymentMax"
                          label="Maximum Amount"
                          defaultValue={defaultValues.singlePayment?.max || ""}
                          onChange={(value) =>
                            onSinglePaymentChange(value, "max")
                          }
                          onBlur={onSinglePaymentBlur}
                          error={!!errors.singlePayment}
                          fullWidth
                          data-testid="singlePaymentMax"
                          key={`singlePaymentMax-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Charged to Date Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <WholeDollarInput
                          id="chargedToDateMin"
                          label="Minimum Amount"
                          defaultValue={defaultValues.chargedToDate?.min || ""}
                          onChange={(value) =>
                            onChargedToDateChange(value, "min")
                          }
                          onBlur={onChargedToDateBlur}
                          hint={errors.chargedToDate}
                          error={!!errors.chargedToDate}
                          fullWidth
                          data-testid="chargedToDateMin"
                          key={`chargedToDateMin-${clear}`}
                        />
                        <WholeDollarInput
                          id="chargedToDateMax"
                          label="Maximum Amount"
                          defaultValue={defaultValues.chargedToDate?.max || ""}
                          onChange={(value) =>
                            onChargedToDateChange(value, "max")
                          }
                          onBlur={onChargedToDateBlur}
                          error={!!errors.chargedToDate}
                          fullWidth
                          data-testid="chargedToDateMax"
                          key={`chargedToDateMax-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                    <SABox pb="small">
                      <SAText type="heading-4">Paid to Date Range</SAText>
                    </SABox>
                    <SABox pb="large">
                      <SAColumns
                        columns={{ xs: [12], md: [6, 6] }}
                        spacing="medium"
                      >
                        <WholeDollarInput
                          id="paidToDateMin"
                          label="Minimum Amount"
                          defaultValue={defaultValues.paidToDate?.min || ""}
                          onChange={(value) => onPaidToDateChange(value, "min")}
                          onBlur={onPaidToDateBlur}
                          hint={errors.paidToDate}
                          error={!!errors.paidToDate}
                          fullWidth
                          data-testid="paidToDateMin"
                          key={`paidToDateMin-${clear}`}
                        />
                        <WholeDollarInput
                          id="paidToDateMax"
                          label="Maximum Amount"
                          defaultValue={defaultValues.paidToDate?.max || ""}
                          onChange={(value) => onPaidToDateChange(value, "max")}
                          onBlur={onPaidToDateBlur}
                          error={!!errors.paidToDate}
                          fullWidth
                          data-testid="paidToDateMax"
                          key={`paidToDateMax-${clear}`}
                        />
                      </SAColumns>
                    </SABox>
                  </SABox>
                </SAColumns>
              </SABox>
              <ButtonContainer>
                <SABox pr="medium">
                  <SAButton
                    variant="secondary-medium-outline"
                    label="CLEAR"
                    textTransform="uppercase"
                    onClick={clearFields}
                  />
                </SABox>
                <SABox>
                  <SAButton
                    variant="primary-medium"
                    label="FILTER"
                    textTransform="uppercase"
                    disabled={
                      !!(
                        errors.serviceDate ||
                        errors.checkDate ||
                        errors.singleCharge ||
                        errors.singlePayment ||
                        errors.chargedToDate ||
                        errors.paidToDate
                      )
                    }
                    onClick={saveAndApplyFilters}
                  />
                </SABox>
              </ButtonContainer>
            </SABox>
          </SACard>
        </CardContainer>
      </FlexModal>
    </ModalBackdrop>
  );
};
