import React from "react";
import CloseSmallIcon from "../../CloseSmallIcon.png";

const imgStyles = {
  width: "14px",
  height: "14px",
};

export default () => {
  return <img src={CloseSmallIcon} alt="" style={imgStyles} />;
};
