import React from "react";
import { SABox, SAStack, SAText } from "@saux/design-system-react";
import styled from "styled-components";
import {
  KeyValueTexts,
  openLink,
  TextBulletPoints,
} from "../common/CustomTextFormatters";
import { WcFAQContainer } from "./WcFAQContainer";

export const catastrophicEvents = [
  "Alleged or actual kidnapping",
  "Amputation of a significant portion of one extremity (hand, arm, foot, leg, etc.) or multiple amputations",
  "Fatality",
  "Head injury",
  "Large chemical exposures to all on site",
  "Large scale fires involving potential total loss or exposure to all on site",
  "Motor vehicle accidents involving coma, death or paralysis",
  "Multiple claimant injuries resulting from the same incident/exposure (not including motor vehicle accidents)",
  "Multiple fractures or significant degloving injuries or involving more than one arm, hand, or leg (often machine related)",
  "Robberies with injuries",
  "Serious burns",
  "Serious internal injuries resulting from blunt penetrating or crushing injuries to the chest or abdomen",
  "Sexual assaults",
  "Significant eye injuries involving potential loss of eye/eyesight",
  "Spinal cord injury",
];

const LeftAlignedBox = styled(SABox)`
  display: flex;
  text-align: left;
`;

const StyledStack = styled(SAStack)`
  background: #ffffff;
`;

const StyledText = styled(SAText)`
  line-height: 4px;
`;

const StyledSAText = styled(SAText)`
  margin-bottom: -2px;
`;

export const WcFAQSection = () => {
  return (
    <StyledStack separator="line">
      <WcFAQContainer title="How do I Report an Injury?">
        <>
          <SAText text="Please make every effort to get us your First Report of Injury as quickly as possible, preferably within 24 hours. When State Auto gets the facts within 24 hours, case and claims management can start. Delayed reporting can significantly increase the cost of the claim." />
          <SABox mt="medium">
            <SAText
              type="paragraph"
              weight="bold"
              text="Website (preferred method)"
            />
            <SAText
              type="paragraph"
              colorVariant="link"
              text="www.stateauto.com/WorkersCompensation"
              onClick={(e) =>
                openLink(e, "https://www.stateauto.com/WorkersCompensation")
              }
            />

            <LeftAlignedBox ml="30px" mt="medium" mb="medium">
              <TextBulletPoints
                elements={[
                  <>
                    Click on <b>Report a Workers’ Compensation Claim</b>
                  </>,
                  <>
                    Enter your <b>policy number</b> and the{" "}
                    <b>date of injury.</b>
                  </>,
                  <>
                    Select the <b>employee location,</b> and verify the location
                    to begin the report.
                  </>,
                ]}
              />
            </LeftAlignedBox>
            <SAText
              type="paragraph"
              text="Have the employee’s personal information including Social Security Number and birthdate available to help make this a smooth process. It is important that all fields and information are completed."
            />

            <SAText type="paragraph" weight="bold" text="Alternate methods" />

            <LeftAlignedBox>
              <KeyValueTexts
                pairs={[
                  {
                    key: "Fax",
                    value: "1-866-286-5258",
                  },
                  {
                    key: "Phone",
                    value: "1-866-620-3137",
                    link: "tel:18666203137",
                  },
                  {
                    key: "Email",
                    value: "InjuryReports@StateAuto.com",
                    link: "mailto:InjuryReports@StateAuto.com",
                  },
                ]}
              />
            </LeftAlignedBox>
          </SABox>
        </>
      </WcFAQContainer>

      <WcFAQContainer title="How do I expedite medical bills and authorization?">
        <>
          <SAText text="To expedite the payment of bills and medical authorization requests, please use the correct mailing address:" />
          <SABox mt="medium">
            <SAText type="paragraph" weight="bold" text="Medical Bills:" />
            <StyledText type="paragraph" text="State Auto" />
            <StyledText type="paragraph" text="P.O. Box 818057" />
            <StyledText type="paragraph" text="Cleveland, OH 44181-9600" />
            <StyledText type="paragraph" text="Fax: 1-800-563-3364" />
          </SABox>
          <SABox mt="large">
            <SAText
              type="paragraph"
              weight="bold"
              text="Authorization forms, medical records, legal invoices, and all other workers' compensation claims mail:"
            />
            <StyledText
              type="paragraph"
              text="State Auto Insurance Companies"
            />
            <StyledText type="paragraph" text="P.O. Box 182617" />
            <StyledText type="paragraph" text="Columbus, OH 43218" />
            <StyledText type="paragraph" text="Fax: 1-800-563-3364" />
          </SABox>
        </>
      </WcFAQContainer>

      <WcFAQContainer title="How do I find a medical provider?">
        <>
          <LeftAlignedBox ml="30px" mb="medium">
            <TextBulletPoints
              elements={[
                <>
                  Go to StateAuto.com/WorkersCompensation and select{" "}
                  <b>Find a Medical Provider.</b>
                </>,
                <>
                  Enter the code <b>RTWIN</b> into <b>Client ID box. </b>
                </>,
                <>
                  Select <b>Online Tools</b>, then <b>Channeling</b>
                </>,
                <>
                  You can now search for a medical provider by address, provider
                  name, and region.
                </>,
              ]}
              listStyleType="decimal"
            />
          </LeftAlignedBox>
          <StyledSAText type="paragraph" text="Poster:" weight="bold" />
          <SAText
            type="paragraph"
            text="After you locate and select the providers that will be part of your panel, you can select Worksite Poster to generate a poster to display."
          />
        </>
      </WcFAQContainer>

      <WcFAQContainer title="How does the First Script Prescription Program work?">
        <LeftAlignedBox>
          <SABox>
            <SAText
              type="standard"
              text="The employee is given the toll-free number"
            />
            <SAText
              type="standard"
              colorVariant="link"
              onClick={(e) => openLink(e, "tel:18007912080")}
              text=" 1-800-791-2080 "
            />
            <SAText
              type="standard"
              text="to take with their approved prescription to the pharmacy. The pharmacy calls First Script and verifies eligibility, then temporarily enrolls the injured worker. No calls are made to the employer, and the approved worker receives the approved prescription."
            />
          </SABox>
        </LeftAlignedBox>
      </WcFAQContainer>

      <WcFAQContainer title="How do I report a catastrophic injury after hours?">
        <>
          <SAText
            type="paragraph"
            text="When a serious workplace injury happens, get emergency medical care immediately and then contact State Auto."
          />
          <LeftAlignedBox mt="xs">
            <KeyValueTexts
              pairs={[
                {
                  key: "Definition of “After Hours”",
                  value: "Outside the hours of 8 a.m. to 5 p.m. (Central)",
                },
                {
                  key: "After-Hours Reporting Phone Number",
                  value: "1-866-620-3137",
                  link: "tel:18666203137",
                },
              ]}
              mb="20px"
            />
          </LeftAlignedBox>
          <StyledSAText
            type="paragraph"
            text="Catastrophic Injuries/Incidents:"
            weight="bold"
          />
          <SAText
            type="paragraph"
            text="The following types of catastrophic injuries or incidents that must be reported to State Auto within 2 hours of occurrence."
          />
          <LeftAlignedBox ml="30px" mb="medium">
            <TextBulletPoints elements={catastrophicEvents} />
          </LeftAlignedBox>
        </>
      </WcFAQContainer>
    </StyledStack>
  );
};
