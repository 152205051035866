import React, { useEffect, useState } from "react";
import { SABox, SACard, SAStack } from "@saux/design-system-react";
import ExpandableFAQ from "./ExpandableFAQ";
import { FAQ } from "../../FAQSection/FAQItem";
import { FAQType, fetchFAQS } from "../../../services";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";

export interface IncidentFAQProps {
  incidentType: string;
}

export default ({ incidentType }: IncidentFAQProps) => {
  const [faqs, setFAQS] = useState([] as FAQ[]);
  const faqType = {
    type: incidentType,
  };

  useEffect(() => {
    fetchFAQS(faqType as FAQType)
      .then((response) => {
        const { data } = response;
        setFAQS(data.data);
      })
      .catch(() => {});
  }, []);

  return (
    <SABox m="medium">
      <SACard variant="minimal">
        <SABox pb="large">
          <AltColorText type="heading-3" text="Frequently Asked Questions" />
        </SABox>
        <SAStack>
          {faqs?.map((faq: FAQ) => (
            <ExpandableFAQ
              key={faq.question}
              question={faq.question}
              answer={faq.answer}
              type={incidentType}
            />
          ))}
        </SAStack>
      </SACard>
    </SABox>
  );
};
