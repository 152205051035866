import React from "react";
import { SABox, SAText } from "@saux/design-system-react";
import styled from "styled-components";

export const RowItemBox = styled(SABox)`
  display: flex;
  justifycontent: start;
  align-items: start;
`;

interface RowItemsProps {
  icon?: any;
  headerText: string;
  contentText: string;
}

export const RowItem = (props: RowItemsProps) => {
  return (
    <RowItemBox mb="small">
      {props.icon && props.icon}
      <SABox>
        <RowItemBox pb="small" m="8px" ml={props.icon ? "8px" : "0px"} mt="0px">
          <SAText type="standard" text={props.headerText} weight="bold" />
        </RowItemBox>
        <RowItemBox
          pb="small"
          m="8px"
          ml={props.icon ? "8px" : "0px"}
          mt="-8px"
        >
          <SAText type="standard" text={props.contentText} />
        </RowItemBox>
      </SABox>
    </RowItemBox>
  );
};
