import React, { useEffect, useState } from "react";
import { DialogTitleProps, Fade } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { SAButton, SARadio, SARadioGroup } from "@saux/design-system-react";
import { CloseIcon } from "../../assets/Icons";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

interface BootstrapDialogTitleProps {
  onClose: React.MouseEventHandler<HTMLButtonElement>;
}

const BootstrapDialogTitle = (
  props: DialogTitleProps & BootstrapDialogTitleProps
) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface PolicyDetails {
  policy_number: string;
  product_code: string;
}

interface NewClaimModalProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (policyDetails: PolicyDetails) => void;
  policy_details: PolicyDetails[];
  dialogProps?: {
    disablePortal: boolean;
    hideBackdrop: boolean;
    TransitionComponent: ({ children }: any) => any;
  }; // added for testing purpose
}

const NewClaimModal = ({
  open,
  handleClose,
  handleSubmit,
  policy_details,
  dialogProps,
}: NewClaimModalProps) => {
  const [policyDetails, setPolicyDetails] = useState<PolicyDetails>({
    product_code: "",
    policy_number: "",
  });

  const changeproduct_code = (e: React.ChangeEvent<HTMLInputElement>) => {
    const polDetails = e.target.value.split("-");
    const [name, value] = [polDetails[0], polDetails[1]];
    setPolicyDetails({ product_code: name, policy_number: value });
  };

  useEffect(() => {
    setPolicyDetails({ product_code: "", policy_number: "" });
  }, [open]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
        {...dialogProps}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ margin: "8px" }}>
          File a New Claim
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ margin: "8px" }}>
          <SARadioGroup
            id="policyRadio"
            label="Choose the policy you would like to file a new claim for:"
            value={policyDetails.policy_number}
            variant="standard"
            direction="vertical"
            onChange={changeproduct_code}
          >
            <span hidden />
            {policy_details.map((element) => {
              return (
                <SARadio
                  key={element.policy_number}
                  value={`${element.product_code}-${element.policy_number}`}
                  name={element.product_code}
                  label={`${element.product_code} - ${element.policy_number}`}
                  variant="standard"
                />
              );
            })}
          </SARadioGroup>
        </DialogContent>
        <DialogActions style={{ margin: "8px" }}>
          <SAButton
            label="CANCEL"
            onClick={() => {
              handleClose();
            }}
            variant="link"
            color="black"
          />
          <SAButton
            label="START CLAIM"
            disabled={policyDetails.product_code === ""}
            onClick={() => {
              handleSubmit(policyDetails);
            }}
            variant="primary"
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default NewClaimModal;
