import React from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import { SuccessIcon, CloseIcon } from "../../../../assets/Icons";
import { CloseBannerProps } from "../ModalBanners";
import { ButtonContainer } from "../../../uploadFileContent/FileContent";

const ModalContainer = styled(Box)`
  border-radius: 5px;
  padding: 0.8rem 1.2rem;
  margin: auto;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(1, 83, 142, 0.2);
  max-width: 360px;
  max-height: 120px;
  border-left: 5px solid #4d840b;
  background-color: #e7f4e6;
`;
const Content = styled(Box)`
  margin: 0.5rem 1.2rem;
`;
const ModalIcon = styled(Box)`
  align-self: center;
  text-align: center;
  line-height: 1px;
`;
const HeaderText = styled(Box)`
  display: block;
  text-align: left;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 16px;
  color: #415520;
`;
const DescriptionText = styled(Box)`
  display: block;
  text-align: left;
  font-size: 12px;
  color: #3b3b3b;
`;

export default ({ closeBanner }: CloseBannerProps) => {
  return (
    <ModalContainer id="ModalOverlayOpen__UploadSuccessPopup">
      <ModalIcon data-testid="successIcon">
        <SuccessIcon />
      </ModalIcon>

      <Content>
        <HeaderText>Your upload is complete</HeaderText>
        <DescriptionText>
          It may take several minutes for the document to display
        </DescriptionText>
      </Content>

      <ModalIcon>
        <ButtonContainer
          onClick={() => closeBanner(false)}
          data-testid="closeButton"
        >
          <CloseIcon />
        </ButtonContainer>
      </ModalIcon>
    </ModalContainer>
  );
};
