import React from "react";
import { SABox } from "@saux/design-system-react/box";
import InjuryClaimType from "./ClaimType/InjuryClaimType";
import MedicalClaimType from "./ClaimType/MedicalClaimType";
import { useSelectedIncidentState } from "../../providers";
import VehicleClaimTypeSlideHeader from "./ClaimType/VehicleClaimTypeSlideHeader";
import PropertyClaimTypeSlide from "./ClaimType/PropertyClaimTypeSlide";

export default () => {
  const {
    insuredAsset,
    vehicle,
    property,
    injury,
    medical,
  } = useSelectedIncidentState();

  let claimTypeSection = <SABox />;
  if (vehicle) {
    claimTypeSection = (
      <VehicleClaimTypeSlideHeader
        insuredAsset={insuredAsset}
        vehicle={vehicle}
      />
    );
  } else if (property) {
    claimTypeSection = <PropertyClaimTypeSlide property={property} />;
  } else if (injury) {
    claimTypeSection = <InjuryClaimType injury={injury} />;
  } else if (medical) {
    claimTypeSection = <MedicalClaimType medical={medical} />;
  }
  return claimTypeSection;
};
