import Box from "@material-ui/core/Box";
import React, { useEffect } from "react";
import styled from "styled-components";
import { SACard, SABox } from "@saux/design-system-react";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import { ItalicizedFont } from "../claimsLatestPayments/ClaimsLatestPayments";
import AlertDetails from "./AlertDetails";
import { GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts } from "../../generated/GET_CLAIM_INCIDENTS";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import { Snowplow } from "../../util/snowplow/snowplow";

export interface ClaimsAlertProps {
  alerts?:
    | GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts[]
    | undefined;
}

const FullWidthBox = styled(SABox)`
  width: 100%;
`;

export default ({ alerts }: ClaimsAlertProps) => {
  const { userDisplayName } = useClaimsApplicationState();
  const { selected } = useSelectedIncidentState();
  useEffect(() => {
    if (alerts && alerts.length !== 0)
      Snowplow.track.viewAlerts({
        name: userDisplayName || "",
        email: "",
        claimNumber: selected || "",
        alerts: alerts?.map((alert) => {
          return {
            eventName: alert.alertName,
            message: alert.alertDescription,
            isRead: alert.isRead,
            createdDate: alert.createDate,
          };
        }),
      });
  }, []);

  return (
    <FullWidthBox>
      <SACard variant="minimal">
        <SABox pb="small">
          <AltColorText type="heading-4" text="Latest Alerts & Updates" />
        </SABox>
        {alerts?.length ? (
          <Box overflow={{ vertical: "auto" }} margin={{ top: "15px" }}>
            {alerts?.map(
              (
                alert: GET_CLAIM_INCIDENTS_account_items_claim_incidents_incident_alerts
              ) => (
                <AlertDetails
                  createDate={alert?.createDate!}
                  alertName={alert?.alertName!}
                  alertDescription={alert?.alertDescription!}
                />
              )
            )}
          </Box>
        ) : (
          <ItalicizedFont text="There are no alerts to display." type="small" />
        )}
      </SACard>
    </FullWidthBox>
  );
};
