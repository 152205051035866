import React, { useState } from "react";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import {
  SABox,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import { Snowplow } from "../../../util/snowplow/snowplow";
import { useClaimsApplicationState } from "../../../providers";

export const QuestionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  &:hover {
    cursor: pointer;
  }
`;
const FAQContainer = styled(SABox)`
  width: 70%;
  border-bottom: 1px solid #d9dfeb;
`;

const MobileFAQContainer = styled(SABox)`
  width: 100%;
  border-bottom: 1px solid #d9dfeb;
`;

const ChevronContainer = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px 0px 10px 0px;
`;

export interface ExpandableFAQ {
  question?: string;
  answer?: string;
  type?: string;
}

export default ({ question, answer, type }: ExpandableFAQ) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { accountNumber } = useClaimsApplicationState();

  const onFaqExpanded = () => {
    if (!expanded) {
      Snowplow.track.faqClicked({
        faq: question,
        faqType: type,
        accountNumber,
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <MobileFAQContainer>
          <QuestionBox
            onClick={() => {
              setExpanded(!expanded);
              onFaqExpanded();
            }}
            margin="5px 0px 0px 0px"
            data-testid="incidentFaqItemExpander"
          >
            <SAText type="paragraph" weight="bold" text={question} />
            <ChevronContainer ml="small">
              <SAIcon
                icon={expanded ? SAIcons.chevronUp : SAIcons.chevronDown}
                size={SAIconSize.small}
              />
            </ChevronContainer>
          </QuestionBox>
          {expanded && (
            <SABox pt="xs" pb="xs">
              <SAText type="paragraph" text={answer} />
            </SABox>
          )}
        </MobileFAQContainer>
      ) : (
        <FAQContainer pt="medium" pb="xs">
          <QuestionBox
            onClick={() => {
              setExpanded(!expanded);
              onFaqExpanded();
            }}
            data-testid="incidentFaqItemExpander"
          >
            <SAText type="paragraph" weight="bold" text={question} />
            <ChevronContainer mr="small">
              <SAIcon
                icon={expanded ? SAIcons.chevronUp : SAIcons.chevronDown}
                size={SAIconSize.small}
              />
            </ChevronContainer>
          </QuestionBox>
          {expanded && (
            <SABox pt="xs" pb="xs">
              <SAText type="paragraph" text={answer} />
            </SABox>
          )}
        </FAQContainer>
      )}
    </>
  );
};
