import React, {
  useReducer,
  createContext,
  ReactElement,
  useContext,
} from "react";
import { UploadModal } from "../components";

export enum ModalStates {
  On = "MODAL_STATE_ON",
  Off = "MODAL_STATE_OFF",
}

export enum ModalTypes {
  Upload = "UPLOAD_MODAL",
  None = "NO_MODAL",
}

const initialState = {
  open: false,
  Modal: <div />,
  claim: "",
};

const reducer = (
  state: { open: boolean; Modal: ReactElement; claim: string },
  action: { type: ModalStates; name: ModalTypes; props: any; claim: string }
) => {
  switch (action.type) {
    case ModalStates.On:
      return {
        ...state,
        open: true,
        Modal: React.createElement(UploadModal, { ...action.props }),
        claim: action.claim,
      };
    case ModalStates.Off:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export interface ModalControllerContextProps {
  open?: boolean;
  Modal: ReactElement;
  dispatchModal?: Function;
  claim?: string;
}

const ModalControllerContext = createContext<ModalControllerContextProps>(
  initialState
);

export interface ModalControllerProviderProps {
  children: ReactElement;
}

export const ModalControllerProvider = ({
  children,
}: ModalControllerProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ModalControllerContext.Provider
      value={{ ...state, dispatchModal: dispatch }}
    >
      {children}
    </ModalControllerContext.Provider>
  );
};

export const useModalController = (): ModalControllerContextProps => {
  return useContext(ModalControllerContext);
};
