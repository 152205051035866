import React, { createContext, ReactElement, useContext } from "react";
import {
  AdjusterProps,
  ClaimsDetailProps,
} from "../components/claimsDetail/ClaimsDetail";
import { ClaimData } from "../components/recentClaimsContainer/RecentClaimsContainer";

export interface ClaimsApplicationStateContextProps {
  recentClaimsList?: ClaimData[];
  wcClaimsList?: ClaimData[];
  priorClaimsList?: ClaimData[];
  selected?: string;
  setSelected: Function;
  userDisplayName?: string;
  awsAppSyncClient?: any;
  accountNumber?: string;
  claimsIncidentsList?: (ClaimsDetailProps | undefined)[];
  noIncidentsAdjusterData?: AdjusterProps;
  hasWorkersComp?: boolean;
  isWorkersCompView?: boolean;
  setIsWorkersCompView: Function;
  changeSelectedAndPolicyNumber: Function;
  selectedPolicyNumber?: string;
  hideClaimDetails?: boolean;
}

const ClaimsApplicationStateContext = createContext<ClaimsApplicationStateContextProps>(
  {
    setSelected: () => {},
    setIsWorkersCompView: () => {},
    changeSelectedAndPolicyNumber: () => {},
  }
);

export interface ClaimsApplicationStateProps {
  children: ReactElement;
  value: ClaimsApplicationStateContextProps;
}

export const ClaimsApplicationState = ({
  children,
  value,
}: ClaimsApplicationStateProps) => (
  <ClaimsApplicationStateContext.Provider value={value}>
    {children}
  </ClaimsApplicationStateContext.Provider>
);

export const useClaimsApplicationState = (): ClaimsApplicationStateContextProps => {
  return useContext(ClaimsApplicationStateContext);
};
