import { useLazyQuery } from "@apollo/client";
import {
  SABox,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SASelect,
  SASelectOption,
  SAText,
} from "@saux/design-system-react";
import { loader } from "graphql.macro";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useClaimsApplicationState } from "../../providers";
import { Snowplow } from "../../util/snowplow/snowplow";
import {
  PaymentDetails,
  VendorPaymentDetails,
} from "../claimsDetail/ClaimsDetail";
import { Loading } from "../loading";
import { WCClaimPayee } from "./WCClaimPayee";
import { WCClaimPaymentsFilterModal } from "./WCClaimPaymentsFilterModal";
import { convertDate } from "./WCClaimPaymentsTable";

export interface MinMax<T> {
  min?: T;
  max?: T;
}

export interface WCClaimPaymentsFilters {
  payeeName?: string[];
  chargedToDate?: MinMax<number>;
  paidToDate?: MinMax<number>;
  checkNumber?: string;
  singleCharge?: MinMax<number>;
  singlePayment?: MinMax<number>;
  checkDate?: MinMax<string>;
  serviceDate?: MinMax<string>;
  status?: string[];
}

const get_wc_claim_payments = loader(
  "../../graphql/queries/Get_WC_Claim_Payments.graphql"
);

export const WhiteBGSelect = styled(SASelect)`
  background-color: white;
`;

const FlexContainer = styled(SABox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectContainer = styled(SABox)`
  flex-grow: 2;
`;

const NoPaymentsContainer = styled(SABox)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const getCheckNumberOptions = (payees: VendorPaymentDetails[]) => {
  const options = [];

  for (let i: number = 0; i < payees.length; i++) {
    const payments = payees[i].paymentDetails;

    if (payments) {
      for (let i: number = 0; i < payments.length; i++) {
        const checkNumber = payments[i].checknumber;

        if (checkNumber) {
          options.push(`${checkNumber}`);
        }
      }
    }
  }

  return options;
};

export const getPayeeSortFunction = (isAscending: boolean) => {
  let greaterThan = 1;
  let lessThan = -1;

  if (!isAscending) {
    greaterThan = -1;
    lessThan = 1;
  }

  return (a: VendorPaymentDetails, b: VendorPaymentDetails) => {
    const aValue = a.name;
    const bValue = b.name;

    if (aValue !== undefined && bValue === undefined) {
      return greaterThan;
    }

    if (aValue === undefined && bValue !== undefined) {
      return lessThan;
    }

    if (aValue === bValue) {
      return 1;
    }

    return aValue && bValue && aValue > bValue ? greaterThan : lessThan;
  };
};

interface DisplayedListStartEnd {
  start: number;
  end: number;
}

export const WCClaimPayments = () => {
  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [filteredList, setFilteredList] = useState<VendorPaymentDetails[]>([]);
  const [
    displayedListIndeces,
    setDisplayedListIndeces,
  ] = useState<DisplayedListStartEnd>({ start: 0, end: 5 });
  const activeFilters = useRef<WCClaimPaymentsFilters>({});
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();

  const [
    getWCClaimPaymentsQuery,
    {
      data: wcClaimPaymentsData,
      error: wcClaimPaymentsError,
      previousData: previousPaymentsData,
      loading,
    },
  ] = useLazyQuery<{
    getWCDetails: { paymentDetails: VendorPaymentDetails[] };
  }>(get_wc_claim_payments, {
    variables: {
      accountNumber,
      policyNumber: selectedPolicyNumber,
      claimNumber: selected,
    },
  });

  const paymentDetails = wcClaimPaymentsData?.getWCDetails?.paymentDetails;
  const previousPaymentDetails =
    previousPaymentsData?.getWCDetails?.paymentDetails;
  const unfilteredList = [...(paymentDetails || previousPaymentDetails || [])];
  const selectOptions = unfilteredList
    .filter((payee: VendorPaymentDetails) => !!payee.name)
    .map((payee: VendorPaymentDetails) => payee.name || "");
  const checkNumberOptions = getCheckNumberOptions(unfilteredList);
  const notOnFirstPage = !(displayedListIndeces.start === 0);
  const notOnLastPage = !(displayedListIndeces.end >= filteredList.length);

  const firstPage = () => {
    const end = filteredList.length < 5 ? filteredList.length : 5;

    setDisplayedListIndeces({ start: 0, end });
  };

  const previousPage = () => {
    setDisplayedListIndeces((prevValue: DisplayedListStartEnd) => {
      const start = prevValue.start - 5;
      const end = prevValue.start;

      return { start, end };
    });
  };

  const nextPage = () => {
    setDisplayedListIndeces((prevValue: DisplayedListStartEnd) => {
      const start = prevValue.start + 5;
      const endPlusFive = prevValue.end + 5;
      const end =
        endPlusFive > filteredList.length ? filteredList.length : endPlusFive;

      return { start, end };
    });
  };

  const lastPage = () => {
    const start = filteredList.length - (filteredList.length % 5);
    const end = filteredList.length;

    setDisplayedListIndeces({ start, end });
  };

  const resetPage = (list: VendorPaymentDetails[]) => {
    const end = list.length < 5 ? list.length : 5;

    setDisplayedListIndeces({ start: 0, end });
  };

  const filterPayments = (filters: WCClaimPaymentsFilters) => {
    const filterResults: VendorPaymentDetails[] = [];

    if (unfilteredList?.length) {
      for (let i: number = 0; i < unfilteredList.length; i++) {
        const payee = unfilteredList[i];
        const payeeResult: VendorPaymentDetails = {
          ...payee,
          paymentDetails: [],
        };
        let pushPayee: boolean = true;
        let payeeFilterApplied: boolean = false;

        if (filters.payeeName?.length) {
          pushPayee = filters?.payeeName.includes(payee.name || "");
          payeeFilterApplied = true;
        }

        if (pushPayee && filters.chargedToDate) {
          if (filters.chargedToDate.max !== undefined) {
            pushPayee =
              payee.chargedtodate !== undefined && payee.chargedtodate !== null
                ? payee.chargedtodate <= filters.chargedToDate.max
                : false;
            payeeFilterApplied = true;
          }

          if (pushPayee && filters.chargedToDate.min !== undefined) {
            pushPayee =
              payee.chargedtodate !== undefined && payee.chargedtodate !== null
                ? payee.chargedtodate >= filters.chargedToDate.min
                : false;
            payeeFilterApplied = true;
          }
        }

        if (pushPayee && filters.paidToDate) {
          if (filters.paidToDate.max !== undefined) {
            pushPayee =
              payee.paidtodate !== undefined && payee.paidtodate !== null
                ? payee.paidtodate <= filters.paidToDate.max
                : false;
            payeeFilterApplied = true;
          }

          if (pushPayee && filters.paidToDate.min !== undefined) {
            pushPayee =
              payee.paidtodate !== undefined && payee.paidtodate !== null
                ? payee.paidtodate >= filters.paidToDate.min
                : false;
            payeeFilterApplied = true;
          }
        }

        if (pushPayee) {
          let paymentFilterApplied: boolean = false;

          payeeResult.paymentDetails = (payee.paymentDetails || []).filter(
            (payment: PaymentDetails) => {
              let returnPayment: boolean = true;

              if (filters.status?.length) {
                returnPayment = filters.status.includes(
                  payment.paymentstatus?.toLowerCase() || ""
                );
                paymentFilterApplied = true;
              }

              if (returnPayment && filters.checkNumber) {
                returnPayment = `${payment.checknumber || ""}`.includes(
                  filters.checkNumber
                );
                paymentFilterApplied = true;
              }

              if (returnPayment && filters.checkDate) {
                if (filters.checkDate.max) {
                  returnPayment = payment.checkdate
                    ? convertDate(payment.checkdate) <=
                      convertDate(filters.checkDate.max)
                    : false;
                  paymentFilterApplied = true;
                }

                if (returnPayment && filters.checkDate.min) {
                  returnPayment = payment.checkdate
                    ? convertDate(payment.checkdate) >=
                      convertDate(filters.checkDate.min)
                    : false;
                  paymentFilterApplied = true;
                }
              }

              if (returnPayment && filters.serviceDate) {
                if (returnPayment && filters.serviceDate.min) {
                  returnPayment = payment.service_fromdate
                    ? convertDate(payment.service_fromdate) >=
                      convertDate(filters.serviceDate.min)
                    : false;
                  paymentFilterApplied = true;
                }

                if (returnPayment && filters.serviceDate.max) {
                  returnPayment = payment.service_thrudate
                    ? convertDate(payment.service_thrudate) <=
                      convertDate(filters.serviceDate.max)
                    : false;
                  paymentFilterApplied = true;
                }
              }

              if (returnPayment && filters.singleCharge) {
                if (filters.singleCharge.max !== undefined) {
                  returnPayment =
                    payment.chargedamount !== undefined &&
                    payment.chargedamount !== null
                      ? payment.chargedamount <= filters.singleCharge.max
                      : false;
                  paymentFilterApplied = true;
                }

                if (returnPayment && filters.singleCharge.min !== undefined) {
                  returnPayment =
                    payment.chargedamount !== undefined &&
                    payment.chargedamount !== null
                      ? payment.chargedamount >= filters.singleCharge.min
                      : false;
                  paymentFilterApplied = true;
                }
              }

              if (returnPayment && filters.singlePayment) {
                if (filters.singlePayment.max !== undefined) {
                  returnPayment =
                    payment.paidamount !== undefined &&
                    payment.paidamount !== null
                      ? payment.paidamount <= filters.singlePayment.max
                      : false;
                  paymentFilterApplied = true;
                }

                if (returnPayment && filters.singlePayment.min !== undefined) {
                  returnPayment =
                    payment.paidamount !== undefined &&
                    payment.paidamount !== null
                      ? payment.paidamount >= filters.singlePayment.min
                      : false;
                  paymentFilterApplied = true;
                }
              }

              return returnPayment;
            }
          );

          if (
            payeeFilterApplied ||
            (!payeeFilterApplied &&
              paymentFilterApplied &&
              payeeResult.paymentDetails?.length) ||
            (!payeeFilterApplied && !paymentFilterApplied)
          ) {
            filterResults.push(payeeResult);
          }
        }
      }

      setFilteredList(filterResults);
      resetPage(filterResults);
    }
  };

  const toggleSort = () => {
    setSortAscending((currentValue: boolean) => !currentValue);
  };

  const onSelectChange = (e: any) => {
    const names = e.target?.value?.filter((value: string) => !!value);
    activeFilters.current.payeeName = names;

    setSelectedNames(names || []);
    filterPayments(activeFilters.current);
  };

  const resetFilters = () => {
    activeFilters.current = {};
    setSelectedNames([]);
    setFilteredList(unfilteredList);
    resetPage(unfilteredList);
  };

  const reset = (list: VendorPaymentDetails[]) => {
    activeFilters.current = {};
    setSelectedNames([]);
    setFilteredList(list);
    resetPage(list);
  };

  useEffect(() => {
    getWCClaimPaymentsQuery();
  }, []);

  useEffect(() => {
    if (wcClaimPaymentsError) {
      if (previousPaymentDetails) {
        reset([...previousPaymentDetails]);
      } else {
        reset([]);
      }

      return;
    }

    if (paymentDetails) {
      reset([...paymentDetails]);
    }
  }, [paymentDetails, wcClaimPaymentsError, previousPaymentDetails]);

  const wcPaymentsFiltered = () => {
    if (selectedNames.length >= 1) {
      Snowplow.track.wcPaymentsFilterApplied({
        accountNumber: accountNumber || "",
        claimNumber: selected || "",
        policyNumber: selectedPolicyNumber || "",
        filters: ["payeeName"],
      });
    }
  };

  return (
    <>
      {(loading ||
        wcClaimPaymentsData ||
        previousPaymentsData ||
        wcClaimPaymentsError) && (
        <Loading
          loading={loading}
          error={
            wcClaimPaymentsError && !previousPaymentsData
              ? wcClaimPaymentsError
              : undefined
          }
        >
          <SABox>
            {showFilterModal && (
              <WCClaimPaymentsFilterModal
                setShowFilterModal={setShowFilterModal}
                setSelectedNames={setSelectedNames}
                selectOptions={selectOptions}
                checkNumberOptions={checkNumberOptions}
                filters={activeFilters}
                filterPayments={filterPayments}
              />
            )}
            <FlexContainer pb="large">
              <SelectContainer p="small">
                <WhiteBGSelect
                  label="Payee Name"
                  id="payeeNameSelect"
                  multiple
                  variant="checkbox"
                  onChange={onSelectChange}
                  onBlur={wcPaymentsFiltered}
                  clickToRemove
                  fullWidth
                >
                  {selectOptions.map((name: string) => (
                    <SASelectOption
                      value={name}
                      selected={selectedNames.includes(name)}
                      key={name}
                    >
                      {name}
                    </SASelectOption>
                  ))}
                </WhiteBGSelect>
              </SelectContainer>
              <SABox p="small">
                <SAButton
                  variant="primary-small-outline"
                  label="CLEAR FILTERS"
                  textTransform="uppercase"
                  onClick={resetFilters}
                />
              </SABox>
              <SABox p="small">
                <SAButton
                  variant="primary-small"
                  label="FILTER PAYMENTS"
                  textTransform="uppercase"
                  onClick={() => {
                    setShowFilterModal(true);
                  }}
                />
              </SABox>
            </FlexContainer>
            <FlexContainer pb="medium">
              <SABox pl="small">
                <SAText weight="bold">Sort: </SAText>
                <SAText onClick={toggleSort}>Payee A-Z </SAText>
                <SAIcon
                  icon={sortAscending ? SAIcons.chevronUp : SAIcons.chevronDown}
                  onClick={toggleSort}
                  colorVariant="dark"
                  size={SAIconSize.small}
                  data-testid={`sortPayments${
                    sortAscending ? "Ascending" : "Descending"
                  }`}
                />
              </SABox>
              <FlexContainer>
                <SABox pr="small">
                  <SAText>
                    {`${
                      filteredList.length ? displayedListIndeces.start + 1 : 0
                    } - ${displayedListIndeces.end} of ${filteredList.length}`}
                  </SAText>
                </SABox>
                <FlexContainer pt="xxs">
                  <SABox pr="xs">
                    <SAIcon
                      icon={SAIcons.chevronsLeft}
                      onClick={notOnFirstPage ? firstPage : undefined}
                      colorVariant="dark"
                      size={SAIconSize.small}
                      data-testid="firstPaymentsPage"
                    />
                  </SABox>
                  <SABox pr="xs">
                    <SAIcon
                      icon={SAIcons.chevronLeft}
                      onClick={notOnFirstPage ? previousPage : undefined}
                      colorVariant="dark"
                      size={SAIconSize.small}
                      data-testid="previousPaymentsPage"
                    />
                  </SABox>
                  <SABox pl="xs">
                    <SAIcon
                      icon={SAIcons.chevronRight}
                      onClick={notOnLastPage ? nextPage : undefined}
                      colorVariant="dark"
                      size={SAIconSize.small}
                      data-testid="nextPaymentsPage"
                    />
                  </SABox>
                  <SABox pl="xs">
                    <SAIcon
                      icon={SAIcons.chevronsRight}
                      onClick={notOnLastPage ? lastPage : undefined}
                      colorVariant="dark"
                      size={SAIconSize.small}
                      data-testid="lastPaymentsPage"
                    />
                  </SABox>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <SABox>
              {filteredList.length ? (
                <>
                  {[...filteredList]
                    .sort(getPayeeSortFunction(sortAscending))
                    .slice(displayedListIndeces.start, displayedListIndeces.end)
                    .map((payee: VendorPaymentDetails, index: number) => (
                      <SABox
                        key={`${payee?.name}${payee?.chargedtodate}${payee?.paidtodate}`}
                        data-testid={`wc-claim-payee-${index}-${
                          payee?.name || ""
                        }`}
                      >
                        <WCClaimPayee payee={payee} />
                      </SABox>
                    ))}
                </>
              ) : (
                <NoPaymentsContainer width="100%" p="large">
                  <SAText type="heading-3">
                    No payments found. If filters are applied, try expanding
                    your search criteria.
                  </SAText>
                </NoPaymentsContainer>
              )}
            </SABox>
          </SABox>
        </Loading>
      )}
    </>
  );
};
