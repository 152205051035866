import React from "react";
import styled from "styled-components";
import { SAIcon, SAIcons, SAIconSize } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { SAStack } from "@saux/design-system-react/stack";
import { formatTitleCase } from "../../../util/formatters";
import { NoTextWrapBox } from "./MedicalClaimType";
import { AltColorText } from "../../recentClaimsItem/RecentClaimsItem";

export interface PropertyProps {
  propertyLine1?: string;
  propertyLine2?: string;
  propertyLine3?: string;
}

export interface PropertyClaimTypeProps {
  property?: PropertyProps;
}

const StyledPropertyIcon = styled(SAIcon)`
  min-heigh: 34px;
  min-width: 30px;
`;

export default ({ property }: PropertyClaimTypeProps) => {
  const line1 = property?.propertyLine1;
  const line2 = property?.propertyLine2;
  const line2cont = property?.propertyLine3;
  const outputLine1 = `${formatTitleCase(line1)}`;
  const outputLine2 = `${formatTitleCase(line2)}`;
  return (
    <SABox mr="small">
      <SABox display="flex">
        <StyledPropertyIcon icon={SAIcons.home} size={SAIconSize.medium} />
        <SABox pl="small">
          <SAStack spacing="small">
            <SABox>
              <AltColorText type="heading-3" text="Property" />
            </SABox>
            <SABox display="flex">
              <SABox display="flex" mr="xs">
                <AltColorText type="small" text="Address: " weight="bold" />
              </SABox>
              <SABox display="flex">
                <SAStack spacing="xs">
                  <NoTextWrapBox display="flex">
                    <AltColorText type="small" text={outputLine1} />
                  </NoTextWrapBox>
                  <NoTextWrapBox display="flex">
                    <AltColorText
                      type="small"
                      text={`${outputLine2} ${line2cont}`}
                    />
                  </NoTextWrapBox>
                </SAStack>
              </SABox>
            </SABox>
          </SAStack>
        </SABox>
      </SABox>
    </SABox>
  );
};
