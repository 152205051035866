/* eslint-disable class-methods-use-this */
import TagManager from "react-gtm-module";

export enum ACTOR_TYPES {
  User = "User",
  System = "System",
}

export interface faqClickedProps {
  faq: string | undefined;
  faqType?: string;
  accountNumber?: string;
}

export interface adjusterEmailClickedProps {
  claimNumber: string;
  adjusterEmail: string;
  adjusterName: string;
}

export interface adjusterPhoneClickedProps {
  claimNumber: string;
  adjusterPhone: string;
  adjusterName: string;
}

export interface uploadDocumentsProps {
  claimNumber: string;
  documents: any;
  uploadSucceeded: boolean;
}

export interface viewClaimsProps {
  name: string;
  email: string;
  claims: any;
}

export interface viewProgressProps {
  name: string;
  email: string;
  claimNumber: string;
  progressStep: string;
}

export interface viewAlertsProps {
  name: string;
  email: string;
  claimNumber: string;
  alerts: any;
}

export interface WcEventProps {
  event?: string;
  accountNumber: string;
  claimNumber: string;
  policyNumber: string;
}

export class Snowplow {
  static track = new Snowplow();

  faqClicked({ faq, faqType, accountNumber }: faqClickedProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "faqClicked",
        actor: ACTOR_TYPES.User,
        faq: faq || "",
        faqType: faqType || "",
        accountNumber: accountNumber || "",
      },
    });
  }

  adjusterEmailClicked({
    claimNumber,
    adjusterEmail,
    adjusterName,
  }: adjusterEmailClickedProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "adjusterEmailClicked",
        actor: ACTOR_TYPES.User,
        claimNumber: claimNumber || "",
        adjusterEmail: adjusterEmail || "",
        adjusterName: adjusterName || "",
      },
    });
  }

  adjusterPhoneClicked({
    claimNumber,
    adjusterPhone,
    adjusterName,
  }: adjusterPhoneClickedProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "adjusterPhoneClicked",
        actor: ACTOR_TYPES.User,
        claimNumber: claimNumber || "",
        adjusterPhone,
        adjusterName,
      },
    });
  }

  uploadDocuments({
    claimNumber,
    documents,
    uploadSucceeded,
  }: uploadDocumentsProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "uploadDocuments",
        actor: ACTOR_TYPES.User,
        claimNumber,
        documents,
        uploadSucceeded,
      },
    });
  }

  viewAlerts({ name, email, claimNumber, alerts }: viewAlertsProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "viewAlerts",
        actor: ACTOR_TYPES.System,
        name,
        email,
        claimNumber,
        alerts,
      },
    });
  }

  viewClaims({ name, email, claims }: viewClaimsProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "viewClaims",
        actor: ACTOR_TYPES.System,
        name,
        email,
        claims,
      },
    });
  }

  viewProgress({ name, email, claimNumber, progressStep }: viewProgressProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "viewProgress",
        name,
        email,
        claimNumber,
        progressStep,
      },
    });
  }

  wcSearchWithClaimNumber(props: {
    accountNumber: string;
    claimNumber: string;
  }) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcSearchWithClaimNumber",
        accountNumber: props.accountNumber,
        claimNumber: props.claimNumber,
      },
    });
  }

  wcSortClaimsList(props: { accountNumber: string; sortType: string }) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcSortClaimsList",
        accountNumber: props.accountNumber,
        sortType: props.sortType,
      },
    });
  }

  wcSnowplowEvent({
    event,
    accountNumber,
    claimNumber,
    policyNumber,
  }: WcEventProps) {
    TagManager.dataLayer({
      dataLayer: {
        event,
        accountNumber,
        claimNumber,
        policyNumber,
      },
    });
  }

  wcPaymentsFilterApplied({
    accountNumber,
    claimNumber,
    policyNumber,
    filters,
  }: WcEventProps & { filters: string[] }) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcPaymentsFilterApplied",
        accountNumber,
        claimNumber,
        policyNumber,
        filters,
      },
    });
  }

  wcClaimsFilterApplied(props: { accountNumber: string; filters: string[] }) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcClaimsFilterApplied",
        accountNumber: props.accountNumber,
        filters: props.filters,
      },
    });
  }

  wcFaqExpanded(props: WcEventProps & { faq: string }) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcFaqExpanded",
        faq: props.faq || "",
        accountNumber: props.accountNumber || "",
        claimNumber: props.claimNumber || "",
        policyNumber: props.policyNumber || "",
      },
    });
  }

  wcAdjusterPhoneClicked({
    accountNumber,
    claimNumber,
    policyNumber,
    adjusterName,
    adjusterPhone,
  }: WcEventProps & adjusterPhoneClickedProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcAdjusterPhoneClicked",
        accountNumber,
        claimNumber,
        policyNumber,
        adjusterName,
        adjusterPhone,
      },
    });
  }

  wcAdjusterEmailClicked({
    accountNumber,
    claimNumber,
    policyNumber,
    adjusterName,
    adjusterEmail,
  }: WcEventProps & adjusterEmailClickedProps) {
    TagManager.dataLayer({
      dataLayer: {
        event: "wcAdjusterEmailClicked",
        accountNumber,
        claimNumber,
        policyNumber,
        adjusterName,
        adjusterEmail,
      },
    });
  }
}
