import { Box } from "@material-ui/core";
import { SAText, SABox, SAColumns } from "@saux/design-system-react";
import React, { useContext, useEffect } from "react";
import styled, { ThemeContext } from "styled-components";
import { formatDate } from "../../util/formatters";
import formatTitleCaseOther from "../../util/formatters/formatTitleCaseOther";
import { Snowplow } from "../../util/snowplow/snowplow";
import {
  ClaimNumberWrapper,
  FlexContainer,
  StyledTag,
  TextAlignLeftBox,
} from "../styled";

export interface DisplayData {
  insuredName?: string;
  claimNumber?: string;
  lossLocation?: string;
  dateOfLoss?: string;
  status: string;
  selected: boolean;
}

export interface RecentClaimsItemProps {
  recentClaimsList: any;
  priorClaimsList: any;
  userDisplayName: string | undefined;
  selectedClaim: string | undefined;
}

export const AltColorText = styled(SAText)`
  color: #041e41;
`;

const SelectableBox = styled(Box)`
  padding: 20px;
  background: #e4eded;
  border-top: 1px solid #cedbdb;
  &:hover {
    cursor: pointer;
  }
`;

export default ({
  insuredName,
  claimNumber,
  lossLocation,
  dateOfLoss,
  status,
  selected,
  selectedClaim,
  recentClaimsList,
  priorClaimsList,
  userDisplayName,
}: DisplayData & RecentClaimsItemProps) => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    if (selected) {
      const recentClaims = recentClaimsList || [];
      const priorClaims = priorClaimsList || [];

      Snowplow.track.viewClaims({
        name: userDisplayName || "",
        email: "",
        claims: recentClaims.concat(priorClaims) || [],
      });
      Snowplow.track.viewProgress({
        name: userDisplayName || "",
        email: "",
        claimNumber: selectedClaim || "",
        progressStep: (status as string) || "",
      });
    }
  }, [selected]);

  return (
    <SelectableBox
      style={
        selected
          ? {
              background: "white",
              borderLeft: "4px solid #9325b2",
            }
          : { background: "#ECEFF4" }
      }
    >
      <SABox>
        <FlexContainer pb="small">
          <ClaimNumberWrapper>
            <AltColorText type="heading-4" text={claimNumber} />
          </ClaimNumberWrapper>
          <SABox>
            <StyledTag
              label={formatTitleCaseOther(status)}
              variant={
                status === "open"
                  ? "secondary-outline-small"
                  : "standard-outline-small"
              }
            />
          </SABox>
        </FlexContainer>
        <SAColumns
          columns={{ xs: [5, 7], sm: [3, 9], md: [5, 7] }}
          spacing="xs"
        >
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text="Insured Name: " />
          </TextAlignLeftBox>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text={insuredName} />
          </TextAlignLeftBox>
        </SAColumns>
        <SAColumns
          columns={{ xs: [5, 7], sm: [3, 9], md: [5, 7] }}
          spacing="xs"
        >
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text="Loss Location: " />
          </TextAlignLeftBox>
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text={lossLocation} />
          </TextAlignLeftBox>
        </SAColumns>
        <SAColumns
          columns={{ xs: [5, 7], sm: [3, 9], md: [5, 7] }}
          spacing="xs"
        >
          <TextAlignLeftBox pb="xxs">
            <AltColorText type="small" text="Date of Loss: " />
          </TextAlignLeftBox>
          <TextAlignLeftBox pb="xxs">
            <AltColorText
              type="small"
              text={formatDate(dateOfLoss) as string}
            />
          </TextAlignLeftBox>
        </SAColumns>
      </SABox>
    </SelectableBox>
  );
};
