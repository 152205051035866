import React, { useEffect, useState } from "react";
import {
  SABox,
  SAButton,
  SASearch,
  SAStack,
  SAText,
} from "@saux/design-system-react";
import styled from "styled-components";
import FileShieldIcon from "../../assets/Icons/fileShieldIcon/FileShieldIcon";
import { useClaimsApplicationState } from "../../providers";
import { NewClaimLink } from "../../page/ClaimsPage/ClaimsPage";

export const CenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerCenteredBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const IconRoundBox = styled(SABox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #e1f5ff;
  border-radius: 50px;
  width: 100px;
  height: 100px;
`;
const SASelectableText = styled(SAText)`
  &:hover {
    cursor: pointer;
  }
`;

const SAFilterButton = styled(SAButton)`
  height: 25px;
`;

export interface WcClaimSearchProps {
  setSearchString: (search: string) => void;
  setOpenAdvancedFilterModal: (state: boolean) => void;
}
export const WcClaimSearch = ({
  setSearchString,
  setOpenAdvancedFilterModal,
}: WcClaimSearchProps) => {
  const { accountNumber } = useClaimsApplicationState();

  useEffect(() => {
    // workaround to set SA Search bg color to white
    const searchBar = document.getElementById("wc-claims-search");
    if (searchBar) {
      searchBar.style.width = "260px";
      searchBar.style.background = "white";
    }
  }, []);

  return (
    <ContainerCenteredBox>
      <SAStack spacing="small">
        <CenteredBox>
          <IconRoundBox data-testid="file-shield-icon">
            <FileShieldIcon />
          </IconRoundBox>
        </CenteredBox>
        <CenteredBox>
          <SAText
            size="400"
            text="Select a Claim to View or Search for a Claim"
            colorVariant="outline"
          />
        </CenteredBox>
        <CenteredBox data-testid="wc-claims-search-box">
          <SASearch
            id="wc-claims-search"
            data-testid="wc-claims-search"
            onSearch={(search) => setSearchString(search.toString())}
            fullWidth
            placeholder="Search Claim Number"
          />
        </CenteredBox>
        <CenteredBox>
          <SAFilterButton
            fullWidth={false}
            label="ADVANCED FILTER"
            onClick={() => setOpenAdvancedFilterModal(true)}
            variant="secondary-small-outline"
          >
            <SASelectableText
              size="200"
              text="ADVANCED FILTER"
              colorVariant="primary"
              type="standard"
            />
          </SAFilterButton>
        </CenteredBox>
        <CenteredBox pt="large">
          <NewClaimLink account_number={accountNumber || ""} />
        </CenteredBox>
      </SAStack>
    </ContainerCenteredBox>
  );
};
