import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  SAAccordion,
  SAColumns,
  SAIcon,
  SAIcons,
  SAStack,
} from "@saux/design-system-react";
import styled from "styled-components";
import { loader } from "graphql.macro";
import { CalendarIcon } from "../../assets/Icons/calendarIcon/CalendarIcon";
import { ConeIcon } from "../../assets/Icons/coneIcon/ConeIcon";
import { MapPinIcon } from "../../assets/Icons/mapPinIcon/MapPinIcon";
import { useClaimsApplicationState } from "../../providers/ClaimsApplicationState";
import { formatDate } from "../../util/formatters";
import { WorkerCompProps } from "../claimsDetail/ClaimsDetail";
import { Loading } from "../loading";
import { RowItem } from "../common/RowItemContainer";
import { Snowplow } from "../../util/snowplow/snowplow";

const StyledAccordion = styled(SAAccordion)`
  .accordion-summary {
    background: #ffffff;
  }
  .accordion-title {
    font-size: 18px;
  }
  background: #ffffff;
`;

const wc_query_file = loader(
  "../../graphql/queries/Get_WC_Details_Tab_Data.graphql"
);

export const WcDetailsTab = () => {
  const {
    accountNumber,
    selected,
    selectedPolicyNumber,
  } = useClaimsApplicationState();

  const [getWcDetailsQuery, { called, loading, error, data }] = useLazyQuery(
    wc_query_file,
    {
      variables: {
        accountNumber,
        claimNumber: selected,
        policyNumber: selectedPolicyNumber,
      },
    }
  );

  useEffect(() => {
    getWcDetailsQuery();
  }, []);

  const handleChange = (event: string) => (
    _e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent,
    isExpanded: boolean
  ) => {
    if (isExpanded) {
      Snowplow.track.wcSnowplowEvent({
        event,
        accountNumber: accountNumber || "",
        claimNumber: selected || "",
        policyNumber: selectedPolicyNumber || "",
      });
    }
  };

  const wcDetails: WorkerCompProps = data?.getWCDetails;

  return (
    <Loading loading={called && loading} error={error}>
      <SAStack spacing="medium">
        <StyledAccordion
          title="Incident Details"
          variant="standard"
          onChange={handleChange("wcIncidentDetailsOpened")}
        >
          <SAColumns
            columns={{ xs: [12, 12, 12, 12, 12, 12], sm: [6, 6, 6, 6, 6, 6] }}
          >
            <RowItem
              icon={<SAIcon icon={SAIcons.personCircle} size="24px" />}
              headerText="Primary Contact"
              contentText={wcDetails?.employerContactDetails?.name || "N/A"}
            />
            <RowItem
              icon={<SAIcon icon={SAIcons.checklist} size="24px" />}
              headerText="Activities when Injured"
              contentText={wcDetails?.activitieswheninjured || "N/A"}
            />
            <RowItem
              icon={<MapPinIcon />}
              headerText="Loss Location"
              contentText={
                [
                  [
                    wcDetails?.losslocationaddressline1,
                    wcDetails?.losslocationcity,
                    wcDetails?.losslocationstate,
                  ]
                    .filter((loc) => !!loc)
                    .join(", "),
                  wcDetails?.losslocationzip,
                ]
                  .filter((loc) => !!loc)
                  .join(" ") || "N/A"
              }
            />
            <RowItem
              icon={<ConeIcon />}
              headerText="Cause of Injury"
              contentText={wcDetails?.causeofinjury || "N/A"}
            />
            <RowItem
              icon={<CalendarIcon />}
              headerText="Date of Injury Reported by Employee"
              contentText={
                wcDetails?.ernotifiedofinjurydate
                  ? (formatDate(wcDetails?.ernotifiedofinjurydate) as string)
                  : "N/A"
              }
            />
            <RowItem
              icon={<CalendarIcon />}
              headerText="Date of Claim Submission"
              contentText={
                wcDetails?.injuryreporteddate
                  ? (formatDate(wcDetails?.injuryreporteddate) as string)
                  : "N/A"
              }
            />
          </SAColumns>
        </StyledAccordion>

        <StyledAccordion
          title="Injured Person Details"
          variant="standard"
          onChange={handleChange("wcPersonDetailsOpened")}
        >
          <SAColumns columns={{ xs: [12, 12, 12], sm: [6, 6, 6] }}>
            <RowItem
              icon={<SAIcon icon={SAIcons.personCircle} size="24px" />}
              headerText="Injured Name"
              contentText={wcDetails?.injuredContactDetails?.name || "N/A"}
            />
            <RowItem
              icon={<CalendarIcon />}
              headerText="Estimated Return to Work Date"
              contentText={
                wcDetails?.returntoworkdate
                  ? (formatDate(wcDetails?.returntoworkdate) as string)
                  : "N/A"
              }
            />
            <RowItem
              icon={<SAIcon icon={SAIcons.idCard} size="24px" />}
              headerText="Injured Position"
              contentText={wcDetails?.occupation || "N/A"}
            />
          </SAColumns>
        </StyledAccordion>

        <StyledAccordion
          title="Injury Details"
          variant="standard"
          onChange={handleChange("wcInjuryDetailsOpened")}
        >
          <SAColumns columns={{ xs: [12, 12, 12], sm: [6, 6, 6] }}>
            <RowItem
              headerText="Description/Nature of Injury"
              contentText={wcDetails?.natureofinjury || "N/A"}
            />
            <RowItem
              headerText="Body Part Injured"
              contentText={wcDetails?.partofbody || "N/A"}
            />
            <RowItem
              headerText="Date of Injury"
              contentText={
                wcDetails?.dateofloss
                  ? (formatDate(wcDetails?.dateofloss) as string)
                  : "N/A"
              }
            />
          </SAColumns>
        </StyledAccordion>
      </SAStack>
    </Loading>
  );
};
