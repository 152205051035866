import React from "react";
import styled from "styled-components";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { SACard, SAText } from "@saux/design-system-react";
import { SABox } from "@saux/design-system-react/box";
import { SAColumns } from "@saux/design-system-react/columns";
import { SAStack } from "@saux/design-system-react/stack";
import { formatTitleCase } from "../../util/formatters";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import { FlexContainer } from "../styled";

export interface PayeesProps {
  name?: string | undefined;
}

export interface AmountProps {
  amount?: number | undefined;
  currency?: string | undefined;
}

export interface ClaimsLatestPaymentProps {
  payees?: string[] | undefined;
  paymentMethod?: string | undefined;
  checkNumber?: string | undefined;
  amount?: string | undefined;
  issueDate?: string | undefined;
}

export interface ClaimsLatestPayment {
  claimsSummaryPaymentsList?: ClaimsLatestPaymentProps[] | undefined;
}

export const ItalicizedFont = styled(SAText)`
  font-style: italic;
  color: rgba(4, 30, 65, 0.5);
`;

export default ({ claimsSummaryPaymentsList }: ClaimsLatestPayment) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SACard variant="minimal">
      <SABox>
        <SABox pb="small">
          <AltColorText type="heading-4" text="Latest Payments" />
        </SABox>
        {claimsSummaryPaymentsList?.length ? (
          <Box overflow={{ vertical: "auto" }} margin={{ top: "15px" }}>
            {claimsSummaryPaymentsList?.map(
              (payment: ClaimsLatestPaymentProps | null) => {
                return (
                  <SABox flex={0}>
                    <SABox>
                      <SACard variant="sub">
                        <SABox pr="small" display="flex">
                          <SABox>
                            <AltColorText
                              type={isMobile ? "small" : "standard"}
                              text="Payee: "
                              weight="bold"
                            />
                            <AltColorText
                              type={isMobile ? "small" : "standard"}
                              text={payment?.payees?.[0]}
                            />
                          </SABox>
                        </SABox>
                      </SACard>
                    </SABox>
                    <SABox mt="small">
                      {isMobile ? (
                        <SAStack spacing="small">
                          <FlexContainer>
                            <SABox ml="small">
                              <AltColorText
                                type="small"
                                text="Issued"
                                weight="bold"
                              />
                            </SABox>
                            <SABox mr="small">
                              <SAText
                                type={isMobile ? "small" : "standard"}
                                text={payment?.issueDate}
                              />
                            </SABox>
                          </FlexContainer>
                          <FlexContainer>
                            <SABox ml="small">
                              <AltColorText
                                type="small"
                                text={formatTitleCase(payment?.paymentMethod)}
                                weight="bold"
                              />
                            </SABox>
                            <SABox mr="small">
                              <SAText
                                type={isMobile ? "small" : "standard"}
                                text={payment?.checkNumber}
                              />
                            </SABox>
                          </FlexContainer>
                          <FlexContainer>
                            <SABox ml="small">
                              <AltColorText
                                type="small"
                                text="Amount"
                                weight="bold"
                              />
                            </SABox>
                            <SABox mr="small">
                              <SAText
                                type={isMobile ? "small" : "standard"}
                                text={`${payment?.amount}`}
                              />
                            </SABox>
                          </FlexContainer>
                        </SAStack>
                      ) : (
                        <SAColumns spacing="small" columns={[4, 4, 4]}>
                          <SAStack>
                            <SABox>
                              <AltColorText
                                type="small"
                                text="Issued"
                                weight="bold"
                              />
                            </SABox>
                            <SABox>
                              <SAText
                                type={isMobile ? "small" : "standard"}
                                text={payment?.issueDate}
                              />
                            </SABox>
                          </SAStack>
                          <SAStack>
                            <SABox>
                              <AltColorText
                                type="small"
                                text={formatTitleCase(payment?.paymentMethod)}
                                weight="bold"
                              />
                            </SABox>
                            <SABox>
                              <SAText
                                type={isMobile ? "small" : "standard"}
                                text={payment?.checkNumber}
                              />
                            </SABox>
                          </SAStack>
                          <SAStack>
                            <AltColorText
                              type="small"
                              text="Amount"
                              weight="bold"
                            />
                            <SAText
                              type={isMobile ? "small" : "standard"}
                              text={`${payment?.amount}`}
                            />
                          </SAStack>
                        </SAColumns>
                      )}
                    </SABox>
                  </SABox>
                );
              }
            )}
          </Box>
        ) : (
          <ItalicizedFont type="small" text="No payments found." />
        )}
      </SABox>
    </SACard>
  );
};
