import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  SABox,
  SAStack,
  SAText,
  SACard,
  SAButton,
} from "@saux/design-system-react";
import { Box, Modal } from "@material-ui/core";
import styled from "styled-components";
import { Slide } from "../slide/Slide";
import {
  LeftJustifiedBox,
  RightJustifiedBox,
} from "../claimAdjusterDropdownSection/ClaimAdjusterDropdownSection";
import { AltColorText } from "../recentClaimsItem/RecentClaimsItem";
import FileIcon from "./FileIcon";
import { formatDate } from "../../util/formatters";
import { GreyTextDark, GreyTextLight, StyledSABox } from "./PhotosAndFiles";
import { DocumentListItem } from "../claimsDetail/ClaimDetailsDropdown";
import {
  useClaimsApplicationState,
  useSelectedIncidentState,
} from "../../providers";
import FileIconResponsive from "./FileIconResponsive";
import { download } from "../../services";

export interface MobileFileListItemProps {
  f: DocumentListItem;
}

export const MobileFileItemRow = styled(Box)`
  width: 100%;
  border-bottom: 1px solid #d9dfeb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
  }
`;

const StepOneHeader = styled(SABox)`
  position: relative;
`;

const MobileFileDownloadSlide = styled(SABox)`
  background: #ffffff;
  height: 100vh;
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  overflow: auto;
  position: fixed;
  @media (max-width: 750px) {
    height: calc(100vh - 6rem);
  }
`;
const StyledDownloadButton = styled(SAButton)`
  width: 100%;
`;
const ShaperBox = styled(SABox)`
  height: 200px;
  width: 200px;
`;

export default ({ f }: MobileFileListItemProps) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [areStepsActive, setAreStepsActive] = useState(false);
  const { accountNumber } = useClaimsApplicationState();
  const { publicID } = useSelectedIncidentState();
  const get_download_url = loader(
    "../../graphql/queries/Download_Claims_Document.graphql"
  );
  const [getDownloadUrl] = useLazyQuery(get_download_url, {
    variables: {
      account_number: accountNumber,
      document_url: f.documentUrl,
      incident_id: publicID,
    },
    onCompleted: (returnData: any) => {
      const downloadLink =
        returnData?.account?.items[0]?.downloadClaimsDocument;
      download(downloadLink);
    },
  });

  const renderStepOneHeader = (f: DocumentListItem) => {
    let filename = "";
    let author = "";
    if (f.name) {
      filename = f.name;
    }
    if (f.author) {
      author = f.author;
    }
    return (
      <StepOneHeader data-testid="stepOneHeaderMobileFile">
        <SAStack>
          <SAText type="heading-3" text={filename} />
          <SAText type="small" text={author} />
        </SAStack>
      </StepOneHeader>
    );
  };

  const renderMobileFileDownload = () => {
    return (
      <MobileFileDownloadSlide>
        <ShaperBox>
          <FileIconResponsive />
        </ShaperBox>
        <StyledDownloadButton
          fullWidth={false}
          label="download"
          onClick={() => {
            getDownloadUrl();
          }}
          textTransform="uppercase"
          variant="primary"
        />
      </MobileFileDownloadSlide>
    );
  };

  return (
    <>
      <Modal open={areStepsActive}>
        <SACard variant="minimal">
          <Slide
            activeStepIndex={activeStepIndex}
            displayCallbackIcon
            stepCallback={() => setAreStepsActive(false)}
            steps={[
              {
                stepKey: "step-1",
                stepHeader: renderStepOneHeader(f),
                stepElement: renderMobileFileDownload(),
              },
            ]}
          />
        </SACard>
      </Modal>
      <MobileFileItemRow
        padding="5px"
        onClick={() => setAreStepsActive(true)}
        data-testid="mobileFileItemRow"
      >
        <StyledSABox>
          <FileIcon />
          <SAStack>
            <LeftJustifiedBox pl="small">
              <AltColorText type="small" weight="bold" text={f.name!} />
              <SABox mt="xs">
                <GreyTextDark type="small" text={f.author!} />
              </SABox>
            </LeftJustifiedBox>
          </SAStack>
        </StyledSABox>
        <SAStack>
          <RightJustifiedBox>
            <GreyTextLight
              type="small"
              text={formatDate(f.createTime!) as string}
            />
            <SABox mt="xs">
              <GreyTextDark type="small" text={f.mimeType || "File"} />
            </SABox>
          </RightJustifiedBox>
        </SAStack>
      </MobileFileItemRow>
    </>
  );
};
