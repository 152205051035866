import React from "react";

export default () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 0.875C11.8452 0.875 12.125 1.15482 12.125 1.5V5.25C12.125 5.59518 11.8452 5.875 11.5 5.875H7.75C7.40482 5.875 7.125 5.59518 7.125 5.25C7.125 4.90482 7.40482 4.625 7.75 4.625H10.5024L9.30574 3.40304C9.30117 3.39837 9.29668 3.39364 9.29226 3.38884C8.81394 2.86878 8.22692 2.49385 7.58853 2.29318C6.95055 2.09265 6.27762 2.07117 5.63074 2.23023C4.98352 2.38937 4.37846 2.72537 3.87393 3.21269C3.36909 3.7003 2.98173 4.32341 2.75281 5.02641C2.64593 5.35463 2.29322 5.53406 1.965 5.42718C1.63679 5.3203 1.45736 4.96759 1.56424 4.63938C1.85401 3.7495 2.34821 2.94848 3.00552 2.31359C3.66315 1.67841 4.46298 1.23013 5.33228 1.01638C6.20192 0.802556 7.10772 0.831752 7.96337 1.10071C8.81548 1.36855 9.58577 1.86397 10.2055 2.53523L10.875 3.21892V1.5C10.875 1.15482 11.1548 0.875 11.5 0.875ZM0.875 7.75C0.875 7.40482 1.15482 7.125 1.5 7.125H5.25C5.59518 7.125 5.875 7.40482 5.875 7.75C5.875 8.09518 5.59518 8.375 5.25 8.375H2.49758L3.69426 9.59697C3.69883 9.60163 3.70332 9.60636 3.70774 9.61117C4.18607 10.1312 4.77308 10.5062 5.41147 10.7068C6.04945 10.9074 6.72238 10.9288 7.36926 10.7698C8.01648 10.6106 8.62154 10.2746 9.12607 9.78732C9.63091 9.2997 10.0183 8.67659 10.2472 7.97359C10.3541 7.64537 10.7068 7.46594 11.035 7.57282C11.3632 7.6797 11.5426 8.03241 11.4358 8.36062C11.146 9.2505 10.6518 10.0515 9.99448 10.6864C9.33685 11.3216 8.53702 11.7699 7.66772 11.9836C6.79808 12.1974 5.89228 12.1682 5.03663 11.8993C4.18453 11.6315 3.41423 11.136 2.79455 10.4648L2.125 9.78108V11.5C2.125 11.8452 1.84518 12.125 1.5 12.125C1.15482 12.125 0.875 11.8452 0.875 11.5V8.24965V7.75Z"
        fill="white"
      />
    </svg>
  );
};
