import React from "react";

export default () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 7.36414C5 7.16304 4.83694 7 4.63568 7H3.36414C3.16306 7 3 7.16304 3 7.36414V8.63551C3 8.83692 3.16306 9 3.36414 9H4.63568C4.83694 9 5 8.83692 5 8.63551V7.36414Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 7.36414C7 7.16304 6.83679 7 6.63586 7H5.36418C5.16308 7 5 7.16304 5 7.36414V8.63551C5 8.83692 5.16308 9 5.36418 9H6.63586C6.83679 9 7 8.83692 7 8.63551V7.36414Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 7.36414C10 7.16304 9.83693 7 9.63585 7H8.36446C8.1632 7 8 7.16304 8 7.36414V8.63551C8 8.83692 8.1632 9 8.36446 9H9.63585C9.83693 9 10 8.83692 10 8.63551V7.36414Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 9.36424C5 9.16276 4.83694 9 4.63568 9H3.36414C3.16306 9 3 9.16276 3 9.36424V10.6358C3 10.8371 3.16306 11 3.36414 11H4.63568C4.83694 11 5 10.8369 5 10.6358V9.36424Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 9.36424C7 9.16276 6.83679 9 6.63586 9H5.36418C5.16308 9 5 9.16276 5 9.36424V10.6358C5 10.8371 5.16308 11 5.36418 11H6.63586C6.83679 11 7 10.8369 7 10.6358V9.36424Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 9.36424C10 9.16276 9.83693 9 9.63602 9H8.36446C8.1632 9 8 9.16276 8 9.36424V10.6358C8 10.8371 8.1632 11 8.36446 11H9.63602C9.83693 11 10 10.8369 10 10.6358V9.36424Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8429 1.00594V2.63867C11.8429 3.37653 11.2533 3.97128 10.5265 3.97128H9.69589C8.96908 3.97128 8.37162 3.37653 8.37162 2.63867V1H4.62838V2.63867C4.62838 3.37653 4.03088 3.97128 3.30422 3.97128H2.47355C1.74685 3.97128 1.15719 3.37653 1.15719 2.63867V1.00594C0.522003 1.02536 0 1.55892 0 2.21486V11.783C0 12.4513 0.533478 13 1.19166 13H11.8083C12.4655 13 13 12.4502 13 11.783V2.21486C13 1.55892 12.4781 1.02536 11.8429 1.00594ZM11.4571 11.1873C11.4571 11.476 11.2266 11.7103 10.9421 11.7103H2.03536C1.75081 11.7103 1.52018 11.476 1.52018 11.1873V6.24485C1.52018 5.95597 1.7507 5.72169 2.03536 5.72169H10.9419C11.2265 5.72169 11.4571 5.95597 11.4571 6.24485V11.1873Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.52367 3H3.47637C3.76541 3 4 2.82888 4 2.61762V0.38263C4 0.171214 3.76541 0 3.47637 0H2.52367C2.23442 0 2 0.171214 2 0.38263V2.61762C2 2.82888 2.23442 3 2.52367 3Z"
      fill="#0173C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52367 3H10.4765C10.7654 3 11 2.82888 11 2.61762V0.38263C11 0.171214 10.7656 0 10.4765 0H9.52367C9.23444 0 9 0.171214 9 0.38263V2.61762C9 2.82888 9.23444 3 9.52367 3Z"
      fill="#0173C6"
    />
  </svg>
);
