import React from "react";

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="alertIconTest"
  >
    <g id="Icon/Alert">
      <circle id="Oval" cx="10" cy="10" r="10" fill="#F5A623" />
      <path
        id="!"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.212 9.322L10.744 12.256H9.26797L8.79997 9.322V3.4H11.212V9.322ZM11.068 15.82C10.804 16.072 10.444 16.198 9.98797 16.198C9.55597 16.198 9.20797 16.072 8.94397 15.82C8.67997 15.568 8.54797 15.232 8.54797 14.812C8.54797 14.392 8.67997 14.059 8.94397 13.813C9.20797 13.567 9.55597 13.444 9.98797 13.444C10.444 13.444 10.804 13.567 11.068 13.813C11.332 14.059 11.464 14.392 11.464 14.812C11.464 15.232 11.332 15.568 11.068 15.82Z"
        fill="white"
      />
    </g>
  </svg>
);
