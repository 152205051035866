import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { ErrorIcon, CloseIcon } from "../../../../assets/Icons";
import { CloseBannerProps } from "../ModalBanners";
import { ButtonContainer } from "../../../uploadFileContent/FileContent";

const ModalContainer = styled(Box)`
  border-radius: 5px;
  padding: 0.8rem 1.2rem;
  margin: auto;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0px 4px 4px rgba(1, 83, 142, 0.2);
  max-width: 500px;
  max-height: 120px;
  border: 1px solid #fbcad2;
  border-left: 5px solid #b00020;
  background-color: #fdeaee;
`;
const Content = styled(Box)`
  margin: 0.5rem 1.2rem;
`;
const ModalIcon = styled(Box)`
  align-self: center;
  text-align: center;
  line-height: 1px;
`;
const HeaderText = styled(Box)`
  display: block;
  text-align: left;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 14px;
  color: #b00020;
`;
const DescriptionText = styled(Box)`
  display: block;
  text-align: left;
  font-size: 14px;
  color: #b00020;
`;

export default ({ closeBanner }: CloseBannerProps) => {
  return (
    <ModalContainer id="ModalOverlayOpen__UploadErrorPopup">
      <ModalIcon data-testid="errorIcon">
        <ErrorIcon />
      </ModalIcon>

      <Content>
        <HeaderText>There was an Error Uploading Your Files</HeaderText>
        <DescriptionText>
          Please review the errors and clear any files that have errors.
        </DescriptionText>
      </Content>

      <ModalIcon>
        <ButtonContainer
          onClick={() => closeBanner(false)}
          data-testid="closeButton"
        >
          <CloseIcon />
        </ButtonContainer>
      </ModalIcon>
    </ModalContainer>
  );
};
