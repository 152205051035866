import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DropArea } from ".";
import { FileContent } from "..";
import {
  ModalStates,
  ModalTypes,
  useModalController,
} from "../../providers/ModalController";
import { CloseIcon } from "../../assets/Icons";
import { ModalBannerNames, ModalBanners } from "./ModalBanner";
import { SelectFiles } from "./DropArea";
import { ButtonContainer } from "../uploadFileContent/FileContent";

export interface modalProperties {
  staging: boolean;
  showModalHandler: Function;
  stagingHandler: Function;
}

export enum UploadStatus {
  pending = "pending",
  failed = "failed",
  success = "success",
}

const StagingModal = styled.div`
  display: flex;
  flex-direction: column;
`;
const StagingSection = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  border: 1px, solid, #d9dfeb;
  background-color: #ffffff;
  border-radius: 3px;
  max-height: 90vh;
  padding: 30px;
  overflow: auto;
  width: 800px;
  box-shadow: 0px 8px 32px -8px #002038;
  @media (max-width: 960px) {
    width: 85vw;
    height: auto;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  @media (max-width: 615px) {
    max-height: calc(100vh - 50px);
    margin-top: 50px;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const UploadModal = styled.div`
  display: flex;
  flex-direction: column;
`;
const UploadSection = styled.div`
  box-shadow: 0px 8px 32px -8px #002038;
  padding: 30px;
  background-color: #ffffff;
  width: 650px;
  @media (max-width: 960px) {
    width: 85vw;
    height: auto;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const HeaderText = styled.div`
  color: #041e41;
  font-size: 24px;
  font-weight: 700;
`;
const StagingBox = styled.div`
  border: 1px solid #80b8e2;
  border-radius: 4px;
  margin-top: 30px;
  overflow: auto;
  min-height: 40px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  padding-top: 30px;
  gap: 40px;
  @media (max-width: 615px) {
    flex-direction: column;
    gap: 30px;
  }
`;
const ButtonSubGroup = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
`;
const LeftButtonSubGroup = styled(ButtonSubGroup)`
  justify-content: flex-start;
  gap: 30px;
  @media (max-width: 615px) {
    gap: 10px;
  }
`;
const RightButtonSubGroup = styled(ButtonSubGroup)`
  justify-content: flex-end;
`;
export const SelectButton = styled.label`
  font-weight: 700;
  font-size: 14px;
  font-family: "PT Sans";
  background-color: #0173c6;
  color: #ffffff;
  border: 1px solid #0173c6;
  border-radius: 3px;
  padding: 5px 15px;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 615px) {
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    padding: 5px 30px;
  }
  @media (max-width: 460px) {
    padding: 5px 10px;
  }
`;
export const UploadButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  font-family: "PT Sans";
  background-color: #4d840b;
  color: #ffffff;
  border: 1px solid #4d840b;
  border-radius: 3px;
  padding: 5px 15px;
  margin: auto;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 615px) {
    border-radius: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    padding: 8px 15px;
    width: 250px;
  }
  @media (max-width: 460px) {
    padding: 5px 25px;
    width: auto;
  }
`;
const ClearListButton = styled.div`
  font-size: 14px;
  font-weight: 700;
  align-self: center;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 615px) {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    padding: 5px 45px;
    margin: auto;
  }
  @media (max-width: 460px) {
    padding: 5px 20px;
  }
`;
const UploadCancelAnchor = styled(ClearListButton)`
  align-self: flex-end;
  line-height: 0;
`;

export const ErrorText = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #e02020;
`;

const AlignIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 4px;
`;

const StagingSpacing = styled.div`
  margin-top: -1rem;
`;

export default () => {
  const { dispatchModal } = useModalController();
  const [staging, setStaging] = useState(false);
  const reset: any[] = [];
  const [upload, setUpload] = useState(0);
  const [displayBanner, setDisplayBanner] = useState<ModalBannerNames | false>(
    false
  );
  const [fileUploadSuccess, setFileUploadSuccess] = useState<UploadStatus[]>(
    []
  );

  const spliceFileList = (fileList: any[], f: any) => {
    for (let i = 0; i < fileList.length; i += 1) {
      if (f.name === fileList[i].name) {
        fileList.splice(i, 1);
        i -= 1;
      }
    }
    return fileList;
  };

  const reducer = (
    state: { fileList: any[] },
    action: { type: any; inDropZone: any; files: any }
  ) => {
    switch (action.type) {
      case "SET_IN_DROP_ZONE":
        return { ...state, inDropZone: action.inDropZone };
      case "ADD_FILE_TO_LIST":
        return { ...state, fileList: state.fileList.concat(action.files) };
      case "CLEAR_LIST":
        return { fileList: action.files };
      case "REMOVE_FILE":
        return {
          fileList: spliceFileList(state.fileList, action.files),
        };
      default:
        return state;
    }
  };

  const [data, dispatch] = React.useReducer(reducer, {
    inDropZone: false,
    fileList: [],
  });

  useEffect(() => {
    if (fileUploadSuccess.length !== 0) {
      const allFilesAttempted = fileUploadSuccess?.every(
        (file: any) => file !== UploadStatus.pending
      );

      if (allFilesAttempted) {
        const allFilesFailed = fileUploadSuccess?.every(
          (file: any) => file === UploadStatus.failed
        );
        if (allFilesFailed) {
          setDisplayBanner(ModalBannerNames.error);
          return;
        }

        const anyFileFailed = fileUploadSuccess?.some(
          (file: any) => file === UploadStatus.failed
        );
        if (anyFileFailed) {
          setDisplayBanner(ModalBannerNames.partialSuccess);
          return;
        }

        setDisplayBanner(ModalBannerNames.success);
      }
    }
  }, [fileUploadSuccess]);

  const closeModal = () => {
    if (dispatchModal) {
      dispatchModal({
        type: ModalStates.Off,
        name: ModalTypes.Upload,
      });
    }
    setFileUploadSuccess([]);
  };

  const removeFile = (f: any, index: number) => {
    if (data.fileList.length === 1) {
      dispatch({
        type: "CLEAR_LIST",
        inDropZone: false,
        files: reset,
      });
    } else
      dispatch({
        type: "REMOVE_FILE",
        inDropZone: false,
        files: f,
      });

    setFileUploadSuccess((prevState: UploadStatus[]) => {
      const currentState: UploadStatus[] = prevState;
      if (currentState[index]) {
        currentState.splice(index, 1);
      }
      return currentState;
    });
  };

  const uploadFiles = () => {
    setFileUploadSuccess((prevState: UploadStatus[]) => {
      const currentState: UploadStatus[] = data.fileList.map(
        (_file: any, index: number) => {
          if (prevState[index] && prevState[index] === "success") {
            return UploadStatus.success;
          }
          return UploadStatus.pending;
        }
      );
      return currentState;
    });
    setUpload(upload + 1);
  };

  const chooseFiles = (e: any) => {
    let files: any[] = [];
    let i = 0;
    for (i = 0; i < e.length; i += 1) {
      files.push(e[i]);
    }

    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f: { name: any }) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));
      dispatch({
        type: "ADD_FILE_TO_LIST",
        inDropZone: false,
        files,
      });
      setStaging(true);
    }
  };

  return (
    <>
      {staging ? (
        <StagingModal>
          <StagingSection data-testid="staging-section">
            <HeaderRow>
              <HeaderText>Upload Documents</HeaderText>

              <AlignIcon>
                <ButtonContainer
                  data-testid="closeIcon"
                  onClick={() => closeModal()}
                >
                  <CloseIcon />
                </ButtonContainer>
              </AlignIcon>
            </HeaderRow>
            <StagingBox>
              {data.fileList.map((f: File, index: number) => {
                return (
                  <FileContent
                    name={f.name}
                    dispatch={dispatch}
                    uploadState={upload}
                    uploadSuccess={setFileUploadSuccess}
                    file={f}
                    key={f.name}
                    setUpload={setUpload}
                    removeFile={removeFile}
                    index={index}
                  />
                );
              })}
            </StagingBox>
            <ButtonRow>
              <LeftButtonSubGroup>
                <ClearListButton
                  onClick={() => {
                    dispatch({
                      type: "CLEAR_LIST",
                      inDropZone: false,
                      files: reset,
                    });
                    closeModal();
                  }}
                >
                  CLEAR LIST
                </ClearListButton>
                <SelectButton>
                  ADD MORE FILES
                  <SelectFiles
                    type="file"
                    name="file"
                    id="file"
                    multiple
                    onChange={(e) => {
                      chooseFiles(e.target.files);
                    }}
                  />
                </SelectButton>
              </LeftButtonSubGroup>
              <RightButtonSubGroup>
                <UploadButton onClick={uploadFiles}>
                  UPLOAD DOCUMENTS
                </UploadButton>
              </RightButtonSubGroup>
            </ButtonRow>
          </StagingSection>
          <StagingSpacing>
            <ModalBanners
              displayBanner={displayBanner}
              closeBanner={setDisplayBanner}
            />
          </StagingSpacing>
        </StagingModal>
      ) : (
        <UploadModal>
          <UploadSection>
            <HeaderRow>
              <HeaderText>Upload Documents</HeaderText>
              <AlignIcon>
                <ButtonContainer
                  data-testid="closeIcon"
                  onClick={() => closeModal()}
                >
                  <CloseIcon />
                </ButtonContainer>
              </AlignIcon>
            </HeaderRow>
            <DropArea
              stagingHandler={setStaging}
              data={data}
              dispatch={dispatch}
            />
            <UploadCancelAnchor
              id="cancelAnchor"
              data-testid="uploadModalCancelAnchor"
              onClick={() => closeModal()}
            >
              CANCEL
            </UploadCancelAnchor>
          </UploadSection>
          <StagingSpacing>
            <ModalBanners
              displayBanner={displayBanner}
              closeBanner={setDisplayBanner}
            />
          </StagingSpacing>
        </UploadModal>
      )}
    </>
  );
};
