import React from "react";
import { SABox } from "@saux/design-system-react";
import styled from "styled-components";
import { useClaimsApplicationState } from "../../providers";
import { WcClaimsItem } from "../wcClaimsItem/WcClaimsItem";
import { ClaimData } from "../recentClaimsContainer/RecentClaimsContainer";
import { SortType } from "../wcClaimsTab/WcClaimsTab";

const SAContainerBox = styled(SABox)`
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
`;
export interface WcClaimsListProps {
  wcDisplayedClaimsList: ClaimData[];
  sortType: SortType;
}
export const WcClaimsList = ({
  wcDisplayedClaimsList,
  sortType,
}: WcClaimsListProps) => {
  const {
    selected,
    changeSelectedAndPolicyNumber,
  } = useClaimsApplicationState();

  return (
    <SAContainerBox>
      {wcDisplayedClaimsList?.map((displayedItem) => (
        <WcClaimsItem
          {...displayedItem}
          sortType={sortType}
          changeSelectedAndPolicyNumber={changeSelectedAndPolicyNumber}
          selected={displayedItem.claimNumber === selected}
        />
      ))}
    </SAContainerBox>
  );
};
